import { Enumeration } from '@bp/shared/models/core/enum';

import { NewsKind } from './news-kind';
import { BLOG_PROMO_ROUTE_PATH, BRIDGER_STORIES_PROMO_ROUTE_PATH, NEWSROOM_PROMO_ROUTE_PATH } from './newsroom-route-paths.constants';

export class NewsroomSectionKind extends Enumeration {

	static blog = new NewsroomSectionKind({
		routePath: BLOG_PROMO_ROUTE_PATH,
	});

	static bridgerStories = new NewsroomSectionKind({
		routePath: BRIDGER_STORIES_PROMO_ROUTE_PATH,
	});

	static newsroom = new NewsroomSectionKind({
		routePath: NEWSROOM_PROMO_ROUTE_PATH,
	});

	static getByNewsKind(kind: NewsKind): NewsroomSectionKind {
		switch (kind) {
			case NewsKind.blogPost:
				return NewsroomSectionKind.blog;

			case NewsKind.caseStudy:
				return NewsroomSectionKind.bridgerStories;

			default:
				return NewsroomSectionKind.newsroom;
		}
	}

	readonly routePath: string;

	constructor(config: {
		displayName?: any;
		routePath: string;
	}) {
		super(config.displayName);

		this.routePath = config.routePath;
	}

}
