import { Moment } from 'moment';

import { Label, Default, Mapper, View } from '../decorators';
import { FieldViewType } from '../enums';
import { momentMapper } from '../mappers';

import { EntityBase } from './entity-base';

export abstract class FirebaseEntity extends EntityBase {

	@Label('Author')
	@Default(null)
	authorUid!: string | null;

	@Mapper(momentMapper)
	@View(FieldViewType.moment, () => 'LLL')
	@Default(null)
	createdAt!: Moment | null;

	@Mapper(momentMapper)
	@View(FieldViewType.moment, () => 'LLL')
	@Default(null)
	updatedAt!: Moment | null;

	@Default(null)
	updatedBy!: string | null;

}
