import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminsSharedFeaturesNotificationsHubModule } from './admins-shared-features-notifications-hub.module';
import { NotificationsHubEffects } from './state/notifications-hub.effects';
import { FEATURE_KEY, reducer } from './state/notifications-hub.reducer';

@NgModule({
	imports: [
		AdminsSharedFeaturesNotificationsHubModule,

		EffectsModule.forFeature([ NotificationsHubEffects ]),
		StoreModule.forFeature(FEATURE_KEY, reducer),

	],
})
export class AdminsSharedFeaturesNotificationsHubRootModule { }
