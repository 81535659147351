import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { BridgerAdminFeature, MerchantAdminFeature } from '@bp/shared/domains/permissions';

import {
	incompleteIdentityHasAccessGuardConfig, IncompleteIdentityHasAccessGuard
} from '@bp/admins-shared/domains/identity';
import { IntroPageStep } from '@bp/admins-shared/pages/intro/models';

import { IdentityLoggedOutGuard } from '@bp/frontend-domains-identity';

import {
	CreateAccountPageComponent,
	AcceptInvitePageComponent,
	IntroShellPageComponent,
	LoginPageComponent,
	OtpOnLoginPageComponent,
	RegisterAuthenticatorAppPageComponent,
	ResetAuthenticatorAppLinkSentPageComponent,
	ResetAuthenticatorAppPageComponent,
	ResetAuthenticatorAppVerificationPageComponent,
	ResetPasswordLinkSentPageComponent,
	ResetPasswordPageComponent,
	ResetPasswordVerificationPageComponent,
	SendResetAuthenticatorAppLinkPageComponent,
	SendResetPasswordLinkPageComponent,
	SessionExpiredPageComponent,
	SetSecurityQuestionsAnswersPageComponent,
	LoginAfterInvitePageComponent
} from './pages';

const routes: Routes = [
	{
		path: 'intro',
		component: IntroShellPageComponent,
		data: {
			fullscreen: true,
		},
		canActivate: [ IdentityLoggedOutGuard ],
		children: [
			{
				path: IntroPageStep.login.kebabCase,
				component: LoginPageComponent,
				data: {
					step: IntroPageStep.login,
					title: IntroPageStep.login.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.otpOnLogin.kebabCase,
				component: OtpOnLoginPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.otpOnLogin,
					title: IntroPageStep.otpOnLogin.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.otpVerify),
				},
			},

			// #region Signup Via Invite
			{
				path: IntroPageStep.acceptInvite.kebabCase,
				component: AcceptInvitePageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.acceptInvite,
					title: IntroPageStep.acceptInvite.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						BridgerAdminFeature.baAcceptInvite,
						MerchantAdminFeature.acceptInvite,
					]),
				},
			},
			{
				path: IntroPageStep.createAccount.kebabCase,
				component: CreateAccountPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.createAccount,
					title: IntroPageStep.createAccount.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						BridgerAdminFeature.baCreateAccount,
						MerchantAdminFeature.createAccount,
					]),
				},
			},
			{
				path: IntroPageStep.loginAfterInvite.kebabCase,
				component: LoginAfterInvitePageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.loginAfterInvite,
					title: IntroPageStep.loginAfterInvite.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						BridgerAdminFeature.baLoginAfterInvite,
						MerchantAdminFeature.loginAfterInvite,
					]),
				},
			},

			{
				path: IntroPageStep.setSecurityQuestionsAnswers.kebabCase,
				component: SetSecurityQuestionsAnswersPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.setSecurityQuestionsAnswers,
					title: IntroPageStep.setSecurityQuestionsAnswers.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.setSecurityQuestionsAnswers),
				},
			},
			{
				path: IntroPageStep.registerAuthenticatorApp.kebabCase,
				component: RegisterAuthenticatorAppPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.registerAuthenticatorApp,
					title: IntroPageStep.registerAuthenticatorApp.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.registerAuthenticator),
				},
			},
			// #endregion

			// #region reset password
			{
				path: IntroPageStep.sendResetPasswordLink.kebabCase,
				component: SendResetPasswordLinkPageComponent,
				data: {
					step: IntroPageStep.sendResetPasswordLink,
					title: IntroPageStep.sendResetPasswordLink.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetPasswordLinkSent.kebabCase,
				component: ResetPasswordLinkSentPageComponent,
				data: {
					step: IntroPageStep.resetPasswordLinkSent,
					title: IntroPageStep.resetPasswordLinkSent.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetPasswordVerification.kebabCase,
				component: ResetPasswordVerificationPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetPasswordVerification,
					title: IntroPageStep.resetPasswordVerification.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						MerchantAdminFeature.verifyAnswerBeforeResetPassword,
						MerchantAdminFeature.otpResetPasswordVerification,
					]),
				},
			},
			{
				path: IntroPageStep.resetPassword.kebabCase,
				component: ResetPasswordPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetPassword,
					title: IntroPageStep.resetPassword.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						MerchantAdminFeature.resetPassword,
						MerchantAdminFeature.resetExpiredPassword,
					]),
				},
			},
			// #endregion

			// #region reset authenticator app
			{
				path: IntroPageStep.sendResetAuthenticatorAppLink.kebabCase,
				component: SendResetAuthenticatorAppLinkPageComponent,
				data: {
					step: IntroPageStep.sendResetAuthenticatorAppLink,
					title: IntroPageStep.sendResetAuthenticatorAppLink.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetAuthenticatorAppLinkSent.kebabCase,
				component: ResetAuthenticatorAppLinkSentPageComponent,
				data: {
					step: IntroPageStep.resetAuthenticatorAppLinkSent,
					title: IntroPageStep.resetAuthenticatorAppLinkSent.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetAuthenticatorAppVerification.kebabCase,
				component: ResetAuthenticatorAppVerificationPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetAuthenticatorAppVerification,
					title: IntroPageStep.resetAuthenticatorAppVerification.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(
						MerchantAdminFeature.verifyAnswerBeforeResetAuthenticator,
					),
				},
			},
			{
				path: IntroPageStep.resetAuthenticatorApp.kebabCase,
				component: ResetAuthenticatorAppPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetAuthenticatorApp,
					title: IntroPageStep.resetAuthenticatorApp.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.resetAuthenticator),
				},
			},
			// #endregion

			{
				path: IntroPageStep.sessionExpired.kebabCase,
				component: SessionExpiredPageComponent,
				data: {
					step: IntroPageStep.sessionExpired,
					title: IntroPageStep.sessionExpired.titleDisplayName,
				},
			},

			{
				path: '**',
				redirectTo: IntroPageStep.login.kebabCase,
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class AdminsSharedPagesIntroRoutingModule {}
