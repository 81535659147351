import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedFeaturesShareModule } from '@bp/frontend/features/share';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';

import {
	AnnouncementsHubItemComponent,
	AnnouncementModalComponent,
	AnnouncementsHubBodyComponent,
	AnnouncementsHubOpenButtonComponent,
	AnnouncementsHubHeaderComponent,
	AnnouncementsHubComponent
} from './components';

@NgModule({
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		MatListModule,
		MatProgressBarModule,
		MatSidenavModule,
		MatToolbarModule,

		SharedDirectivesCoreModule,
		SharedComponentsCoreModule,
		SharedFeaturesModalModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesMomentPipesModule,
		SharedFeaturesShareModule,
		SharedPipesModule,

		RouterModule.forChild([]),
	],
	exports: [ AnnouncementsHubOpenButtonComponent, AnnouncementsHubComponent ],
	declarations: [
		AnnouncementsHubOpenButtonComponent,
		AnnouncementsHubBodyComponent,
		AnnouncementsHubComponent,
		AnnouncementsHubHeaderComponent,
		AnnouncementsHubItemComponent,
		AnnouncementModalComponent,
	],
})
export class MerchantAdminFrontendFeaturesAnnouncementsModule {}
