<mat-progress-bar *ngIf="_requestSupportFacade.pending$ | async" color="primary" mode="indeterminate" @slideUp />

<h2 mat-dialog-title>Support</h2>

<mat-dialog-content>
	<bp-alert *ngIf="_requestSupportFacade.error$ | async as error" type="danger" [errors]="error.messages" />

	<div *ngIf="_requestSupportFacade.entity$ | async as supportRequest; else requestSupportTpl" @fadeIn>
		<bp-active-request-info [supportRequest]="supportRequest" />

		<ng-container *ngIf="_canEdit$ | async">
			<bp-change-support-duration-form
				*ngIf="_availableSupportRequestDurationChanges$ | async as _availableSupportRequestDurationChanges"
				class="mb-4 mt-4"
				[pending]="_requestSupportFacade.pending$ | async"
				[availableSupportRequestDurationChanges]="_availableSupportRequestDurationChanges"
				(submitted)="_requestSupportFacade.changeDuration($event)"
			/>
		</ng-container>
	</div>

	<ng-template #requestSupportTpl>
		<div @fadeIn>
			<div class="alert alert-info alert-with-border alert-shadowless">
				Bridgerpay Support doesn't have access to your organization
			</div>

			<bp-request-support-form
				#form
				[error]="_requestSupportFacade.error$ | async"
				(submitted)="_requestSupportFacade.grantAccess($event)"
			/>
		</div>
	</ng-template>
</mat-dialog-content>

<mat-dialog-actions class="mt-3">
	<button mat-stroked-button type="button" class="mr-auto" [mat-dialog-close]="false">Cancel</button>

	<ng-container *ngIf="_requestSupportFacade.entity$ | async as supportRequest; else grantAccessTpl">
		<button
			*ngIf="_canEdit$ | async"
			mat-flat-button
			type="button"
			color="warn"
			[disabled]="_requestSupportFacade.pending$ | async"
			(click)="_requestSupportFacade.revokeAccess(supportRequest)"
		>
			Revoke access
		</button>
	</ng-container>

	<ng-template #grantAccessTpl>
		<button
			mat-flat-button
			type="button"
			color="primary"
			[disabled]="_requestSupportFacade.pending$ | async"
			(click)="form?.submit()"
		>
			Grant access
		</button>
	</ng-template>
</mat-dialog-actions>
