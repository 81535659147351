import { createReducer, on } from '@ngrx/store';

import type { NewsSummary, AnnouncementsHubStatePerUser } from '@bp/frontend/domains/newsroom/models';

import type { EntitiesListState } from '@bp/admins-shared/features/entity';
import { composeEntitiesListReducer, ENTITIES_LIST_INITIAL_STATE } from '@bp/admins-shared/features/entity';

import { announcementsHubStatePerUserApiUpdate } from './announcements-api.actions';
import { close, open, updateAnnouncementsHubStatePerUser, ANNOUNCEMENTS_ACTIONS } from './announcements.actions';

export interface IState extends EntitiesListState<NewsSummary> {
	stateForCurrentUser: AnnouncementsHubStatePerUser | null;

	isOpened: boolean;
}

export const initialState: IState = {
	...ENTITIES_LIST_INITIAL_STATE,

	stateForCurrentUser: null,

	isOpened: false,
};

export const reducer = createReducer(
	initialState,

	...composeEntitiesListReducer(initialState, ANNOUNCEMENTS_ACTIONS),

	on(
		announcementsHubStatePerUserApiUpdate,
		updateAnnouncementsHubStatePerUser,
		(state, { result }): IState => ({
			...state,
			stateForCurrentUser: result,
		}),
	),

	on(
		open,
		close,
		(state, { type }): IState => ({
			...state,
			isOpened: type === open.type,
		}),
	),
);
