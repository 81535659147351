import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	templateUrl: './subscription-suspended-page.component.html',
	styleUrls: [ './subscription-suspended-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionSuspendedPageComponent {

}
