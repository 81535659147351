import { combineReducers } from '@ngrx/store';

import * as fromEntity from './entity/announcement.reducer';
import * as fromList from './list/announcements.reducer';

const reducers = {
	entity: fromEntity.reducer,
	list: fromList.reducer,
};

export const FEATURE_KEY = '[feature]:announcements';

export interface IState {
	entity: fromEntity.IState;
	list: fromList.IState;
}

const announcementsFeatureReducer = combineReducers(reducers);

export function reducer(state: any, action: any) {
	return announcementsFeatureReducer(state, action);
}
