import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { ModalHostComponent } from '@bp/frontend/features/modal';

@Component({
	templateUrl: './subscription-fee-is-overdue-modal-page.component.html',
	styleUrls: [ './subscription-fee-is-overdue-modal-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionFeeIsOverdueModalPageComponent extends ModalHostComponent {

	protected readonly _currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	override panelClass = [ 'subscription-fee-is-overdue-modal-page-overlay-pane', 'modal-overlay-pane-big' ];

}
