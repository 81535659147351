import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroFacade } from '../../../state';

@Component({
	selector: 'bp-send-reset-password-link-page',
	templateUrl: './send-reset-password-link-page.component.html',
	styleUrls: [ './send-reset-password-link-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendResetPasswordLinkPageComponent {

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) { }

}
