import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-error-shell',
	templateUrl: './error-shell.component.html',
	styleUrls: [ './error-shell.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorShellComponent {

}
