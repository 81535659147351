import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import type { IChangePasswordApiRequest } from '@bp/admins-shared/domains/identity/models';

import { changePassword } from './user.actions';
import { getUserPageError, getUserPagePending } from './user.selectors';
import { changePasswordSuccess } from './user-api.actions';

@Injectable({ providedIn: 'root' })
export class UserFacade {

	pending$ = this._store$.select(getUserPagePending);

	error$ = this._store$.select(getUserPageError);

	changePasswordSuccess$ = this._actions$.pipe(ofType(changePasswordSuccess));

	constructor(
		private readonly _store$: Store,
		private readonly _actions$: Actions,
	) { }

	changePassword(password: IChangePasswordApiRequest): void {
		this._store$.dispatch(changePassword(password));
	}

}
