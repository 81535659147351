import { filter } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SupportRequest, SupportRequestDurationChange } from '@bp/shared/domains/support-requests';
import { isPresent } from '@bp/shared/utilities/core';

import { SupportRequestCreateModel } from '@bp/merchant-admin/shared/domains/support-requests';

import {
	requestSupport, open, completeSupportRequest, startSupportRequestCheckTimer, changeSupportRequestDuration
} from './request-support.actions';
import { selectEntity, selectError, selectPending } from './request-support.selectors';

@Injectable({ providedIn: 'root' })
export class RequestSupportFacade {

	private readonly __store$ = inject(Store);

	error$ = this.__store$.select(selectError);

	pending$ = this.__store$.select(selectPending);

	entity$ = this.__store$.select(selectEntity);

	presentEntity$ = this.entity$.pipe(filter(isPresent));

	open(): void {
		this.__store$.dispatch(open());
	}

	startPeriodicalCheckForActiveSupportRequest(): void {
		this.__store$.dispatch(startSupportRequestCheckTimer());
	}

	grantAccess(supportRequestCreateModel: SupportRequestCreateModel): void {
		this.__store$.dispatch(requestSupport({ supportRequestCreateModel }));
	}

	revokeAccess(supportRequest: SupportRequest): void {
		this.__store$.dispatch(completeSupportRequest({ supportRequest }));
	}

	changeDuration(supportRequestDurationChange: SupportRequestDurationChange): void {
		this.__store$.dispatch(changeSupportRequestDuration({ supportRequestDurationChange }));
	}

}
