import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';
import { News } from '@bp/frontend/domains/newsroom/models';

export const ACTIONS_SCOPE = '[Feature][AnnouncementsHub][Announcement]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(`${ scope } Load Success`, props<ResultActionPayload<News>>());

export const loadFailure = createAction(`${ scope } Load Failure`, props<ErrorActionPayload>());
