
import { obscureSensitiveDataInUrl } from '@bp/shared/models/core';
import { DTO, MapFromDTO, Mapper } from '@bp/shared/models/metadata';
import { Platform } from '@bp/shared/typings';

import { EventsTrackingAnalytics } from '@bp/frontend/features/firebase/models';
import { IUtmTags } from '@bp/frontend/features/analytics/utms-models';

export type CrmUserAnalyticsDTO = DTO<CrmUserAnalytics>;

export class CrmUserAnalytics extends EventsTrackingAnalytics {

	static {
		this._initClassMetadata();
	}

	@MapFromDTO()
	firstVisitPlatform?: Platform;

	@MapFromDTO()
	firstVisitedPageTitle?: string;

	@Mapper(obscureSensitiveDataInUrl)
	firstVisitedPageUrl?: string;

	@Mapper(obscureSensitiveDataInUrl)
	firstRefererPageUrl?: string;

	@Mapper(obscureSensitiveDataInUrl)
	lastRefererPageUrl?: string;

	@MapFromDTO()
	lastUserSessionRecordingUrl?: string;

	@MapFromDTO()
	lastVisitedPageTitle?: string;

	@Mapper(obscureSensitiveDataInUrl)
	lastVisitedPageUrl?: string;

	@MapFromDTO()
	lastFrontendVersion?: string;

	@MapFromDTO()
	lastBackendVersion?: string;

	@MapFromDTO()
	wizardSetupId?: string;

	@MapFromDTO()
	wizardSessionId?: string;

	@MapFromDTO()
    utmTags?: IUtmTags;

	constructor(dto?: CrmUserAnalyticsDTO) {
		super(dto);
	}

}
