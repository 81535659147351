<div class="h-100">
	<div class="text-center">
		This extra step verifies it's really you trying to reset authenticator app for
		<strong> {{ (identityFacade.incompleteIdentity$ | async)?.email }}</strong>
	</div>

	<bp-provide-security-question-answer-form
		[pending]="introFacade.pending$ | async"
		[error]="introFacade.error$ | async"
		[identitySecurityQuestions]="identityFacade.identitySecurityQuestions$ | async"
		(submitted)="identityFacade.verifySecurityQuestionAnswer($event)"
	/>
</div>
