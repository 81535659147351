import { createAction, props } from '@ngrx/store';

import { Organization } from '@bp/shared/domains/organizations';

import {
	composeEntitiesListActions, EntitiesListActions, EntityActionPayload
} from '@bp/admins-shared/features/entity';

import { AccessibleOrganizationsQueryParams } from '../../models';

import { ACTIONS_SCOPE, ORGANIZATIONS_LIST_API_ACTIONS } from './accessible-organizations-api.actions';

export const ORGANIZATIONS_LIST_ACTIONS: EntitiesListActions<Organization, AccessibleOrganizationsQueryParams> = {
	...composeEntitiesListActions(ACTIONS_SCOPE),

	api: ORGANIZATIONS_LIST_API_ACTIONS,
};

export const confirmConnectToOrganization = createAction(
	`${ ACTIONS_SCOPE } Confirm Connect To Organization`,
	props<EntityActionPayload<Organization>>(),
);

export const dismissConfirmConnectToOrganization = createAction(
	`${ ACTIONS_SCOPE } Dismiss Connect To Organization`,
);

export const connectToOrganization = createAction(
	`${ ACTIONS_SCOPE } Connect To Organization`,
	props<EntityActionPayload<Organization>>(),
);
