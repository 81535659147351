// eslint-disable-next-line @typescript-eslint/naming-convention
import QRCode from 'qrcode';
import { firstValueFrom } from 'rxjs';

import type { AfterViewInit, ElementRef } from '@angular/core';
import {
	ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren
} from '@angular/core';

import { firstPresentQueryListItem } from '@bp/shared/utilities/core';
import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';
import { FADE_IN } from '@bp/frontend/animations';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';
import { GtagEvents } from '@bp/frontend/features/analytics/models';
import { AsyncVoidSubject } from '@bp/frontend/rxjs';

import type { IRegisterAuthenticatorAppApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-register-authenticator-app-form',
	templateUrl: './register-authenticator-app-form.component.html',
	styleUrls: [ './register-authenticator-app-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class RegisterAuthenticatorAppFormComponent
	extends FormBaseComponent<IRegisterAuthenticatorAppApiRequest>
	implements OnChanges, AfterViewInit {

	private _authenticatorKey!: string | null;

	@Input() userEmail?: string | null;

	@Input() submitButtonText!: string;

	@Input() submitButtonGtagEventName!: GtagEvents.List;

	@Input()
	get authenticatorKey(): string | null {
		return this._authenticatorKey;
	}

	set authenticatorKey(value: string | null) {
		this._authenticatorKey = value;

		this.controls!.key!.setValue(value);
	}

	@Output() readonly login = new EventEmitter();

	@ViewChildren('qrCodeCanvas')
	qrCodeCanvasQueryList!: QueryList<ElementRef>;

	override onSubmitShowInvalidInputsToast = false;

	private readonly _viewInited$ = new AsyncVoidSubject();

	constructor() {
		super();

		this.form = this._formBuilder.group(ensureFormGroupConfig<IRegisterAuthenticatorAppApiRequest>({
			key: [ null ],
			code: [ null, { validators: Validators.required }],
		}));
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if ((changes.userEmail || changes.authenticatorKey) && this.userEmail && this.authenticatorKey)
			void this._mountAuthenticatorQRCode();
	}

	ngAfterViewInit(): void {
		this._viewInited$.complete();
	}

	private async _mountAuthenticatorQRCode(): Promise<void> {
		await firstValueFrom(this._viewInited$);

		const $QRCodeCanvas = await firstPresentQueryListItem(
			this.qrCodeCanvasQueryList,
			elementRef => <HTMLCanvasElement>elementRef.nativeElement,
		);

		const issuer = 'bridgerpay';

		void QRCode.toCanvas(
			$QRCodeCanvas,
			`otpauth://totp/${ issuer }:${ this.userEmail }?secret=${ this.authenticatorKey }&issuer=${ issuer }`,
			{
				margin: 0,
				width: 175,
			},
		);
	}

}
