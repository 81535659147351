import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './user.reducer';
import { FEATURE_KEY } from './user.reducer';

export const selectPageState = createFeatureSelector<IState>(FEATURE_KEY);

export const getUserPageError = createSelector(
	selectPageState,
	s => s.error,
);

export const getUserPagePending = createSelector(
	selectPageState,
	s => s.pending,
);
