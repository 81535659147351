import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { FADE } from '@bp/frontend/animations';

import { RequestSupportFacade } from '../../state';

@Component({
	selector: 'bp-request-support-open-button',
	templateUrl: './request-support-open-button.component.html',
	styleUrls: [ './request-support-open-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class RequestSupportOpenButtonComponent implements OnInit {

	protected readonly _requestSupportFacade = inject(RequestSupportFacade);

	ngOnInit(): void {
		this._requestSupportFacade.startPeriodicalCheckForActiveSupportRequest();
	}

}
