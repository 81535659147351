import { of, EMPTY, exhaustMap, mergeMap, map, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { apiResult, apiVoidResult } from '@bp/frontend/models/common';
import { GoogleTagService } from '@bp/frontend/features/analytics';

import {
	INCOMPLETE_SESSION_EXPIRED_ROUTE_PATHNAME, INTRO_ROUTE_PATHSEGMENT,
	LOGIN_ROUTE_PATHNAME
} from '@bp/admins-shared/domains/identity/models';

import { IdentityApiService } from '../services';
import { tryCreateIdentityBasedOnLoginQueryParams } from '../utils';
import { InviteApiService } from '../services/invite-api.service';

import {
	createAccount, loadAllSecurityQuestions, loadAuthenticatorAppKey, loadIdentitySecurityQuestions,
	registerAuthenticatorApp, resetAuthenticatorApp, resetPassword, resetPasswordOtpVerification,
	sendResetAuthenticatorAppLink, sendResetPasswordLink, setSecurityQuestionsAnswers, loginOtpVerification,
	verifySecurityQuestionsAnswers, acceptInvite
} from './identity.actions';
import { IdentityFacade } from './identity.facade';
import {
	createAccountFailure, createAccountSuccess, loadAllSecurityQuestionsFailure,
	loadAllSecurityQuestionsSuccess, loadAuthenticatorAppKeyFailure, loadAuthenticatorAppKeySuccess,
	loadIdentitySecurityQuestionsFailure, loadIdentitySecurityQuestionsSuccess, registerAuthenticatorFailure,
	registerAuthenticatorSuccess, resetAuthenticatorAppFailure, resetAuthenticatorAppSuccess,
	resetPasswordFailure, resetPasswordOtpVerificationFailure, resetPasswordOtpVerificationSuccess,
	resetPasswordSuccess, sendResetAuthenticatorAppLinkFailure, sendResetAuthenticatorAppLinkSuccess,
	sendResetPasswordLinkFailure, sendResetPasswordLinkSuccess, setSecurityQuestionsAnswersFailure,
	setSecurityQuestionsAnswersSuccess, loginOtpVerificationFailure, loginOtpVerificationSuccess,
	verifySecurityQuestionsAnswersFailure, verifySecurityQuestionsAnswersSuccess, acceptInviteFailure,
	acceptInviteSuccess
} from './identity-api.actions';
import {
	incompleteIdentityEffectsInit, incompleteIdentitySessionExpired,
	setIncompleteIdentityBasedOnLoginQueryParams, startIncompleteIdentitySessionExpiryTimer,
	stopIncompleteIdentitySessionExpiryTimer
} from './incomplete-identity.actions';

@Injectable()
export class IncompleteIdentityEffects implements OnInitEffects {

	private static readonly _isNotIncompleteIdentityRoute = [
		LOGIN_ROUTE_PATHNAME,
		INCOMPLETE_SESSION_EXPIRED_ROUTE_PATHNAME,
	].includes(window.location.pathname)
			|| !window.location.pathname.startsWith(INTRO_ROUTE_PATHSEGMENT);

	trySetIdentityBasedOnLoginQueryParams$ = createEffect(() => this._actions$.pipe(
		ofType(incompleteIdentityEffectsInit),
		mergeMap(() => IncompleteIdentityEffects._isNotIncompleteIdentityRoute
			? EMPTY
			: of(setIncompleteIdentityBasedOnLoginQueryParams({
				identity: tryCreateIdentityBasedOnLoginQueryParams(),
			}))),
	));

	loginOtpVerification$ = createEffect(() => this._actions$.pipe(
		ofType(loginOtpVerification),
		exhaustMap(payload => this._identityApiService
			.loginOtpVerification(payload)
			.pipe(apiResult(loginOtpVerificationSuccess, loginOtpVerificationFailure))),
	));

	// #region Token Expiration Timer

	whenIncompleteIdentityChangedToggleExpiryTimer$ = createEffect(
		() => this._identityFacade.incompleteIdentity$.pipe(
			map(identity => identity
				? startIncompleteIdentitySessionExpiryTimer({
					expiresAt: identity.sessionExpiresAt,
				})
				: stopIncompleteIdentitySessionExpiryTimer()),
		),
	);

	incompleteIdentitySessionExpiryTimer$ = createEffect(() => this._actions$.pipe(
		ofType(startIncompleteIdentitySessionExpiryTimer, stopIncompleteIdentitySessionExpiryTimer),
		switchMap(action => action.type === startIncompleteIdentitySessionExpiryTimer.type
			? timer(action.expiresAt.toDate())
				.pipe(map(() => incompleteIdentitySessionExpired()))
			: EMPTY),
	));

	whenIncompleteSessionExpiredNavigateToExpiredPage$ = createEffect(
		() => this._actions$.pipe(
			ofType(incompleteIdentitySessionExpired),
			tap(() => {
				this._identityFacade.removeIdentity();

				void this._router.navigateByUrl(INCOMPLETE_SESSION_EXPIRED_ROUTE_PATHNAME);
			}),
		),
		{ dispatch: false },
	);

	// #endregion

	// #region Signup Via Invite

	acceptInvite$ = createEffect(() => this._actions$.pipe(
		ofType(acceptInvite),
		exhaustMap(() => this._inviteApiService
			.acceptInvite()
			.pipe(apiResult(acceptInviteSuccess, acceptInviteFailure))),
	));

	createAccount$ = createEffect(() => this._actions$.pipe(
		ofType(createAccount),
		exhaustMap(payload => this._inviteApiService
			.createAccount(payload)
			.pipe(apiResult(createAccountSuccess, createAccountFailure))),
	));

	// #endregion

	// #region Continue Signup

	loadAllSecurityQuestions$ = createEffect(() => this._actions$.pipe(
		ofType(loadAllSecurityQuestions),
		exhaustMap(() => this._identityApiService
			.getAllSecurityQuestions()
			.pipe(apiResult(loadAllSecurityQuestionsSuccess, loadAllSecurityQuestionsFailure))),
	));

	setSecurityQuestionsAnswers$ = createEffect(() => this._actions$.pipe(
		ofType(setSecurityQuestionsAnswers),
		exhaustMap(payload => this._identityApiService
			.setSecurityQuestionsAnswers(payload)
			.pipe(apiResult(setSecurityQuestionsAnswersSuccess, setSecurityQuestionsAnswersFailure))),
	));

	loadAuthenticatorAppKey$ = createEffect(() => this._actions$.pipe(
		ofType(loadAuthenticatorAppKey),
		exhaustMap(() => this._identityApiService
			.getAuthenticatorAppKey()
			.pipe(apiResult(loadAuthenticatorAppKeySuccess, loadAuthenticatorAppKeyFailure))),
	));

	registerAuthenticatorApp$ = createEffect(() => this._actions$.pipe(
		ofType(registerAuthenticatorApp),
		exhaustMap(payload => this._identityApiService
			.registerAuthenticatorApp(payload)
			.pipe(apiResult(registerAuthenticatorSuccess, registerAuthenticatorFailure))),
	));

	onRegisterAuthenticatorAppSuccess$ = createEffect(
		() => this._actions$.pipe(
			ofType(registerAuthenticatorSuccess),
			tap(() => void this._googleTagService.dispatchEvent('authenticator_connected')),
		),
		{ dispatch: false },
	);

	// #endregion

	// #region reset

	loadIdentitySecurityQuestions$ = createEffect(() => this._actions$.pipe(
		ofType(loadIdentitySecurityQuestions),
		exhaustMap(() => this._identityApiService
			.getIdentitySecurityQuestions()
			.pipe(apiResult(loadIdentitySecurityQuestionsSuccess, loadIdentitySecurityQuestionsFailure))),
	));

	verifySecurityQuestionsAnswers$ = createEffect(() => this._actions$.pipe(
		ofType(verifySecurityQuestionsAnswers),
		exhaustMap(payload => this._identityApiService
			.verifySecurityQuestionsAnswers(payload)
			.pipe(apiResult(verifySecurityQuestionsAnswersSuccess, verifySecurityQuestionsAnswersFailure))),
	));

	// #region reset password

	sendResetPasswordLink$ = createEffect(() => this._actions$.pipe(
		ofType(sendResetPasswordLink),
		exhaustMap(payload => this._identityApiService
			.sendResetPasswordLink(payload)
			.pipe(apiVoidResult(sendResetPasswordLinkSuccess, sendResetPasswordLinkFailure))),
	));

	resetPasswordOtpVerification$ = createEffect(() => this._actions$.pipe(
		ofType(resetPasswordOtpVerification),
		exhaustMap(payload => this._identityApiService
			.resetPasswordOtpVerification(payload)
			.pipe(apiResult(resetPasswordOtpVerificationSuccess, resetPasswordOtpVerificationFailure))),
	));

	resetPassword$ = createEffect(() => this._actions$.pipe(
		ofType(resetPassword),
		exhaustMap(payload => (payload.isExpiredPasswordReset
			? this._identityApiService.resetExpiredPassword(payload)
			: this._identityApiService.resetPassword(payload))
			.pipe(apiResult(resetPasswordSuccess, resetPasswordFailure))),
	));

	// #endregion

	// #region reset authenticator app

	sendResetAuthenticatorAppLink$ = createEffect(() => this._actions$.pipe(
		ofType(sendResetAuthenticatorAppLink),
		exhaustMap(() => this._identityApiService
			.sendResetAuthenticatorAppLink()
			.pipe(apiVoidResult(sendResetAuthenticatorAppLinkSuccess, sendResetAuthenticatorAppLinkFailure))),
	));

	resetAuthenticatorApp$ = createEffect(() => this._actions$.pipe(
		ofType(resetAuthenticatorApp),
		exhaustMap(payload => this._identityApiService
			.resetAuthenticatorApp(payload)
			.pipe(apiResult(resetAuthenticatorAppSuccess, resetAuthenticatorAppFailure))),
	));

	onResetAuthenticatorAppSuccess$ = createEffect(
		() => this._actions$.pipe(
			ofType(resetAuthenticatorAppSuccess),
			tap(() => void this._googleTagService.dispatchEvent('authenticator_reset')),
		),
		{ dispatch: false },
	);

	// #endregion

	// #endregion

	constructor(
		private readonly _identityFacade: IdentityFacade,
		private readonly _actions$: Actions,
		private readonly _identityApiService: IdentityApiService,
		private readonly _router: Router,
		private readonly _googleTagService: GoogleTagService,
		private readonly _inviteApiService: InviteApiService,
	) {

	}

	ngrxOnInitEffects(): Action {
		return incompleteIdentityEffectsInit();
	}

}
