import { SlidingStepRoute } from '@bp/frontend/features/sliding-step-routes/models';

// The order of props is used for creating the transition animation between steps
export class IntroPageStep extends SlidingStepRoute {

	static login = new IntroPageStep('Log in to your account');

	static otpOnLogin = new IntroPageStep('2-Step Verification');

	static setPassword = new IntroPageStep('Set Password');

	// #region Signup Via Invite

	static acceptInvite = new IntroPageStep('Invitation');

	static createAccount = new IntroPageStep('Create Account');

	static loginAfterInvite = new IntroPageStep('Invitation');

	// #endregion

	// #region deprecated, remove after accepting invite implementation by backend

	static emailVerification = new IntroPageStep('Email Verification');

	// #endregion !deprecated

	// #region Continue Signup

	static setSecurityQuestionsAnswers = new IntroPageStep('Security questions');

	static registerAuthenticatorApp = new IntroPageStep('Authenticator connection');

	// #endregion

	// #region reset password

	static sendResetPasswordLink = new IntroPageStep('Reset password');

	static resetPasswordLinkSent = new IntroPageStep('Email sent!');

	static resetPasswordVerification = new IntroPageStep('2-step verification');

	static resetPassword = new IntroPageStep('Reset Password');

	// #endregion

	// #region reset authenticator app

	static sendResetAuthenticatorAppLink = new IntroPageStep('Reset authenticator app');

	static resetAuthenticatorAppLinkSent = new IntroPageStep('Email sent!');

	static resetAuthenticatorAppVerification = new IntroPageStep('Identity verification');

	static resetAuthenticatorApp = new IntroPageStep('Reset authenticator app');

	// #endregion

	static sessionExpired = new IntroPageStep('Your session has expired');

}
