import { Injectable } from '@angular/core';

import { ModalService } from '@bp/frontend/features/modal';

import { LayoutFacade } from './layout.facade';

@Injectable()
export class LayoutEffects {

	constructor(
		private readonly _modalService: ModalService,
		private readonly _layoutFacade: LayoutFacade,
	) {
		this._closeFloatOutletsOnAction();
	}

	private _closeFloatOutletsOnAction(): void {
		this._layoutFacade.closeFloatOutlets$.subscribe(() => {
			this._layoutFacade.closeSidenav();

			this._modalService.closeAll();
		});
	}

}
