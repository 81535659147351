import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { FADE } from '@bp/frontend/animations';
import { BaseDialogComponent } from '@bp/frontend/components/dialogs';

import { PaymentChallengeDialogData, PaymentChallengeDialogResult } from '../../models';

@Component({
	selector: 'bp-subscription-plan-payment-challenge-dialog',
	templateUrl: './subscription-plan-payment-challenge-dialog.component.html',
	styleUrls: [ './subscription-plan-payment-challenge-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class SubscriptionPlanPaymentChallengeDialogComponent
	extends BaseDialogComponent<PaymentChallengeDialogData, PaymentChallengeDialogResult> {

	private readonly __domSanitizer = inject(DomSanitizer);

	protected _isChallengeLoaded = false;

	protected readonly _challengeHTML = this.__domSanitizer.bypassSecurityTrustHtml(this._dialogData.challengeHTML);

	constructor() {
		super();

		this._dialogRef.addPanelClass('subscription-plan-payment-challenge-dialog-overlay-pane');
	}

	@HostListener('window:message', [ '$event' ])
	protected _onIframeMessage({ data: { event, status, reason } }: MessageEvent<PaymentChallengeDialogResult & { event: string }>): void {
		if (event !== '[bp]:3d-secure-result')
			return;

		this._dialogRef.close({ status, reason });
	}

	protected _canCloseWithTrueOnEnter(): boolean {
		return false;
	}

}
