import { createAction, props } from '@ngrx/store';

import type {
	NewsSummariesQueryParams,
	NewsSummary,
	AnnouncementsHubStatePerUser
} from '@bp/frontend/domains/newsroom/models';

import type { EntitiesListActions } from '@bp/admins-shared/features/entity';
import { composeEntitiesListActions } from '@bp/admins-shared/features/entity';

import { ACTIONS_SCOPE, ANNOUNCEMENTS_API_ACTIONS } from './announcements-api.actions';

export type MarkAnnouncementsAsSeenRequest = { announcements: NewsSummary[]; showNotificationDot?: boolean };

export const ANNOUNCEMENTS_ACTIONS: EntitiesListActions<NewsSummary, NewsSummariesQueryParams> = {
	...composeEntitiesListActions(ACTIONS_SCOPE),

	api: ANNOUNCEMENTS_API_ACTIONS,
};

export const open = createAction(`${ ACTIONS_SCOPE } Open`);

export const close = createAction(`${ ACTIONS_SCOPE } Close`);

export const listenToAnnouncementsHubStatePerUserChanges = createAction(
	`${ ACTIONS_SCOPE } Listen To Announcements Hub State Per User Changes`,
);

export const updateAnnouncementsHubStatePerUser = createAction(
	`${ ACTIONS_SCOPE } Update Announcements Hub State Per User`,
	props<{ result: AnnouncementsHubStatePerUser }>(),
);

export const markAnnouncementsAsSeen = createAction(
	`${ ACTIONS_SCOPE } Mark Announcements As Seen`,
	props<MarkAnnouncementsAsSeenRequest>(),
);
