import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ModalHostComponent } from '@bp/frontend/features/modal';

import { CURRENT_SUBSCRIPTION_MANAGEMENT_OVERLAY_PANE_CLASS } from '../../models';

@Component({
	selector: 'bp-current-subscription-management-modal',
	templateUrl: './current-subscription-management-modal.component.html',
	styleUrls: [ './current-subscription-management-modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSubscriptionManagementModalComponent extends ModalHostComponent {
	private readonly __activatedRoute = inject(ActivatedRoute);

	override panelClass = [
		CURRENT_SUBSCRIPTION_MANAGEMENT_OVERLAY_PANE_CLASS,
	];

	override disableClose = this.__activatedRoute.snapshot.paramMap.has('disableClose');
}
