import { createAction, props } from '@ngrx/store';

import { IntroPageStep } from '@bp/admins-shared/pages/intro/models';

const ACTIONS_SCOPE = '[Page][Intro]';

export const changeIntroPageStep = createAction(
	`${ ACTIONS_SCOPE } Change Intro Page Step`,
	props<{ step: IntroPageStep }>(),
);
