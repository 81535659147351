import { animate, animateChild, animation, group, query, style, transition, trigger, useAnimation } from '@angular/animations';

const optional = {
	optional: true,
};

const translate = animation([
	style({
		position: 'relative',
		overflow: 'hidden',
	}),
	query(
		':enter',
		[
			style({
				transform: 'translate3d({{enter}}, 0, 0)',
				minHeight: '1px',
			}),
		],
	),
	query(
		':leave',
		[
			style({
				position: 'absolute',
				width: '100%',
			}),
		],
		optional,
	),
	query(':leave', animateChild(), optional),
	group([
		query(
			':leave',
			[
				animate(
					'{{timing}}s cubic-bezier(0.35, 0, 0.25, 1)',
					style({ transform: 'translate3d({{leave}}, 0, 0)' }),
				),
			],
			optional,
		),
		query(':enter', [
			animate(
				'{{timing}}s cubic-bezier(0.35, 0, 0.25, 1)',
				style({ transform: 'translate3d(0%, 0, 0)' }),
			),
		]),
	]),
	query(':enter', animateChild()),
]);

const DURATION = 0.5;

export const SLIDING_STEP_ROUTES_ANIMATION_TRIGGER = trigger('translateRoutesRightLeft', [
	transition('* => right', useAnimation(translate, {
		params: {
			enter: '-100%',
			leave: '100%',
			timing: DURATION,
		},
	})),
	transition('* => left', useAnimation(translate, {
		params: {
			enter: '100%',
			leave: '-100%',
			timing: DURATION,
		},
	})),
]);
