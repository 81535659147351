import { DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { BridgerPspIndustry } from './bridger-psp-industry';
import { BridgerPspLegalEntityType } from './bridger-psp-legal-entity-type';
import { BridgerPspLicense } from './bridger-psp-license';
import { BridgerPspLocation } from './bridger-psp-location';
import { BridgerPspMonthlyTurnoverVolume } from './bridger-psp-monthly-turnover-volume';
import { BridgerPspProcessingHistory } from './bridger-psp-processing-history';

export class BridgerPspIntroductionRequestInfo extends MetadataEntity {

	static {
		this._initClassMetadata();
	}

	@Mapper(BridgerPspLocation)
	locations?: BridgerPspLocation[];

	@Mapper(BridgerPspIndustry)
	industries?: BridgerPspIndustry[];

	@Mapper(BridgerPspLicense)
	licenses?: BridgerPspLicense[];

	@Mapper(BridgerPspLocation)
	legalEntityLocation?: BridgerPspLocation;

	@Mapper(BridgerPspProcessingHistory)
	processingHistory?: BridgerPspProcessingHistory;

	@Mapper(BridgerPspMonthlyTurnoverVolume)
	monthlyTurnoverVolume?: BridgerPspMonthlyTurnoverVolume;

	@Mapper(BridgerPspLegalEntityType)
	legalEntityType?: BridgerPspLegalEntityType;

	constructor(dto?: DTO<BridgerPspIntroductionRequestInfo>) {
		super(dto);
	}

}
