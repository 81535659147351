import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminsSharedFeaturesLayoutModule } from './admins-shared-features-layout.module';
import { LayoutEffects } from './state/layout.effects';
import { FEATURE_KEY, reducer } from './state/layout.reducer';

@NgModule({
	imports: [
		AdminsSharedFeaturesLayoutModule,

		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ LayoutEffects ]),
	],
})
export class AdminsSharedFeaturesLayoutRootModule { }
