import { BehaviorSubject, map } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { takeFirstPresent } from '@bp/frontend/rxjs';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { FADE_IN } from '@bp/frontend/animations';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroFacade } from '../../../state';

enum ResetPasswordVerificationWay {
	Otp,
	ProvideSecurityQuestionAnswer,
}

@Component({
	selector: 'bp-reset-password-verification-page',
	templateUrl: './reset-password-verification-page.component.html',
	styleUrls: [ './reset-password-verification-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class ResetPasswordVerificationPageComponent extends Destroyable {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	ResetPasswordVerificationWay = ResetPasswordVerificationWay;

	// eslint-disable-next-line rxjs/no-exposed-subjects
	currentWay$ = new BehaviorSubject<ResetPasswordVerificationWay>(
		ResetPasswordVerificationWay.ProvideSecurityQuestionAnswer,
	);

	hasAuthApp$ = this.identityFacade.incompleteIdentity$.pipe(
		takeFirstPresent,
		map(identity => identity.hasPermission(MerchantAdminFeature.otpResetPasswordVerification)),
	);

	constructor(public introFacade: IntroFacade, public identityFacade: IdentityFacade) {
		super();

		this.identityFacade.loadIdentitySecurityQuestions();

		this._setInitialResetPasswordVerificationWayBasedOnIdentity();

		this._resetErrorOnResetWayChange();
	}

	private _setInitialResetPasswordVerificationWayBasedOnIdentity(): void {
		this.hasAuthApp$
			.pipe(takeUntilDestroyed(this))
			.subscribe(hasAuthApp => hasAuthApp && void this.currentWay$.next(ResetPasswordVerificationWay.Otp));
	}

	private _resetErrorOnResetWayChange(): void {
		this.currentWay$.pipe(takeUntilDestroyed(this)).subscribe(() => void this.introFacade.resetError());
	}
}
