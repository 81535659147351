import { first, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PaymentChallengeDialogResult } from '../models';

import { paymentChallengeDialogResult, showPaymentChallengeDialog } from './subscription-plan-payment-challenge.actions';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionPlanPaymentChallengeFacade {

	private readonly __actions$ = inject(Actions);

	private readonly __store$ = inject(Store);

	showDialog(challengeHTML: string): Observable<PaymentChallengeDialogResult> {
		this.__store$.dispatch(showPaymentChallengeDialog({ challengeHTML }));

		return this.__actions$.pipe(
			ofType(paymentChallengeDialogResult),
			// eslint-disable-next-line rxjs/no-unsafe-first
			first(),
		);
	}

}
