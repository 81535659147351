import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';

import { Notification } from '../../models';
import { NotificationsHubFacade } from '../../state';

@Component({
	selector: 'bp-notification-hub-notification-item',
	templateUrl: './notifications-hub-notification-item.component.html',
	styleUrls: [ './notifications-hub-notification-item.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsHubNotificationItemComponent {

	@Input() notification!: Notification;

	constructor(
		public notificationsHubFacade: NotificationsHubFacade,
	) {
	}

	@HostListener('click')
	markAsReadOnClick() {
		this.notificationsHubFacade.setReadState(this.notification, true);
	}
}
