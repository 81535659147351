import { isArray, isNil } from 'lodash-es';

import { DTO } from '@bp/shared/models/metadata';

import { BridgerPspIntroductionRequestInfo } from '@bp/frontend/domains/bridger-psps/core';

import { PspIntroductionRequests } from './psp-introduction-requests.type';

export function pspIntroductionRequestInfoMapper(
	requests?: DTO<PspIntroductionRequests> | string[],
): PspIntroductionRequests | undefined {
	if (isNil(requests))
		return requests;

	return Object.fromEntries(
		isArray(requests)
			? requests.map(request => [ request, new BridgerPspIntroductionRequestInfo({}) ])
			: Object.entries(requests).map(([ key, value ]) => [
				key,
				new BridgerPspIntroductionRequestInfo(value),
			]),
	);
}
