import { exhaustMap } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult, apiVoidResult } from '@bp/frontend/models/common';

import { CurrentMemberApiService } from '../../services';

import { trackMember } from './current-member.actions';
import { getMemberHashFailure, getMemberHashSuccess, trackMemberFailure, trackMemberSuccess } from './current-member-api.actions';

@Injectable()
export class CurrentMemberEffects {

	private readonly __membersApiService = inject(CurrentMemberApiService);

	private readonly __actions$ = inject(Actions);

	trackMember$ = createEffect(() => this.__actions$.pipe(
		ofType(trackMember),
		exhaustMap(() => this.__membersApiService.track()
			.pipe(apiVoidResult(trackMemberSuccess, trackMemberFailure))),
	));

	getMemberHash$ = createEffect(() => this.__actions$.pipe(
		ofType(trackMember),
		exhaustMap(() => this.__membersApiService.getMemberHash()
			.pipe(apiResult(getMemberHashSuccess, getMemberHashFailure))),
	));

}
