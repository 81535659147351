import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedFeaturesSlidingStepRoutesModule } from '@bp/frontend/features/sliding-step-routes';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { SharedComponentsMetadataModule } from '@bp/frontend/components/metadata';
import { SharedFeaturesOauthModule } from '@bp/frontend/features/oauth';
import { SpinningLogoComponent } from '@bp/frontend/components/spinning-logo';
import { TurnstileWidgetComponent, TurnstileWidgetSpinnerComponent } from '@bp/frontend/features/turnstile';

import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';

import { AdminsSharedPagesIntroRoutingModule } from './admins-shared-pages-intro-routing.module';
import {
	CreateAccountFormComponent, CreateAccountPageComponent, AcceptInvitePageComponent, IntroShellPageComponent,
	LoginFormComponent, LoginPageComponent, OtpVerificationFormComponent, RegisterAuthenticatorAppPageComponent, ResetPasswordFormComponent,
	ResetPasswordLinkSentPageComponent, ResetPasswordPageComponent, ResetPasswordVerificationPageComponent,
	SendResetPasswordLinkFormComponent, SendResetPasswordLinkPageComponent, SetSecurityQuestionsAnswersFormComponent,
	SetSecurityQuestionsAnswersPageComponent, OtpOnLoginFormComponent, OtpOnLoginPageComponent, SendResetAuthenticatorAppLinkPageComponent,
	ResetAuthenticatorAppLinkSentPageComponent, ResetAuthenticatorAppPageComponent, ResetAuthenticatorAppVerificationPageComponent,
	SessionExpiredPageComponent, IntroShellPageFooterComponent, LoginAfterInvitePageComponent
} from './pages';
import { ProvideSecurityQuestionAnswerFormComponent, RegisterAuthenticatorAppFormComponent } from './components';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatButtonModule,
		MatCardModule,
		MatProgressBarModule,
		MatSelectModule,

		SpinningLogoComponent,
		TurnstileWidgetComponent,
		TurnstileWidgetSpinnerComponent,

		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedComponentsMetadataModule,
		SharedDirectivesCoreModule,
		SharedPipesModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesValidationModule,
		SharedFeaturesSlidingStepRoutesModule,
		SharedFeaturesAnalyticsModule,
		SharedFeaturesOauthModule,

		AdminsSharedPagesIntroRoutingModule,
		AdminsSharedDomainsIdentityModule,
	],
	declarations: [
		IntroShellPageComponent,
		IntroShellPageFooterComponent,

		LoginPageComponent,
		LoginFormComponent,
		OtpOnLoginPageComponent,
		OtpOnLoginFormComponent,

		SessionExpiredPageComponent,
		RegisterAuthenticatorAppFormComponent,

		// signup-via-link
		AcceptInvitePageComponent,
		CreateAccountPageComponent,
		CreateAccountFormComponent,
		LoginAfterInvitePageComponent,

		// continue signup
		SetSecurityQuestionsAnswersPageComponent,
		SetSecurityQuestionsAnswersFormComponent,
		RegisterAuthenticatorAppPageComponent,

		// reset password
		SendResetPasswordLinkPageComponent,
		SendResetPasswordLinkFormComponent,
		ResetPasswordLinkSentPageComponent,
		ResetPasswordPageComponent,
		ResetPasswordFormComponent,
		ResetPasswordVerificationPageComponent,
		OtpVerificationFormComponent,

		// reset auth app
		SendResetAuthenticatorAppLinkPageComponent,
		ResetAuthenticatorAppLinkSentPageComponent,
		ResetAuthenticatorAppPageComponent,
		ResetAuthenticatorAppVerificationPageComponent,

		ProvideSecurityQuestionAnswerFormComponent,
	],

})
export class AdminsSharedPagesIntroModule { }
