import { createFeature, combineReducers } from '@ngrx/store';

import { accessibleOrganizationsFeature } from './accessible-organizations/accessible-organizations.feature';
import { currentMemberFeature } from './current-member/current-member.feature';

const reducer = combineReducers({
	[accessibleOrganizationsFeature.name]: accessibleOrganizationsFeature.reducer,
	[currentMemberFeature.name]: currentMemberFeature.reducer,
});

export const currentMemberDomainFeature = createFeature({
	name: '[domain]:current-member',
	reducer,
});
