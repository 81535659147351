import { RequiredDTO, Mapper } from '@bp/shared/models/metadata';

import { WriteEnvironmentFirebaseDocumentApiRequest } from '@bp/frontend/features/firebase/models';

import { CrmUser } from './crm-user';

export class WriteCrmUserApiRequest extends WriteEnvironmentFirebaseDocumentApiRequest {

	static {
		this._initClassMetadata();
	}

	@Mapper(CrmUser)
	data!: CrmUser;

	constructor(dto: RequiredDTO<WriteCrmUserApiRequest>) {
		super(dto);
	}

}
