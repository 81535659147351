<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<!-- to kick in the browsers save password feature with upon login -->
	<input type="text" name="username" hidden [value]="email" />

	<bp-property-metadata-control
		*ngFor="let propertyMetadata of controlsScheme; let isFirst = first"
		class="w-100"
		[class.mt-n2]="isFirst"
		[metadata]="propertyMetadata"
		[control]="getControlByPropertyMetadata(propertyMetadata)!"
	/>

	<bp-password-input required label="Password" nativeAutocomplete="new-password" formControlName="password" />

	<bp-password-input
		required
		class="mb-4"
		label="Confirm Password"
		nativeAutocomplete="new-password"
		formControlName="confirmPassword"
	/>

	<button mat-flat-button type="submit" class="bp-round-button mt-auto" color="primary" [disabled]="pending$ | async">
		Create
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="login.emit()">Login</button>
	</div>
</form>
