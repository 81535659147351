<mat-progress-bar
	*ngIf="announcementsFacade.pending$ | async"
	class="absolute"
	color="primary"
	mode="indeterminate"
	@slide
/>

<bp-announcements-hub-item
	*ngFor="let announcement of announcementsFacade.records$ | async; trackBy: trackById"
	@hubItemFlyInOut
	[announcement]="announcement"
	(@hubItemFlyInOut.start)="showNoAnnouncements$.next(false)"
	(@hubItemFlyInOut.done)="showNoAnnouncements$.next(!announcementsFacade.records.length)"
/>

<h1 *ngIf="showNoAnnouncements$ | async" class="text-center" @fadeIn>No announcements</h1>
