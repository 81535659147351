<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<div>
		Please fill in the email that you used to register. You will be sent an email with instructions on how to reset
		your password.
	</div>

	<bp-input
		class="mt-4"
		label="Email"
		inputmode="email"
		formControlName="userName"
		nativeAutocomplete="email"
		required
	/>

	<bp-turnstile-widget #turnstileWidget class="mb-3" action="send-reset-password-link" [reset]="!!errors" />

	<button
		class="bp-round-button mt-auto"
		type="submit"
		mat-flat-button
		color="primary"
		[disabled]="(disabled$ | async) || (turnstileWidget.pending$ | async)"
	>
		Send Email

		<bp-turnstile-widget-spinner />
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="login.emit()">Login</button>
	</div>
</form>
