import { createSelector } from '@ngrx/store';

import { NewsSummary } from '@bp/frontend/domains/newsroom/models';

import { composeEntitiesListSelectors } from '@bp/admins-shared/features/entity';

import { getListState } from '../feature.selectors';

import type { IState } from './announcements.reducer';

export const ANNOUNCEMENTS_SELECTORS = composeEntitiesListSelectors<NewsSummary, IState>(getListState);

export const getAnnouncementsHubStateForCurrentUser = createSelector(getListState, s => s.stateForCurrentUser);

export const getIsOpened = createSelector(getListState, s => s.isOpened);
