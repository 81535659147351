import { exhaustMap, map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { SubscriptionPlanPaymentChallengeDialogComponent } from '../components';
import { PaymentChallengeDialogData, PaymentChallengeDialogResult } from '../models';

import { paymentChallengeDialogResult, showPaymentChallengeDialog } from './subscription-plan-payment-challenge.actions';

@Injectable()
export class SubscriptionPlanPaymentChallengeEffects {

	private readonly __actions$ = inject(Actions);

	private readonly __dialog = inject(MatDialog);

	showPaymentChallengeDialog$ = createEffect(() => this.__actions$.pipe(
		ofType(showPaymentChallengeDialog),
		exhaustMap(({ challengeHTML }) => this.__dialog
			.open<SubscriptionPlanPaymentChallengeDialogComponent, PaymentChallengeDialogData, PaymentChallengeDialogResult>(SubscriptionPlanPaymentChallengeDialogComponent, {
				disableClose: true,
				data: {
					challengeHTML,
				},
			})
			.afterClosed()),
		map(result => paymentChallengeDialogResult(result ?? { status: 'cancelled' })),
	));

}
