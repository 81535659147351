import { ToastrService } from 'ngx-toastr';
import { exhaustMap, tap } from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/frontend/models/common';

import { IdentityApiService, IdentityFacade } from '@bp/admins-shared/domains/identity';

import { changePasswordFailure, changePasswordSuccess } from './user-api.actions';
import { changePassword } from './user.actions';

@Injectable()
export class UserEffects {

	private readonly __actions$ = inject(Actions);

	private readonly __identityApiService = inject(IdentityApiService);

	private readonly __toasterService = inject(ToastrService);

	private readonly __identityFacade = inject(IdentityFacade);

	changePassword$ = createEffect(() => this.__actions$.pipe(
		ofType(changePassword),
		exhaustMap(password => this.__identityApiService
			.changePassword(password)
			.pipe(apiResult(changePasswordSuccess, changePasswordFailure))),
	));

	changePasswordSuccess$ = createEffect(
		() => this.__actions$.pipe(
			ofType(changePasswordSuccess),
			tap(({ result }) => {
				this.__identityFacade.setIdentity(result);

				this.__toasterService.success('The password has been updated');
			}),
		),
		{ dispatch: false },
	);

}
