import { isUndefined, omitBy, every, isNil } from 'lodash-es';
import { first } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { GtagEvents, IAnalyticsObserver, IGoogleTagGlobalVariables } from '@bp/frontend/features/analytics/models';
import { CrmUserAnalytics, CrmUserAnalyticsDTO } from '@bp/frontend/domains/crm/users/models';
import { RouterService } from '@bp/frontend/services/router';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { isOrganizationEvent } from '@bp/frontend/domains/crm/core';

import { trySaveCrmUserAnalytics } from './current-crm-user.actions';
import { IState } from './current-crm-user.feature';

@Injectable({ providedIn: 'root' })
export class CurrentCrmUserAnalyticsObserverService implements IAnalyticsObserver {

	private readonly __store$ = inject<Store<IState>>(Store);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __routerService = inject(RouterService);

	constructor() {
		this.__observeAndSetLastVisitedPageUrl();

		this.__observeAndSetFirstVisitedPageUrl();

		this.__observeAndSetUserSessionRecordingUrl();
	}

	private __observeAndSetLastVisitedPageUrl(): void {
		this.__routerService.sanitizedUrlAndTitleOnNavigationEnd$
			.subscribe(({ title }) => void this.__saveCrmUserAnalytics({
				lastVisitedPageTitle: title,
				lastVisitedPageUrl: location.href,
			}));
	}

	private __observeAndSetFirstVisitedPageUrl(): void {
		this.__routerService.sanitizedUrlAndTitleOnNavigationEnd$
			.pipe(first())
			.subscribe(({ title }) => void this.__saveCrmUserAnalytics({
				firstVisitedPageUrl: location.href,
				firstVisitedPageTitle: title,
			}));
	}

	private __observeAndSetUserSessionRecordingUrl(): void {
		this.__telemetry.userSessionRecordingUrl$
			?.subscribe(lastUserSessionRecordingUrl => void this.__saveCrmUserAnalytics({
				lastUserSessionRecordingUrl,
			}));
	}

	setGlobalVariables(analyticsData: IGoogleTagGlobalVariables): void {
		const analytics = new CrmUserAnalytics(omitBy<CrmUserAnalyticsDTO>({
			...analyticsData,
			firstVisitPlatform: analyticsData.platform,
			lastBackendVersion: analyticsData.backendVersion,
			lastFrontendVersion: analyticsData.frontendVersion,
			firstRefererPageUrl: analyticsData.utmTags?.refererPageUrl,
			lastRefererPageUrl: analyticsData.utmTags?.refererPageUrl,
		}, isUndefined));

		if (!every(analytics, isNil))
			this.__saveCrmUserAnalytics(analytics);
	}

	dispatchEvent(event: GtagEvents.List, _payload?: GtagEvents.EventPayloads): void {
		if (event === 'global_config_updated' || isOrganizationEvent(event))
			return;

		this.__saveCrmUserAnalytics(
			CrmUserAnalytics.buildAnalyticsEventDTO(event),
		);
	}

	private __saveCrmUserAnalytics(analytics: CrmUserAnalyticsDTO): void {
		this.__store$.dispatch(trySaveCrmUserAnalytics({
			analytics: new CrmUserAnalytics(analytics),
		}));
	}

}
