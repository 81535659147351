import { ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { ActionReducer } from '@ngrx/store';

import { momentMapper } from '@bp/shared/models/metadata';
import { featurePermissionFactory, MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { isPresent } from '@bp/shared/utilities/core';

import { Identity } from '@bp/admins-shared/domains/identity/models';

import { composeIdentityStateRehydratorMetaReducer } from '@bp/frontend-domains-identity';

import { IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY, IState } from './identity.reducer';

export function identityStateRehydratorMetaReducer(reducer: ActionReducer<any>): ActionReducer<IState> {
	return function(state, action) {
		const newState = composeIdentityStateRehydratorMetaReducer<Identity, IState>(Identity)(reducer)(state, action);

		if (action.type === ROOT_EFFECTS_INIT) {
			return {
				...newState,
				[IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY]: rehydrateFeatureAccessExpirationsMap(newState),
			};
		}

		return newState;
	};
}

function rehydrateFeatureAccessExpirationsMap(state: IState): IState['featureAccessExpirationsMap'] {
	const featureAccessExpirationsMapStateValue = <[claim: string, expiresAt: number][] | IState['featureAccessExpirationsMap'] | undefined><unknown>state[IDENTITY_FEATURE_ACCESS_EXPIRATIONS_MAP_STATE_KEY];

	if (!featureAccessExpirationsMapStateValue)
		return new Map();

	if (featureAccessExpirationsMapStateValue instanceof Map)
		return featureAccessExpirationsMapStateValue;

	return new Map(featureAccessExpirationsMapStateValue
		.map(([ claim, expiresAt ]) => {
			const permission = featurePermissionFactory(
				claim,
				permissionClaim => MerchantAdminFeature.parse(permissionClaim),
			);

			return permission
				? <const>[ permission, momentMapper(expiresAt) ]
				: null;
		})
		.filter(isPresent));
}
