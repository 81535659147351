<mat-card>
	<a routerLink="login">
		<bp-svg-icon name="logo-short-text" />
	</a>

	<h1>{{ outlet?.activatedRouteData?.['step']?.titleDisplayName }}</h1>

	<mat-card-content>
		<mat-progress-bar
			*ngIf="introFacade.pending$ | async"
			class="progress-bar-top-sticked"
			color="primary"
			mode="indeterminate"
			@slide
		/>

		<bp-sliding-step-routes [steps]="introPageSteps">
			<router-outlet #outlet="outlet" (activate)="introFacade.resetError()" />
		</bp-sliding-step-routes>
	</mat-card-content>
</mat-card>

<bp-intro-shell-page-footer />
