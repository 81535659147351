export class YoutubeHelper {

	static extractVideoIdFromUrl(url: string): string | null {
		for (const regexp of [
			/https?:\/\/(?:www\.)?youtube\.com\/.*[?&]v=([^&]+).*/u,
			/https?:\/\/(?:www\.)?youtu\.be\/(.+)/u,
		]) {
			const [ , videoId ] = url.match(regexp) ?? [];

			if (videoId)
				return videoId;
		}

		return null;
	}

	static getFullHDThumbnailUrl(videoId: string): string {
		return `https://img.youtube.com/vi/${ videoId }/maxresdefault.jpg`;
	}

	static getVideoUrl(videoId: string): string {
		return `https://www.youtube.com/watch?v=${ videoId }`;
	}

}
