<div class="text-center">
	<p>You have successfully accepted invite.</p>
	<p>Now you can login.</p>
</div>

<button
	mat-flat-button
	class="bp-round-button mt-auto w-100"
	type="submit"
	color="primary"
	(click)="_introFacade.gotoLoginPage()"
>
	Login
</button>
