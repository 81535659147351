import { IPageQueryParamsDTO } from '@bp/shared/models/common';
import { DTO, MetadataQueryParamsBase, mixinMetadataPageQueryParams } from '@bp/shared/models/metadata';

type AccessibleOrganizationsQueryParamsDTO = DTO<AccessibleOrganizationsQueryParams> & IPageQueryParamsDTO;

export class AccessibleOrganizationsQueryParams extends mixinMetadataPageQueryParams(
	MetadataQueryParamsBase,
) {

	static {
		this._initClassMetadata();
	}

	constructor(dto?: AccessibleOrganizationsQueryParamsDTO) {
		super(dto);
	}

}
