import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

export type NewsKindLiterals = GetEnumerationLiterals<typeof NewsKind>;

export class NewsKind extends Enumeration {

	static pressRelease = new NewsKind();

	static update = new NewsKind();

	static blogPost = new NewsKind();

	static feature = new NewsKind();

	static caseStudy = new NewsKind();

}
