<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<button type="submit" class="hidden"></button>

	<bp-alert type="danger" [errors]="errors" />

	<fieldset>
		<bp-property-metadata-controls-section
			heading="Portal"
			[form]="form"
			[metadata]="metadata"
			[sectionScheme]="sectionScheme"
		/>
	</fieldset>
</form>
