import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IIdentity } from '@bp/shared/domains/jwt-session';

import { IdentityFacade } from '../state';

@Injectable({
	providedIn: 'root',
})
export class IdentityLoggedOutGuard {
	constructor(private readonly _router: Router, private readonly _identityFacade: IdentityFacade<IIdentity, any>) {}

	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		if (this._identityFacade.user) {
			void this._router.navigate([ '/' ]);

			return false;
		}

		return true;
	}
}
