import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterModule } from '@angular/router';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';
import { SharedFeaturesMatMenuTriggerObserverModule } from '@bp/frontend/features/mat-menu-trigger-observer';
import { PaymentCardBrandsSvgIconsDefinitionsComponent } from '@bp/frontend/components/payment-card/brands-svg-icons';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';
import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';
import { AdminsSharedFeaturesLayoutModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubModule } from '@bp/admins-shared/features/notifications-hub';
import { AdminsFooterComponent } from '@bp/admins-shared/components/admins-footer';

import { SharedDomainsIdentityModule } from '@bp/frontend-domains-identity';
import { MerchantAdminFrontendDomainsIdentityModule } from '@bp/merchant-admin/frontend/domains/identity';
import { MerchantAdminFrontendFeaturesAnnouncementsModule } from '@bp/merchant-admin/frontend/features/announcements';
import { MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionModule } from '@bp/merchant-admin/frontend/domains/current-organization-subscription';
import {
	MerchantAdminFrontendFeaturesRequestSupportModule
} from '@bp/merchant-admin/frontend/features/request-support';

import { MenuComponent, LoginToOrganizationComponent } from './components';
import { SubscriptionFeeIsOverdueModalPageComponent, SubscriptionSuspendedPageComponent } from './pages';

const COMPONENTS = [
	MenuComponent,
	SubscriptionSuspendedPageComponent,
	SubscriptionFeeIsOverdueModalPageComponent,
	LoginToOrganizationComponent,
];

const MODULES = [
	CommonModule,
	RouterModule,
	MatIconModule,
	MatProgressBarModule,
	MatButtonModule,
	MatMenuModule,
	MatDialogModule,

	SharedComponentsCoreModule,
	SharedPipesModule,
	SharedFeaturesMomentPipesModule,
	SharedFeaturesModalModule,
	SharedDirectivesCoreModule,
	SharedFeaturesSvgIconsModule,
	SharedFeaturesTooltipModule,
	SharedDomainsIdentityModule,
	SharedComponentsControlsModule,
	SharedFeaturesMatMenuTriggerObserverModule,

	AdminsFooterComponent,
	PaymentCardBrandsSvgIconsDefinitionsComponent,

	AdminsSharedDomainsIdentityModule,
	AdminsSharedCoreComponentsModule,
	AdminsSharedFeaturesLayoutModule,
	AdminsSharedFeaturesNotificationsHubModule,

	MerchantAdminFrontendFeaturesAnnouncementsModule,
	MerchantAdminFrontendFeaturesRequestSupportModule,
	MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionModule,
	MerchantAdminFrontendDomainsIdentityModule,
];

@NgModule({
	imports: MODULES,
	declarations: COMPONENTS,
	exports: [ MODULES, COMPONENTS ],
})
export class CoreModule { }
