import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { LayoutFacade } from '@bp/admins-shared/features/layout';

import { NotificationsHubService } from '../services';

import {
	close, markAllItemsAsRead, open, removeAllItems, removeItem, setItemReadState, updateItems
} from './notifications-hub.actions';

@Injectable()
export class NotificationsHubEffects {

	listenForNotificationsOnPanelOpen$ = createEffect(
		() => this._notificationsHubService
			.list$
			.pipe(map(notifications => updateItems({ notifications }))),
	);

	closePanelOnLayoutCloseFloatOutlets$ = createEffect(
		() => this._layoutFacade.closeFloatOutlets$.pipe(
			map(close),
		),
	);

	onPanelOpen$ = createEffect(
		() => this._actions$.pipe(
			ofType(open),
			tap(() => {
				this._toaster.clear();

				this._layoutFacade.showFullpageBackdrop();
			}),
		),
		{ dispatch: false },
	);

	onPanelClose$ = createEffect(
		() => this._actions$.pipe(
			ofType(close),
			tap(() => void this._layoutFacade.hideFullpageBackdrop()),
		),
		{ dispatch: false },
	);

	setNotificationReadState$ = createEffect(
		() => this._actions$.pipe(
			ofType(setItemReadState),
			tap(({ notification, isRead }) => isRead
				? void this._notificationsHubService.markAsRead(notification)
				: void this._notificationsHubService.markAsUnRead(notification)),
		),
		{ dispatch: false },
	);

	markAllAsRead$ = createEffect(
		() => this._actions$.pipe(
			ofType(markAllItemsAsRead),
			tap(() => void this._notificationsHubService.markAllAsRead()),
		),
		{ dispatch: false },
	);

	delete$ = createEffect(
		() => this._actions$.pipe(
			ofType(removeItem),
			tap(({ notification }) => void this._notificationsHubService.remove(notification)),
		),
		{ dispatch: false },
	);

	deleteAll$ = createEffect(
		() => this._actions$.pipe(
			ofType(removeAllItems),
			tap(() => void this._notificationsHubService.removeAll()),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _actions$: Actions,
		private readonly _notificationsHubService: NotificationsHubService,
		private readonly _layoutFacade: LayoutFacade,
		private readonly _toaster: ToastrService,
	) {
	}

}
