import { GtagEvents } from '@bp/frontend/features/analytics/models';

const organizationFullEvents = new Set<GtagEvents.List>([
	'psp_mid_added',
	'psp_3ds_mid_added',
	'add_payment_info',
	'purchase',
	'purchase_failed',
	'begin_checkout',
	'wizard_psp_submitted',
]);

export function isOrganizationEvent(event: GtagEvents.List): boolean {
	return organizationFullEvents.has(event)
		|| event.endsWith('_plan_purchase')
		|| event.endsWith('_plan_purchase_failed');
}
