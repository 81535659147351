import { Sensitive } from '@bp/shared/models/core';
import { Control, DTO, FieldControlType, MetadataEntity, Required } from '@bp/shared/models/metadata';

export class CreateAccountApiRequest extends MetadataEntity {
	@Required()
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'given-name',
		},
	)
	firstName!: string;

	@Required()
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'family-name',
		},
	)
	lastName!: string;

	@Required()

	[Sensitive.password]!: string;

	@Required()

	[Sensitive.confirmPassword]!: string;

	get fullName(): string {
		return [ this.firstName, this.lastName ].join(' ');
	}

	constructor(dto?: DTO<CreateAccountApiRequest>) {
		super(dto);
	}

}
