import { createReducer, createFeature } from '@ngrx/store';

import { Organization } from '@bp/shared/domains/organizations';

import {
	composeEntitiesInMemoryPagedListReducer,
	ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE,
	EntitiesInMemoryPagedListState
} from '@bp/admins-shared/features/entity';

import { ORGANIZATIONS_LIST_ACTIONS } from './accessible-organizations.actions';

export interface IState extends EntitiesInMemoryPagedListState<Organization> {}

export const initialState: IState = {
	...ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE,
};

export const accessibleOrganizationsFeature = createFeature({
	name: 'accessibleOrganizations',
	reducer: createReducer(
		initialState,
		...composeEntitiesInMemoryPagedListReducer(initialState, ORGANIZATIONS_LIST_ACTIONS),
	),
});
