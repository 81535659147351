import type { MetaDefinition } from '@angular/platform-browser';

import { MetadataEntity } from '@bp/shared/models/metadata';

export abstract class Metatags extends MetadataEntity {

	readonly abstract metatagsSchema: string;

	abstract getMetaDefinitions(): MetaDefinition[];

}
