import { Moment } from 'moment';

import { DTO, Mapper, MetadataEntity, momentMapper } from '@bp/shared/models/metadata';

export class GenerateOtpApiResponse extends MetadataEntity {

	@Mapper(momentMapper)
	otpExpiresAt!: Moment;

	constructor(dto: DTO<GenerateOtpApiResponse>) {
		super(dto);
	}

}
