<button class="btn-modal-icon-close" type="button" mat-icon-button [mat-dialog-close]="null">
	<bp-svg-icon name="close" />
</button>

<h1 mat-dialog-title>3D Secure Challenge</h1>

<mat-dialog-content class="grid-stacked-children">
	<bp-spinning-logo *ngIf="!_isChallengeLoaded" @fade />

	<iframe
		frameborder="0"
		width="100%"
		height="100%"
		[srcdoc]="_challengeHTML"
		(load)="_isChallengeLoaded = true"
	></iframe>
</mat-dialog-content>
