import { Moment } from 'moment';

import { DTO, MapFromDTO, Mapper, MetadataEntity, momentMapper } from '@bp/shared/models/metadata';
import { FeaturePermission } from '@bp/shared/domains/permissions';

export class VerifyOtpApiResponse extends MetadataEntity {

	@MapFromDTO()
	permission!: FeaturePermission<any>;

	@Mapper(momentMapper)
	expiresAt!: Moment;

	constructor(dto: DTO<VerifyOtpApiResponse>) {
		super(dto);
	}

}
