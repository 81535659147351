import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IntroFacade } from '../../../state';

@Component({
	selector: 'bp-reset-password-link-sent-page',
	templateUrl: './reset-password-link-sent-page.component.html',
	styleUrls: [ './reset-password-link-sent-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordLinkSentPageComponent {

	constructor(
		public introFacade: IntroFacade,
	) { }
}
