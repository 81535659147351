import { createAction, props } from '@ngrx/store';

import { PaymentChallengeDialogResult } from '../models';

const ACTIONS_SCOPE = '[Subscription Plan Payment Challenge]';

export const showPaymentChallengeDialog = createAction(
	`${ ACTIONS_SCOPE } Show Payment Challenge Dialog`,
	props<{ challengeHTML: string }>(),
);

export const paymentChallengeDialogResult = createAction(
	`${ ACTIONS_SCOPE } Payment Challenge Dialog Result`,
	props<PaymentChallengeDialogResult>(),
);
