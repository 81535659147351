import { createFeature, createReducer, on } from '@ngrx/store';

import { CrmUser, CrmUserAnalytics } from '@bp/frontend/domains/crm/users/models';
import { BpError } from '@bp/frontend/models/core';

import { trySaveCrmUserKeptInStore, updateCrmUserInStore, trySaveCrmUserAnalytics, resetState, observeCrmUserRemoteChanges } from './current-crm-user.actions';
import { saveCrmUserKeptInStoreFailure, saveCrmUserKeptInStoreSuccess } from './current-crm-user-api.actions';

export interface IState {

	user: CrmUser | null;

	analytics: CrmUserAnalytics | null;

	observedUserId: string | null;

	pending: boolean;

	error: BpError | null;

}

export const initialState: IState = {
	user: null,
	analytics: null,
	observedUserId: null,
	pending: false,
	error: null,
};

export const currentCrmUserFeature = createFeature({
	name: '[domain]:current-crm-user',
	reducer: createReducer(
		initialState,

		on(resetState, (): IState => initialState),

		on(updateCrmUserInStore, (state, { user }): IState => ({
			...state,
			user,
		})),

		on(trySaveCrmUserKeptInStore, (state): IState => ({
			...state,
			pending: !!state.user?.id,
			error: null,
		})),

		on(
			saveCrmUserKeptInStoreSuccess,
			saveCrmUserKeptInStoreFailure,
			(state): IState => ({
				...state,
				pending: false,
			}),
		),

		on(
			saveCrmUserKeptInStoreFailure,
			(state, { error }): IState => ({
				...state,
				error,
			}),
		),

		on(
			observeCrmUserRemoteChanges,
			(state, { userId }): IState => ({
				...state,
				observedUserId: userId,
			}),
		),

		on(
			trySaveCrmUserAnalytics,
			(state, { analytics }): IState => ({
				...state,
				analytics: new CrmUserAnalytics({
					...state.analytics,
					...analytics,
				}),
			}),
		),
	),
});
