import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { Organization } from '@bp/shared/domains/organizations';

import { takePresent } from '@bp/frontend/rxjs';

import { confirmConnectToOrganization, dismissConfirmConnectToOrganization } from './accessible-organizations.actions';
import { BaseAccessibleOrganizationsFacade } from './base-accessible-organizations.facade';

@Injectable({
	providedIn: 'root',
})
export class AccessibleOrganizationsFacade {

	private readonly __store$ = inject(Store);

	private readonly __actions$ = inject(Actions);

	private readonly __baseAccessibleOrganizationsFacade = inject(BaseAccessibleOrganizationsFacade);

	all$ = this.__baseAccessibleOrganizationsFacade.all$.pipe(takePresent);

	onDismissConfirmConnectToOrganization$ = this.__actions$
		.pipe(ofType(dismissConfirmConnectToOrganization));

	loadAll(): void {
		this.__baseAccessibleOrganizationsFacade.loadAll();
	}

	resetState(): void {
		this.__baseAccessibleOrganizationsFacade.resetState();
	}

	confirmLoginToOrganization(organization: Organization): void {
		this.__store$.dispatch(confirmConnectToOrganization({ entity: organization }));
	}

}
