<div *ngIf="_canShare; else clipboardCopyFallbackTpl" bpTooltip="Share" (click)="_share()">
	<ng-container *ngTemplateOutlet="buttonTpl" />
</div>

<ng-template #clipboardCopyFallbackTpl>
	<bp-copy-to-clipboard copyText="Copy URL to clipboard" [value]="url">
		<ng-container *ngTemplateOutlet="buttonTpl" />
	</bp-copy-to-clipboard>
</ng-template>

<ng-template #buttonTpl>
	<button
		*ngIf="_isCompactMode; else fullModeButtonTpl"
		mat-icon-button
		type="button"
		class="bp-icon-button-{{ iconSize }}"
		[color]="color"
	>
		<mat-icon class="material-icons-round">share</mat-icon>
	</button>

	<ng-template #fullModeButtonTpl>
		<button mat-button type="button">
			<mat-icon class="material-icons-round bp-icon-{{ iconSize }}">share</mat-icon>
			<span>Share</span>
		</button>
	</ng-template>
</ng-template>
