import { Component, ChangeDetectionStrategy } from '@angular/core';

import type { DTO } from '@bp/shared/models/metadata';

import type { ControlsSectionScheme } from '@bp/frontend/components/metadata';
import { FormMetadataEntityBaseComponent } from '@bp/frontend/components/metadata';

import { IdentityPreferences } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-identity-preferences-form',
	templateUrl: './identity-preferences-form.component.html',
	styleUrls: [ './identity-preferences-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityPreferencesFormComponent extends FormMetadataEntityBaseComponent<IdentityPreferences> {

	override metadata = IdentityPreferences.getClassMetadata();

	sectionScheme: ControlsSectionScheme<IdentityPreferences> = [
		[ 'timezone' ],
	];

	constructor() {
		super();

		this.setFormScheme({
			timezone: null,
		});
	}

	override factory = (dto?: DTO<IdentityPreferences>): IdentityPreferences => new IdentityPreferences(dto);

}
