import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminsSharedPagesIntroModule } from './admins-shared-pages-intro.module';
import { IntroEffects } from './state/intro.effects';
import { FEATURE_KEY, reducer } from './state/intro.reducer';

@NgModule({
	imports: [
		AdminsSharedPagesIntroModule,
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ IntroEffects ]),
	],
})
export class AdminsSharedPagesIntroRootModule { }
