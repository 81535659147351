import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SpinningLogoComponent } from '@bp/frontend/components/spinning-logo';

import { subscriptionPlanPaymentChallengeFeature } from './state/subscription-plan-payment-challenge.feature';
import { SubscriptionPlanPaymentChallengeEffects } from './state/subscription-plan-payment-challenge.effects';
import { SubscriptionPlanPaymentChallengeDialogComponent } from './components';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,

		SpinningLogoComponent,

		SharedComponentsCoreModule,
		SharedFeaturesSvgIconsModule,

		StoreModule.forFeature(subscriptionPlanPaymentChallengeFeature),
		EffectsModule.forFeature([ SubscriptionPlanPaymentChallengeEffects ]),

	],
	declarations: [
		SubscriptionPlanPaymentChallengeDialogComponent,
	],
})
export class SharedDomainsSubscriptionPlansPaymentChallengeModule {

}
