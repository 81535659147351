import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GoogleOauthButtonComponent } from './components';

const EXPOSED = [
	GoogleOauthButtonComponent,
];

@NgModule({
	imports: [ CommonModule ],
	declarations: [ EXPOSED ],
	exports: [ EXPOSED ],
})
export class SharedFeaturesOauthModule {}
