import { Injectable } from '@angular/core';

import type { MerchantPsp } from '@bp/admins-shared/core/models';
import { EntitiesInMemoryPagedListEffects } from '@bp/admins-shared/features/entity';

import { MerchantPspsQueryParams } from '../models';
import { MerchantPspsApiService } from '../services';

import { MerchantPspsFacade } from './merchant-psps.facade';
import { IState } from './merchant-psps.reducer';

@Injectable()
export class MerchantPspsEffects extends EntitiesInMemoryPagedListEffects<
	MerchantPsp,
	IState,
	MerchantPspsQueryParams,
	MerchantPspsFacade
> {
	readonly routeComponentType = null;

	constructor(merchantPspsFacade: MerchantPspsFacade, merchantPspsApiService: MerchantPspsApiService) {
		super(merchantPspsApiService, merchantPspsFacade);

		this._setDefaultSortParams({
			sortField: 'status',
			sortDir: 'asc',
		});
	}

	protected _loadQueryParamsFactory = (dto?: Partial<MerchantPspsQueryParams>): MerchantPspsQueryParams => new MerchantPspsQueryParams(dto);

	protected readonly _filterRecordsInMemoryOnQueryParamsChange = (
		records: MerchantPsp[],
		{ status, pspName, type }: MerchantPspsQueryParams,
	): MerchantPsp[] => {
		if (status)
			records = records.filter(v => v.status === status);

		if (pspName) {
			const lowerCasedPspName = pspName.toLowerCase();

			records = records.filter(v => v.pspName.toLowerCase().includes(lowerCasedPspName));
		}

		if (type)
			records = records.filter(v => v.type === type);

		return records;
	};
}
