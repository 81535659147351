import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { SubscriptionPlansQueryParams } from '@bp/shared/domains/subscription-plans/core';

import { apiResult } from '@bp/frontend/models/common';

import { SubscriptionPlansApiService } from '../services';

import { loadAllFailure, loadAllSuccess } from './subscription-plans-shared-api.actions';
import { loadAll } from './subscription-plans-shared.actions';

@Injectable()
export class SubscriptionPlansEffects {

	onLoad$ = createEffect(() => this._actions$.pipe(
		ofType(loadAll),
		switchMap(() => this._subscriptionPlansApiService
			.getRecordsPage(new SubscriptionPlansQueryParams({ limit: 99_999 }))
			.pipe(apiResult(loadAllSuccess, loadAllFailure))),
	));

	constructor(
		private readonly _actions$: Actions,
		private readonly _subscriptionPlansApiService: SubscriptionPlansApiService,
	) {
	}

}
