import { get, isArray, isString } from 'lodash-es';

import type { MetaDefinition } from '@angular/platform-browser';

import type { DTO } from '@bp/shared/models/metadata';
import { Default, MapFromDTO, Mapper } from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { isEmpty } from '@bp/shared/utilities/core';

import { ArticleMetatags } from './article-metatags';
import { Metatags } from './metatags';
import { TwitterMetatags } from './twitter-metatags';
import { VideoMetatags } from './video-metatags';

export type RouteMetatagsKeys = NonFunctionPropertyNames<RouteMetatags>;

export class RouteMetatags extends Metatags {

	@MapFromDTO()
	title!: string;

	/**
	 * https://developers.google.com/search/docs/advanced/crawling/block-indexing#meta-tag
	 * */
	@MapFromDTO()
	robots?: ('noarchive' | 'nofollow' | 'noindex' | 'none' | 'nosnippet' | 'notranslate')[] | null;

	@MapFromDTO()
	description!: string;

	@MapFromDTO()
	image!: string | null;

	/**
	 * The canonical URL of your object that will be used as its permanent ID in the graph, e.g.,
	 * "https://www.imdb.com/title/tt0117500/".
	 */
	@MapFromDTO()
	url?: string | null;

	/**
	 * The type of your object, e.g., "video.movie"
	 * https://ogp.me/#types
	 */
	@Default('website')
	type?: 'article' | 'video.other' | 'website';

	@MapFromDTO()
	site_name!: string; // eslint-disable-line @typescript-eslint/naming-convention

	@Mapper(ArticleMetatags)
	article?: ArticleMetatags;

	@Mapper(VideoMetatags)
	video?: VideoMetatags;

	/**
	 * 	https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/summary
	 */
	@Mapper(TwitterMetatags)
	@Default(() => new TwitterMetatags())
	twitter?: TwitterMetatags;

	metatagsSchema = 'og';

	constructor(dto?: DTO<RouteMetatags>) {
		super(dto);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
	getSeoMetatagsNames() {
		return <const>[ 'title', 'description', 'robots' ];
	}

	getMetatagSchemas(): string[] {
		return [
			this.metatagsSchema,
			...this.classMetadata.keys
				.map(property => get(this, property))
				.filter((value): value is Metatags => value instanceof Metatags)
				.map(value => value.metatagsSchema),
		];
	}

	getMetaDefinitions(): MetaDefinition[] {
		return [
			...this.classMetadata.keys
				.flatMap(property => this._getPropertyMetaDefinitions(<RouteMetatagsKeys> property)),
			...this._getSeoMetaDefinitions(),
		];
	}

	private _getSeoMetaDefinitions(): MetaDefinition[] {
		return this.getSeoMetatagsNames()
			.filter(metatagName => !!this[metatagName])
			.map(metatagName => {
				const metatagContent = this[metatagName]!;

				return {
					name: metatagName,
					content: isArray(metatagContent) ? metatagContent.join(',') : metatagContent,
				};
			});
	}

	private _getPropertyMetaDefinitions(property: RouteMetatagsKeys): MetaDefinition[] {
		const value = this[property];

		if (isEmpty(value))
			return [];

		if (value instanceof Metatags)
			return value.getMetaDefinitions();

		if (isString(value)) {
			return [
				{
					property: `${ this.metatagsSchema }:${ property }`,
					content: value,
				},
			];
		}

		return [];
	}
}
