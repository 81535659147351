
import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, Hint, Label, MapFromDTO, Mapper, Table, View, Sortable
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';

import { RouteMetatags } from '@bp/frontend/features/metatags/models';
import { Article } from '@bp/frontend/domains/articles';

import { NewsKind } from './news-kind';
import { NewsroomSectionKind } from './newsroom-section-kind.enum';

export type NewsSummaryKeys = NonFunctionPropertyNames<NewsSummary>;

export type NewsSummaryDTO = DTO<NewsSummary>;

export class NewsSummary extends Article {

	@Hint('The youtube cover image will be used as the cover if youtube video URL provided')
	override youtubeVideoId!: string | null;

	@MapFromDTO()
	@View(FieldViewType.thumbnail)
	externalArticleResourceLogoImgUrl!: string | null;

	@MapFromDTO()
	@View(FieldViewType.link)
	externalArticleResourceUrl!: string | null;

	@Mapper(NewsKind)
	@Control(FieldControlType.select, {
		list: NewsKind.getList(),
		required: true,
	})
	@Table()
	@Sortable()
	kind!: NewsKind;

	@Default(false)
	@Label('This Is Announcement')
	@Hint('The news will appear on the merchant admin announcements')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	// TODO: rename to showOnMerchantAdminAnnouncement
	showOnMerchantAdminWhatsNewHub!: boolean;

	@Default(false)
	@Label('Do not publish to bridgerpay.com')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	skipPublishingToPromoWebsite!: boolean;

	@Default(false)
	@Hint('The merchant admin user is gonna be explicitly notified')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	showNotification!: boolean;

	metatags = new RouteMetatags({
		title: this.title,
		description: this.summary ?? undefined,
		type: 'article',
		image: this.coverImgUrl,
		url: `/${ NewsroomSectionKind.getByNewsKind(this.kind).routePath }/${ this.id }`,
		article: {
			publishedTime: this.publicationDate,
			modifiedTime: this.updatedAt,
		},
	});

	constructor(dto?: NewsSummaryDTO) {
		super(dto);

		this.name = this.title;
	}

}
