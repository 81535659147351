import {
	distinctUntilChanged, exhaustMap, filter, first, map, skip, switchMap, tap, withLatestFrom
} from 'rxjs/operators';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { inject } from '@angular/core';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { IIdentity, PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN } from '@bp/shared/domains/jwt-session';
import { isEqual } from '@bp/shared/utilities/core';

import { LogoutConfirmDialogComponent } from '@bp/frontend/components/dialogs';
import { apiResult } from '@bp/frontend/models/common';
import { RouterService } from '@bp/frontend/services/router';

import { IIdentityApiService } from '../services/identity-api.service.interface';

import { IdentityActions } from './identity.actions';
import { IdentityFacade } from './identity.facade';
import { IdentityState } from './compose-identity-reducer';

export abstract class IdentityEffects<
	TIdentity extends IIdentity,
	TState extends IdentityState<TIdentity>,
	TLoginPayload = undefined>
implements OnInitEffects {

	protected abstract readonly _identityApiService: IIdentityApiService<TIdentity, TLoginPayload>;

	protected readonly _actions$ = inject(Actions);

	protected readonly _dialog = inject(MatDialog);

	protected readonly _router = inject(Router);

	protected readonly _routerService = inject(RouterService);

	get actions(): IdentityActions<TIdentity, TLoginPayload> {
		return this._identityFacade.actions;
	}

	login$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.login),
		exhaustMap(({ payload }) => this._identityApiService
			.login(payload)
			.pipe(apiResult(this.actions.api.loginSuccess, this.actions.api.loginFailure))),
	));

	onLoginSuccessSetIdentity$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loginSuccess),
			tap(({ result }) => void this._identityFacade.setIdentity(result)),
		),
		{ dispatch: false },
	);

	onLogoutConfirmIdentityLogout$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.confirmLogout),
		exhaustMap(() => this._dialog
			.open<LogoutConfirmDialogComponent, undefined, boolean>(LogoutConfirmDialogComponent)
			.afterClosed()),
		map(result => (result ? this.actions.logout() : this.actions.dismissLogoutConfirmation())),
	));

	whenIdentityAuthorizedNavigateToApp$ = createEffect(() => this._identityFacade.userHasLoggedIn$.pipe(
		switchMap(() => this._identityFacade.userPresent$.pipe(first())),
		withLatestFrom(this._identityFacade.urlForRedirectionAfterLogin$),
		map(([ , urlForRedirectionAfterLogin ]) => this.actions.navigateToApp({ urlForRedirectionAfterLogin })),
	));

	navigateToApp$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.navigateToApp),
			tap(
				({ urlForRedirectionAfterLogin }) => void this._router.navigateByUrl(
					urlForRedirectionAfterLogin ?? '/',
					{
						state: PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN,
					},
				),
			),
		),
		{ dispatch: false },
	);

	onPermissionsChangeRunRouteGuards$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.effectsInit),
			switchMap(() => this._identityFacade.userPresent$),
			map(user => user.featurePermissions),
			distinctUntilChanged(isEqual),
			skip(1), // skip the first initial change
			filter(() => !this._routerService.isNavigationInProcess),
			tap(() => void this._router.navigateByUrl(this._router.url)),
		),
		{ dispatch: false },
	);

	constructor(protected readonly _identityFacade: IdentityFacade<TIdentity, TState, TLoginPayload>) { }

	ngrxOnInitEffects(): Action {
		return this.actions.effectsInit();
	}

}
