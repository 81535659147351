import { Environment } from '@bp/shared/models/core';
import { Default, MapFromDTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { FirebaseDocument } from './firebase-document';
import { FirebaseDocumentWriter } from './firebase-document-writer';

export abstract class WriteEnvironmentFirebaseDocumentApiRequest extends MetadataEntity {

	@Mapper(Environment)
	environment!: Environment;

	@Mapper(FirebaseDocumentWriter)
	writer!: FirebaseDocumentWriter;

	@Default(null)
	previousWriteRequestId?: string | null;

	@MapFromDTO()
	writeRequestId?: string;

	abstract data: Partial<FirebaseDocument> | Partial<FirebaseDocument>[];

}
