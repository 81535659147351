import { ILoginQueryParams } from '@bp/shared/domains/jwt-session';

import { TelemetryService } from '@bp/frontend/services/telemetry';

import { Identity } from '@bp/admins-shared/domains/identity/models';

export function tryCreateIdentityBasedOnLoginQueryParams(): Identity | null {
	const identityQueryParams = <Partial<ILoginQueryParams>>(
		Object.fromEntries(new URL(window.location.toString()).searchParams.entries())
	);

	if (identityQueryParams.jwt) {
		try {
			return new Identity(identityQueryParams.jwt, identityQueryParams.refreshToken);
		} catch (error: unknown) {
			TelemetryService.captureError(error, identityQueryParams.jwt);

			return null;
		}
	}

	return null;
}
