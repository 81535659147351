<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<!-- to kick in the browsers save password feature upon login -->
	<input type="text" name="username" hidden [value]="email" />

	<content class="flex-column mt-5">
		<bp-password-input
			required
			label="Current Password"
			nativeAutocomplete="current-password"
			formControlName="currentPassword"
		/>

		<bp-password-input
			required
			class="mt-3"
			label="New Password"
			nativeAutocomplete="new-password"
			formControlName="newPassword"
		/>

		<bp-password-input
			required
			label="Confirm New Password"
			nativeAutocomplete="new-password"
			formControlName="confirmNewPassword"
		/>

		<bp-turnstile-widget #turnstileWidget action="change-password" [reset]="!!errors" />
	</content>

	<footer class="text-center mt-3 mb-4">
		<button
			type="submit"
			mat-flat-button
			color="primary"
			class="bp-round-button bp-button-lg long-button"
			[disabled]="(canNotSave$ | async) || (turnstileWidget.pending$ | async)"
		>
			<bp-turnstile-widget-spinner />

			Save
		</button>
	</footer>
</form>
