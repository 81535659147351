import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SlidingStepRoutesComponent } from './sliding-step-routes.component';

const EXPOSED = [
	SlidingStepRoutesComponent,
];

@NgModule({
	imports: [
		CommonModule,

	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesSlidingStepRoutesModule { }
