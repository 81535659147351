
import type { DTO } from '@bp/shared/models/metadata';
import { Default, FirebaseEntity, MapFromDTO } from '@bp/shared/models/metadata';

export type AnnouncementsHubStatePerUserDTO = DTO<AnnouncementsHubStatePerUser>;

export class AnnouncementsHubStatePerUser extends FirebaseEntity {

	@MapFromDTO()
	showNotificationDot!: boolean;

	@Default([])
	seenNewsIds!: string[];

	constructor(dto?: AnnouncementsHubStatePerUserDTO) {
		super(dto);
	}

}
