import { RequiredDTO, Mapper } from '@bp/shared/models/metadata';

import { WriteEnvironmentFirebaseDocumentApiRequest } from '@bp/frontend/features/firebase/models';

import { CrmOrganization } from './crm-organization';

export class WriteCrmOrganizationApiRequest extends WriteEnvironmentFirebaseDocumentApiRequest {

	static {
		this._initClassMetadata();
	}

	@Mapper(CrmOrganization)
	data!: CrmOrganization;

	constructor(dto: RequiredDTO<WriteCrmOrganizationApiRequest>) {
		super(dto);
	}

}
