import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PermissionBasedNavigationService } from '@bp/frontend-domains-identity';

@Injectable({
	providedIn: 'root',
})
export class NavigationToRootRedirectionGuard {

	private readonly __permissionBasedDefaultNavigationService = inject(PermissionBasedNavigationService);

	canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		this.__permissionBasedDefaultNavigationService.tryNavigate();

		return false;
	}
}
