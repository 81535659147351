import { exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';

import { apiResult, apiNullableResult, apiVoidResult, apiVoidResultWithRequest } from '@bp/frontend/models/common';
import { GoogleTagService } from '@bp/frontend/features/analytics';
import { SubscriptionPlanPurchase } from '@bp/frontend/domains/subscription-plans/checkout';
import { GtagEvents } from '@bp/frontend/features/analytics/models';
import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { SubscriptionPlanPaymentChallengeFacade } from '@bp/frontend/domains/subscription-plans/payment-challenge';
import { BpError } from '@bp/frontend/models/core';

import { SubscriptionPlansSharedFacade } from '@bp/admins-shared/domains/subscription-plans';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { CurrentOrganizationFacade } from '@bp/merchant-admin/frontend/domains/current-organization';

import { CurrentOrganizationSubscriptionApiService } from '../services';

import {
	purchaseSubscriptionPlanFailure, purchaseSubscriptionPlanSuccess, downgradeSubscriptionPlanFailure, downgradeSubscriptionPlanSuccess, loadFailure, loadSuccess, loadBillingPortalDetailsSuccess, loadBillingPortalDetailsFailure
} from './current-organization-subscription-api.actions';
import {
	purchaseSubscriptionPlan, downgradeSubscriptionPlan, refresh, openBillingPortal, loadBillingPortalDetails, setMustPurchaseSubscriptionPlan
} from './current-organization-subscription.actions';
import { CurrentOrganizationSubscriptionFacade } from './current-organization-subscription.facade';

@Injectable()
export class CurrentOrganizationSubscriptionEffects {
	private readonly __currentOrganizationFacade = inject(CurrentOrganizationFacade);

	private readonly __currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	private readonly __subscriptionPlansSharedFacade = inject(SubscriptionPlansSharedFacade);

	private readonly __currentOrganizationSubscriptionApiService = inject(
		CurrentOrganizationSubscriptionApiService,
	);

	private readonly __currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	private readonly __subscriptionPlanPaymentChallengeFacade = inject(
		SubscriptionPlanPaymentChallengeFacade,
	);

	private readonly __identityFacade = inject(IdentityFacade);

	private readonly __actions$ = inject(Actions);

	private readonly __googleTagService = inject(GoogleTagService);

	load$ = createEffect(() => combineLatest([
		this.__currentOrganizationFacade.entity$,
		this.__subscriptionPlansSharedFacade.all$,
	]).pipe(
		filter(([ currentOrganization, subscriptionPlans ]) => !!currentOrganization && !!subscriptionPlans),
		map(
			([ currentOrganization, subscriptionPlans ]) => currentOrganization!.currentSubscription
				? new CurrentOrganizationSubscription({
					...currentOrganization!.currentSubscription,
					subscriptionPlan: subscriptionPlans!.find(
						subscriptionPlan => subscriptionPlan.id === currentOrganization!.currentSubscription?.subscriptionPlanId,
					),
				})
				: null,
		),
		apiNullableResult(loadSuccess, loadFailure),
	));

	refresh$ = createEffect(
		() => this.__actions$.pipe(
			ofType(refresh),
			tap(() => void this.__currentOrganizationFacade.refresh()),
		),
		{ dispatch: false },
	);

	purchaseSubscriptionPlan$ = createEffect(() => this.__actions$.pipe(
		ofType(purchaseSubscriptionPlan),
		tap(
			({ subscriptionPlanPurchase }) => void this.__currentCrmOrganizationFacade.updateAndSaveOrganizationKeptInStore({
				billingDetails: subscriptionPlanPurchase,
			}),
		),
		exhaustMap(({ subscriptionPlanPurchase }) => this.__currentOrganizationSubscriptionApiService
			.purchaseSubscriptionPlan(subscriptionPlanPurchase)
			.pipe(
				switchMap(({ challengeHTML }) => challengeHTML ? this.__showChallengeDialogAndAwaitResult(challengeHTML) : of(void 0)),
				apiVoidResultWithRequest({
					request: subscriptionPlanPurchase,
					successAction: purchaseSubscriptionPlanSuccess,
					failureAction: purchaseSubscriptionPlanFailure,
				}),
			)),
	));

	downgradeSubscription$ = createEffect(() => this.__actions$.pipe(
		ofType(downgradeSubscriptionPlan),
		exhaustMap(({ downgradeSubscriptionPlanApiRequest }) => this.__currentOrganizationSubscriptionApiService
			.downgradeSubscription(downgradeSubscriptionPlanApiRequest)
			.pipe(apiVoidResult(downgradeSubscriptionPlanSuccess, downgradeSubscriptionPlanFailure))),
	));

	openBillingPortal$ = createEffect(() => this.__actions$.pipe(
		ofType(openBillingPortal),
		map(() => loadBillingPortalDetails()),
	));

	loadBillingPortalDetails$ = createEffect(() => this.__actions$.pipe(
		ofType(loadBillingPortalDetails),
		exhaustMap(() => this.__currentOrganizationSubscriptionApiService
			.loadBillingPortalDetails()
			.pipe(apiResult(loadBillingPortalDetailsSuccess, loadBillingPortalDetailsFailure))),
	));

	onLoadBillingPortalDetailsOpenBillingPortal$ = createEffect(
		() => this.__actions$.pipe(
			ofType(loadBillingPortalDetailsSuccess),
			tap(({ result: { url } }) => window.open(url, '_blank', 'noreferrer')),
		),
		{ dispatch: false },
	);

	onPurchaseSubscriptionPlan$ = createEffect(
		() => this.__actions$.pipe(
			ofType(purchaseSubscriptionPlanSuccess, purchaseSubscriptionPlanFailure),
			tap(
				({ type, request }) => void this.__dispatchPurchaseGoogleTagEvent(
					request,
					type === purchaseSubscriptionPlanSuccess.type ? 'success' : 'failure',
				),
			),
		),
		{ dispatch: false },
	);

	setMustPurchaseSubscriptionPlan$ = createEffect(() => combineLatest([
		this.__currentOrganizationFacade.presentEntity$.pipe(
			map(currentOrganization => !!currentOrganization.wasCreatedAfterFreePlanDisabling),
		),
		this.__currentOrganizationSubscriptionFacade.entity$.pipe(
			filter(currentOrganizationSubscription => currentOrganizationSubscription !== undefined),
			map(currentOrganizationSubscription => !currentOrganizationSubscription || !!currentOrganizationSubscription.subscriptionPlan.isFree),
		),
	]).pipe(
		map(
			([ wasOrganizationCreatedAfterFreePlanDisabling, isFreePlanOrNoPlan ]) => isFreePlanOrNoPlan && wasOrganizationCreatedAfterFreePlanDisabling,
		),
		map(isMustPurchaseSubscriptionPlan => setMustPurchaseSubscriptionPlan({
			isMustPurchaseSubscriptionPlan,
		})),
	));

	private __showChallengeDialogAndAwaitResult(challengeHTML: string): Observable<void> {
		return this.__subscriptionPlanPaymentChallengeFacade.showDialog(challengeHTML).pipe(
			map(({ status, reason }) => {
				if (status === 'declined')
					throw new BpError(reason ?? 'Purchase declined');

				if (status === 'cancelled')
					throw new BpError('Purchase cancelled');

				this.__identityFacade.refreshAccessToken();
			}),
		);
	}

	private __dispatchPurchaseGoogleTagEvent(
		{ subscriptionPlan, chargePeriod, currency }: SubscriptionPlanPurchase,
		result: 'failure' | 'success',
	): void {
		const payload: GtagEvents.IEventPayloadMap['purchase'] = {
			subscriptionPlan: subscriptionPlan.type.name,
			chargePeriod: chargePeriod.name,
			value: subscriptionPlan.getMonthPriceFor(chargePeriod, currency),
			transactionsLimit: subscriptionPlan.limits.transactions ?? 0,
			transactionPrice: subscriptionPlan.getTransactionPriceFor(chargePeriod, currency) ?? 0,
			currency: currency.code,
		};

		const purchaseEventName: GtagEvents.List = `purchase${ result === 'success' ? '' : '_failed' }`;

		this.__googleTagService.dispatchEvent(purchaseEventName, payload);

		this.__googleTagService.dispatchEvent(`${ payload.subscriptionPlan }_plan_${ purchaseEventName }`, payload);
	}
}
