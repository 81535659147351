import { isArray } from 'lodash-es';

import {
	booleanMapper, DTO, MapFromDTO, Mapper, MetadataQueryParamsBase, mixinMetadataPageQueryParams,
	mixinMetadataSortQueryParams
} from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities/core';
import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';

import { NewsKind } from './news-kind';
import { NewsSummary, NewsSummaryKeys } from './news-summary';
import { NewsStatus } from './news-status';

type NewsSummariesQueryParamsDTO = DTO<NewsSummariesQueryParams>
& IPageQueryParamsDTO
& ISortQueryParamsDTO<NewsSummaryKeys>;

export class NewsSummariesQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, NewsSummary),
) {

	static published(dto: DTO<NewsSummariesQueryParams>): NewsSummariesQueryParams {
		return new NewsSummariesQueryParams({
			...dto,
			isPublished: true,
			sortField: 'publicationDate',
		});
	}

	static publishedToPromo(dto: DTO<NewsSummariesQueryParams>): NewsSummariesQueryParams {
		return new NewsSummariesQueryParams({
			...dto,
			isPublished: true,
			sortField: 'publicationDate',
			skipPublishingToPromoWebsite: false,
		});
	}

	private static __parseIsPublished(isPublished: boolean | string): boolean {
		const parsed = NewsStatus.parse(isPublished);

		return parsed
			? parsed === NewsStatus.published
			: !!isPublished;
	}

	@MapFromDTO()
	search?: string;

	@Mapper(value => NewsSummariesQueryParams.__parseIsPublished(value))
	isPublished?: boolean;

	kinds?: NewsKind[];

	@Mapper(booleanMapper)
	showOnMerchantAdminWhatsNewHub?: boolean;

	@Mapper(booleanMapper)
	skipPublishingToPromoWebsite?: boolean;

	constructor(dto: NewsSummariesQueryParamsDTO) {
		super(dto);

		if (dto.kinds !== undefined) {
			this.kinds = (isArray(dto.kinds) ? dto.kinds : (dto.kinds?.split(',') ?? []))
				.map(v => NewsKind.parse(v))
				.filter(isPresent);
		}
	}

}
