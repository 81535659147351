import { createAction, props } from '@ngrx/store';

import { SupportRequest } from '@bp/shared/domains/support-requests';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';

export const ACTIONS_SCOPE = '[Feature][Request Support]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const requestSupportSuccess = createAction(
	`${ scope } Request Support Success`,
);

export const requestSupportFailure = createAction(
	`${ scope } Request Support Failure`,
	props<ErrorActionPayload>(),
);

export const completeSupportRequestSuccess = createAction(
	`${ scope } Complete Support Request Success`,
);

export const getActiveSupportRequestSuccess = createAction(
	`${ scope } Get Active Support Request Success`,
	props<ResultActionPayload<SupportRequest | null>>(),
);

export const getActiveSupportRequestFailure = createAction(
	`${ scope } Get Active Support Request Failure`,
	props<ErrorActionPayload>(),
);

export const changeSupportRequestDurationSuccess = createAction(
	`${ ACTIONS_SCOPE } Change Support Request Duration Success`,
	props<ResultActionPayload<SupportRequest | null>>(),
);

export const updateSupportRequestFailure = createAction(
	`${ ACTIONS_SCOPE } Update Support Request Failure`,
	props<ErrorActionPayload>(),
);
