import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import { MerchantPspsEffects } from './state/merchant-psps.effects';
import { FEATURE_KEY, reducer } from './state/merchant-psps.reducer';

@NgModule({
	imports: [ StoreModule.forFeature(FEATURE_KEY, reducer), EffectsModule.forFeature([ MerchantPspsEffects ]) ],
})
export class MerchantAdminFrontendDomainsMerchantPspsRootModule {
	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			MerchantAdminFrontendDomainsMerchantPspsRootModule,
			async () => import('./api-mock-plugin/merchant-psps-api-mock-plugin'),
		);
	}
}
