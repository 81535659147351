import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'bp-current-subscription-management-page',
	templateUrl: './current-subscription-management-page.component.html',
	styleUrls: [ './current-subscription-management-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSubscriptionManagementPageComponent {
	protected readonly _router = inject(Router);
}
