<div>
	An email has been sent to your email address with instructions on how to reset your password. If you don't receive
	it within a few minutes, please check that you used the e-mail address for your bridgerpay account and try again or
	contact us for help.
</div>

<button
	type="button"
	class="bp-round-button mt-auto"
	mat-flat-button
	color="primary"
	(click)="introFacade.gotoLoginPage()"
>
	Login
</button>
