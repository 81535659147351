<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<div class="inputs-wrapper">
		<bp-input label="Email" inputmode="email" nativeAutocomplete="email" formControlName="userName" required />

		<bp-password-input required label="Password" nativeAutocomplete="current-password" formControlName="password" />

		<bp-turnstile-widget #turnstileWidget action="login" [reset]="!!errors" />
	</div>

	<button
		type="submit"
		class="bp-round-button mt-auto"
		mat-flat-button
		color="primary"
		[disabled]="(disabled$ | async) || (turnstileWidget.pending$ | async)"
	>
		Login

		<bp-turnstile-widget-spinner />
	</button>

	<ng-container *ngIf="showOAuthProviders">
		<div *ngIf="googleOAuthButton.enabled" class="or-separator mat-body-2 text-center mat-hint">OR</div>

		<bp-google-oauth-button
			#googleOAuthButton
			class="mb-2 flex-shrink-0"
			(identityChange)="googleIdentityChange.emit($event)"
		/>
	</ng-container>

	<div class="aux-buttons">
		<button type="button" class="link" [disabled]="form.disabled" (click)="sendResetPasswordLink.emit()">
			Forgot password?
		</button>
	</div>
</form>
