import { firstValueFrom } from 'rxjs';
import { isArray } from 'lodash-es';

import { Injectable } from '@angular/core';
import type { Route, ActivatedRouteSnapshot, Data } from '@angular/router';
import { Router } from '@angular/router';

import { Feature, Permission } from '@bp/shared/domains/permissions';

import { LOGIN_ROUTE_PATHNAME } from '@bp/admins-shared/domains/identity/models';

import { IdentityFacade } from '../state';

export type IncompleteIdentityHasAccessGuardConfig = {
	permissions: Permission[];
};

/**
 * If permissions array is provided that would mean the user will have access to the route if
 * his identity has one of the permissions
 */
export function incompleteIdentityHasAccessGuardConfig(
	permission: Permission | Permission[],
): IncompleteIdentityHasAccessGuardConfig {
	return {
		permissions: isArray(permission) ? permission : [ permission ],
	};
}

@Injectable({
	providedIn: 'root',
})
export class IncompleteIdentityHasAccessGuard {
	constructor(private readonly _router: Router, private readonly _identityFacade: IdentityFacade) {}

	async canActivate(route: ActivatedRouteSnapshot | Route): Promise<boolean> {
		const incompleteIdentity = await firstValueFrom(this._identityFacade.incompleteIdentity$);

		if (!incompleteIdentity) {
			this._navigateToLoginPage();

			return false;
		}

		const { data } = route;

		this._assertIncompleteIdentityHasAccessGuardData(data);

		const hasAccess = data.permissions.some(permission => incompleteIdentity.hasPermission(permission));

		if (!hasAccess)
			this._navigateToLoginPage();

		return hasAccess;
	}

	private _navigateToLoginPage(): void {
		void this._router.navigateByUrl(LOGIN_ROUTE_PATHNAME, { replaceUrl: true });
	}

	private _assertIncompleteIdentityHasAccessGuardData(
		data: Data | undefined,
	): asserts data is IncompleteIdentityHasAccessGuardConfig {
		const guardData = <IncompleteIdentityHasAccessGuardConfig>data;

		if (guardData.permissions.every(permission => Feature.isValid(permission)))
			return;

		throw new Error(
			'`IncompleteIdentityHasAccessGuard` must always come with the `permission` property declared on the route config data property',
		);
	}
}
