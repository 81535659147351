<mat-sidenav
	mode="over"
	position="end"
	[opened]="notificationsHubFacade.isOpened$ | async"
	[fixedInViewport]="true"
	(keydown.escape)="notificationsHubFacade.close()"
>
	<bp-notifications-hub-header />

	<bp-notifications-hub-body />
</mat-sidenav>
