import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SLIDE } from '@bp/frontend/animations';

import { NotificationsHubFacade } from '../../state';

@Component({
	selector: 'bp-notifications-hub',
	templateUrl: './notifications-hub.component.html',
	styleUrls: [ './notifications-hub.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class NotificationsHubComponent {

	constructor(public notificationsHubFacade: NotificationsHubFacade) { }

}
