import { Observable, tap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpResponse } from '@angular/common/http';

import { FIREBASE_SETTINGS } from '@bp/frontend/features/firebase/settings';

import { AUTH_PATH_SEGMENT } from '@bp/admins-shared/domains/identity';

import { CurrentOrganizationFacade } from '../state';

@Injectable()
export class HttpPostRequestOrganizationRefreshInterceptor implements HttpInterceptor {
	private readonly __currentOrganizationFacade = inject(CurrentOrganizationFacade);

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const shouldRefreshOrganizationAfterSuccessRequest
			= !request.url.includes(AUTH_PATH_SEGMENT)
			&& !request.url.includes(FIREBASE_SETTINGS.functionsURL)
			&& [ 'DELETE', 'POST' ].includes(request.method);

		return next.handle(request).pipe(
			tap(response => {
				if (shouldRefreshOrganizationAfterSuccessRequest && response instanceof HttpResponse)
					setTimeout(() => void this.__currentOrganizationFacade.refresh());
			}),
		);
	}
}
