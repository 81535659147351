import { NgModule } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import { CurrentMemberEffects } from './state/current-member/current-member.effects';
import {
	AccessibleOrganizationsEffects
} from './state/accessible-organizations/accessible-organizations.effects';
import { currentMemberDomainFeature } from './state/current-member.domain-feature';
import { LoginToAnotherOrganizationConfirmDialogComponent } from './components';

@NgModule({
	imports: [
		StoreModule.forFeature(currentMemberDomainFeature),
		EffectsModule.forFeature([
			AccessibleOrganizationsEffects,
			CurrentMemberEffects,
		]),
		MatLegacyDialogModule,
		MatLegacyButtonModule,
	],
	declarations: [
		LoginToAnotherOrganizationConfirmDialogComponent,
	],
})
export class CurrentMemberRootModule {

	constructor(private readonly _apiMockPluginsManagerService: ApiMockPluginsManagerService) {
		void this._apiMockPluginsManagerService.register(
			CurrentMemberRootModule, [
				async () => import('./api-mock-plugin/members-api-mock-plugin'),
				async () => import('./api-mock-plugin/organizations-api-mock-plugin'),
			],
		);
	}

}
