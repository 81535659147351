import { Default, DTO, Mapper, MetadataEntity, normalizeHtmlOrURL } from '@bp/shared/models/metadata';

export type PurchaseSubscriptionPlanApiResponseDTO = DTO<PurchaseSubscriptionPlanApiResponse>;

export class PurchaseSubscriptionPlanApiResponse extends MetadataEntity {
	@Mapper(normalizeHtmlOrURL)
	@Default(null)
	challengeHTML!: string | null;

	constructor(dto?: PurchaseSubscriptionPlanApiResponseDTO) {
		super(dto);
	}
}
