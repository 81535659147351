import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedFeaturesCopyToClipboardModule } from '@bp/frontend/features/copy-to-clipboard';

import { ShareBtnComponent } from './components';

const EXPOSED = [ ShareBtnComponent ];

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,

		SharedFeaturesCopyToClipboardModule,
		SharedFeaturesTooltipModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesShareModule { }
