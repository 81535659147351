
import { DTO, Required } from '@bp/shared/models/metadata';

import { NewsSummary } from './news-summary';

export class News extends NewsSummary {

	@Required()
	content!: string;

	constructor(dto?: DTO<News>) {
		super(dto);

	}

}
