import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ANALYTICS_OBSERVERS } from '@bp/frontend/features/analytics/models';

import { currentCrmUserFeature } from './current-crm-user.feature';
import { CurrentCrmUserEffects } from './current-crm-user.effects';
import { CurrentCrmUserAnalyticsObserverService } from './current-crm-user-analytics-observer.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(currentCrmUserFeature),
		EffectsModule.forFeature([ CurrentCrmUserEffects ]),
	],
})
export class SharedDomainsCrmUsersCurrentCrmUserStateModule {
	static forRoot(): ModuleWithProviders<SharedDomainsCrmUsersCurrentCrmUserStateModule> {
		return {
			ngModule: SharedDomainsCrmUsersCurrentCrmUserStateModule,
			providers: [
				{ provide: ANALYTICS_OBSERVERS, useClass: CurrentCrmUserAnalyticsObserverService, multi: true },
			],
		};
	}
}
