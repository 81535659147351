import type { IAppEnvironmentConfig } from '@bp/shared/typings';

export const environment: Partial<IAppEnvironmentConfig> = {
	sentry: 'https://bc68390a6aa44489bbfca04e4e6afb85@o205478.ingest.sentry.io/6142334',
	intercom: 'ff289wc0',
	logrocket: 'fpkaz5/prod-merchant-admin',
	googleTagAnalyticsId: 'G-2ZVHNM1ZMB',
	pardot: 994_042,
	signupOrigin: 'https://signup.bridgerpay.com',
	cloudflareTurnstileSiteKey: '0x4AAAAAAAI7CO6n4cjEITHo',
};
