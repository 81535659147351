import { createAction, props } from '@ngrx/store';

import { Organization } from '@bp/shared/domains/organizations';

import { ErrorActionPayload } from '@bp/frontend/models/common';

import { composeEntitiesListApiActions } from '@bp/admins-shared/features/entity';

export const ACTIONS_SCOPE = '[Domain][Current Member][Accessible Organizations]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const ORGANIZATIONS_LIST_API_ACTIONS = composeEntitiesListApiActions<Organization>(scope);

export const connectToOrganizationSuccess = createAction(
	`${ scope } Connect To Organization Success`,
);

export const connectToOrganizationFailure = createAction(
	`${ scope } Connect To Organization Failure`,
	props<ErrorActionPayload>(),
);
