<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<div class="inputs-wrapper">
		<bp-otp-provider-message feature="login" />

		<bp-otp-code-input class="w-100" formControlName="code" required />
	</div>

	<button type="submit" class="bp-round-button" mat-flat-button color="primary" [disabled]="pending$ | async">
		Verify
	</button>

	<div class="aux-buttons">
		<!-- temp disabling due to #AC-125 -->
		<!-- <button class="left-btn" type="button" mat-button color="primary" (click)="resetAuthApp.emit()">
			Reset Auth App
		</button> -->

		<button type="button" class="link" (click)="login.emit()">Login</button>
	</div>
</form>
