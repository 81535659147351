import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { GtagEvents, IAnalyticsObserver } from '@bp/frontend/features/analytics/models';
import { CrmOrganizationAnalytics, CrmOrganizationAnalyticsDTO } from '@bp/frontend/domains/crm/organizations/models';
import { isOrganizationEvent } from '@bp/frontend/domains/crm/core';

import { IState } from './current-crm-organization.feature';
import { trySaveCrmOrganizationAnalytics } from './current-crm-organization.actions';

@Injectable({ providedIn: 'root' })
export class CurrentCrmOrganizationAnalyticsObserverService implements IAnalyticsObserver {

	private readonly __store$ = inject<Store<IState>>(Store);

	dispatchEvent(event: GtagEvents.List, _payload?: GtagEvents.EventPayloads): void {
		if (!isOrganizationEvent(event))
			return;

		this.__saveCrmOrganizationAnalytics(
			CrmOrganizationAnalytics.buildAnalyticsEventDTO(event),
		);
	}

	private __saveCrmOrganizationAnalytics(analytics: CrmOrganizationAnalyticsDTO): void {
		this.__store$.dispatch(trySaveCrmOrganizationAnalytics({
			analytics: new CrmOrganizationAnalytics(analytics),
		}));
	}
}
