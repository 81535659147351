import { Moment } from 'moment';

import {
	Control, Default, FieldControlType, FieldViewType, FirebaseEntity, Hint, Label, MapFromDTO, Mapper, momentMapper, Required, Table, Unserializable, View, DTO, Sortable
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';
import { getLocationMainDomain } from '@bp/shared/utilities/core';

import { RouteMetatags } from '@bp/frontend/features/metatags/models';
import { YoutubeHelper } from '@bp/frontend/features/youtube-player/utilities';

import { ArticleStatus } from './article-status';

export type ArticleKeys = NonFunctionPropertyNames<Article>;

export type ArticleDTO = DTO<Article>;

export const YOUTUBE_LINK_TRANSFORMER = (value: string | null): string | null => value
	? YoutubeHelper.extractVideoIdFromUrl(value) ?? value
	: value;

export abstract class Article extends FirebaseEntity {

	@Unserializable()
	override name!: string | null;

	@Required()
	@Table({ ellipsis: true })
	title!: string;

	@View(FieldViewType.link, propertyValue => YoutubeHelper.getVideoUrl(propertyValue))
	@Control(FieldControlType.input, { controlValueTransformer: YOUTUBE_LINK_TRANSFORMER })
	youtubeVideoId!: string | null;

	@Default(null)
	@View(FieldViewType.textarea)
	@Control(FieldControlType.textarea)
	@Hint('Used as a preview text and a SEO meta description')
	summary!: string | null;

	@MapFromDTO()
	createdBy!: string;

	@MapFromDTO()
	coverImgUrl!: string | null;

	@Table()
	@Sortable()
	override updatedBy!: string;

	@Label('Updated')
	@View(FieldViewType.momentFromNow)
	@Table()
	@Sortable({ isDefault: true })
	override updatedAt!: Moment | null;

	@Mapper(momentMapper)
	@Table()
	@Sortable()
	@Control(FieldControlType.date)
	@View(FieldViewType.moment, () => 'DD MMM YYYY')
	@Hint('If not provided the date of the first publishing is used')
	publicationDate!: Moment | null;

	@Default(false)
	@Label('Status')
	@Table()
	@Sortable()
	@View(
		FieldViewType.booleanCircle,
		v => v ? ArticleStatus.published : ArticleStatus.notPublished,
	)
	isPublished!: boolean;

	@MapFromDTO()
	readonly searchTerms!: string[];

	abstract metatags: RouteMetatags;

	get url(): string {
		return this.metatags.url?.startsWith('https')
			? this.metatags.url
			: `https://${ getLocationMainDomain() }${ this.metatags.url }`;
	}

	constructor(dto?: ArticleDTO) {
		super(dto);

		this.name = this.title;

		this.searchTerms = dto?.title
			?.toLowerCase()
			.split(/[\s/:-]/u)
			.filter(v => !!v) ?? [];
	}

}
