import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { EnvironmentService } from '@bp/frontend/services/environment';

import {
	CurrentOrganizationFacade,
	FeatureVersionsService
} from '@bp/merchant-admin/frontend/domains/current-organization';

@Component({
	selector: 'bp-menu',
	templateUrl: './menu.component.html',
	styleUrls: [ './menu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {

	MerchantAdminFeature = MerchantAdminFeature;

	constructor(
		public environment: EnvironmentService,
		public featureVersionsService: FeatureVersionsService,
		public currentOrganizationFacade: CurrentOrganizationFacade,
	) {}
}
