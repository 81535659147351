<div>
	Readonly access granted by
	<b [bpTooltip]="supportRequest.requestedBy.email">
		{{ supportRequest.requestedBy.nameOrEmail }}
	</b>
	<b [bpTooltip]="supportRequest.created | momentFormat: 'LLL' | async">
		{{ supportRequest.created | momentFromNow | async }}
	</b>
</div>

<div>
	Access will be revoked

	<b [bpTooltip]="supportRequest.expiresAt | momentFormat: 'LLL' | async">{{
		supportRequest.expiresAt | momentFromNow | async
	}}</b>
</div>

<div *ngIf="supportRequest.message" class="mb-0">Message: {{ supportRequest.message }}</div>
