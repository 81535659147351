import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';

import { Identity } from '@bp/admins-shared/domains/identity/models';

import { ACTIONS_SCOPE } from './user.actions';

const apiScope = `[API]${ ACTIONS_SCOPE }`;

export const changePasswordSuccess = createAction(
	`${ apiScope } Change Password Success`,
	props<ResultActionPayload<Identity>>(),
);

export const changePasswordFailure = createAction(
	`${ apiScope } Change Password Failure`,
	props<ErrorActionPayload>(),
);
