import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NEST_PATH_SEGMENT, MEMBERS_PATH_SEGMENT } from '@bp/shared/models/core';

import { GET_MEMBER_HASH_PATH_SEGMENT, TRACK_MEMBER_PATH_SEGMENT } from '@bp/merchant-admin/shared/domains/members';
import { bypassRefreshToken } from '@bp/merchant-admin/frontend/domains/identity';

@Injectable({
	providedIn: 'root',
})
export class CurrentMemberApiService {

	readonly collectionPath = `${ NEST_PATH_SEGMENT }/${ MEMBERS_PATH_SEGMENT }`;

	constructor(private readonly _http: HttpClient) {}

	track(): Observable<void> {
		return this._http.post<void>(
			`${ this.collectionPath }/${ TRACK_MEMBER_PATH_SEGMENT }`,
			{},
			{
				context: bypassRefreshToken(),
			},
		);
	}

	getMemberHash(): Observable<string> {
		return this._http.get<string>(
			`${ this.collectionPath }/${ GET_MEMBER_HASH_PATH_SEGMENT }`,
		);
	}

}
