import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppVersionComponent } from '@bp/frontend/components/app-version';
import { DeveloperOverlayPanelComponent } from '@bp/frontend/components/developer-overlay-panel';

@Component({
	selector: 'bp-admins-footer',
	standalone: true,
	imports: [
		CommonModule,
		AppVersionComponent,
		DeveloperOverlayPanelComponent,
	],
	templateUrl: './admins-footer.component.html',
	styleUrls: [ './admins-footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminsFooterComponent {

}
