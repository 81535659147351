<form *ngIf="form" cdkTrapFocus [cdkTrapFocusAutoCapture]="!pending" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert class="mb-n5 mt-4" type="danger" [errors]="errors" />

	<div class="mt-5 mb-5">
		<p>Provide an answer to your security question</p>

		<bp-select-field
			label="Your Security Question"
			formControlName="id"
			required
			itemDisplayValuePropertyName="text"
			[items]="identitySecurityQuestions"
		>
			<span *bpSelectOption="let securityQuestion">
				{{ securityQuestion.text }}
			</span>
		</bp-select-field>

		<bp-input class="mt-n3 w-100" label="Answer" formControlName="answer" required [isSecret]="true" />
	</div>

	<button
		class="bp-round-button mt-auto"
		type="submit"
		mat-flat-button
		color="primary"
		[class.mb-2]="!hasAnotherWay"
		[disabled]="canNotSave$ | async"
	>
		Next
	</button>

	<div *ngIf="hasAnotherWay" class="aux-buttons">
		<button class="link" type="button" (click)="tryAnotherWay.emit()">Try another way</button>
	</div>
</form>
