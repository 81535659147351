<bp-register-authenticator-app-form
	class="h-100"
	submitButtonText="Connect Authenticator"
	submitButtonGtagEventName="connect_authenticator_click"
	[userEmail]="(identityFacade.incompleteIdentity$ | async)?.email"
	[authenticatorKey]="identityFacade.authenticatorKey$ | async"
	[pending]="introFacade.pending$ | async"
	[error]="introFacade.error$ | async"
	(submitted)="identityFacade.registerAuthenticatorApp($event)"
	(login)="introFacade.gotoLoginPage()"
/>
