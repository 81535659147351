import { Component, ChangeDetectionStrategy } from '@angular/core';

import { IdentityPreferencesFacade } from '@bp/admins-shared/domains/identity';

@Component({
	templateUrl: './identity-preferences-portal-tab-page.component.html',
	styleUrls: [ './identity-preferences-portal-tab-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class IdentityPreferencesPortalTabPageComponent {

	constructor(public identityPreferencesFacade: IdentityPreferencesFacade) { }

}
