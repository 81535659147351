import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IntroFacade } from '../../state/intro.facade';

@Component({
	selector: 'bp-session-expired-page',
	templateUrl: './session-expired-page.component.html',
	styleUrls: [ './session-expired-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpiredPageComponent {

	constructor(public introFacade: IntroFacade) {
	}

}
