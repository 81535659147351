import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/frontend/models/core';

import { changePasswordFailure, changePasswordSuccess } from './user-api.actions';
import { changePassword } from './user.actions';

export const FEATURE_KEY = '[page]:user';

export interface IState {

	error: BpError | null;

	pending: boolean;

}

export const initialState: IState = {
	error: null,
	pending: false,
};

const userReducer = createReducer(

	initialState,

	on(
		changePassword,
		(state): IState => ({
			...state,
			error: null,
			pending: true,
		}),
	),

	on(
		changePasswordSuccess,
		(state): IState => ({
			...state,
			error: null,
			pending: false,
		}),
	),

	on(
		changePasswordFailure,
		(state, { error }): IState => ({
			...state,
			error,
			pending: false,
		}),
	),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return userReducer(state, action);
}
