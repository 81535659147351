import m from 'moment';
import { combineLatest, map, switchMap, timer } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';

import { SupportRequestDurationChange } from '@bp/shared/domains/support-requests';

import { FADE_IN, SLIDE_UP } from '@bp/frontend/animations';
import { BaseDialogComponent } from '@bp/frontend/components/dialogs';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { RequestSupportFacade } from '../../state';

import { RequestSupportFormComponent } from './components';

@Component({
	selector: 'bp-request-support-dialog',
	templateUrl: './request-support-dialog.component.html',
	styleUrls: [ './request-support-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP, FADE_IN ],
})
export class RequestSupportDialogComponent extends BaseDialogComponent {

	@ViewChild('form') form?: RequestSupportFormComponent;

	protected readonly _requestSupportFacade = inject(RequestSupportFacade);

	private readonly __identityFacade = inject(IdentityFacade);

	protected _canEdit$ = combineLatest([
		this._requestSupportFacade.presentEntity$,
		this.__identityFacade.userPresent$,
	]).pipe(map(([ supportRequest, user ]) => supportRequest.requestedBy.user?.id === user.id));

	protected _availableSupportRequestDurationChanges$ = this._requestSupportFacade.presentEntity$.pipe(
		switchMap(supportRequest => timer(0, 1000).pipe(
			map(() => new Set(SupportRequestDurationChange.getList().filter(
				duration => supportRequest.expiresAt
					.clone()
					.add(duration.seconds, 'seconds')
					.isAfter(m()),
			))),
		)),
	);

	protected _canCloseWithTrueOnEnter(): boolean {
		return false;
	}

}
