import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';

export const ACTIONS_SCOPE = '[Current Member]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const trackMemberSuccess = createAction(
	`${ scope } Track Success`,
);

export const trackMemberFailure = createAction(
	`${ scope } Track Failure`,
	props<ErrorActionPayload>(),
);

export const getMemberHashSuccess = createAction(
	`${ scope } Get Member Hash Success`,
	props<ResultActionPayload<string>>(),
);

export const getMemberHashFailure = createAction(
	`${ scope } Get Member Hash Failure`,
	props<ErrorActionPayload>(),
);
