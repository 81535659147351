import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MockUserEmail } from '@bp/shared/domains/jwt-session';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { IntroFacade } from '../../state/intro.facade';

@Component({
	selector: 'bp-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: [ './login-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
	constructor(
		public readonly environment: EnvironmentService,
		public readonly introFacade: IntroFacade,
	) {
		this._loginDemoUserInDemoMode();
	}

	private _loginDemoUserInDemoMode(): void {
		if (MockedBackendState.isDemoMode)
			this.introFacade.loginAsMockBackendUser(MockUserEmail.Demo);
	}
}
