import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroFacade } from '../../state';

@Component({
	selector: 'bp-register-authenticator-app-page',
	templateUrl: './register-authenticator-app-page.component.html',
	styleUrls: [ './register-authenticator-app-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterAuthenticatorAppPageComponent {

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) {
		this.identityFacade.loadAuthenticatorAppKey();
	}

}
