import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminsSharedSubscriptionPlansModule } from './admins-shared-subscription-plans.module';
import { SUBSCRIPTION_PLANS_API_COLLECTION_PATH } from './services';
import { SubscriptionPlansEffects } from './state/subscription-plans-shared.effects';
import { FEATURE_KEY, reducer } from './state/subscription-plans-shared.reducer';

@NgModule({
	imports: [
		AdminsSharedSubscriptionPlansModule,

		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ SubscriptionPlansEffects ]),
	],
	providers: [
		{ provide: SUBSCRIPTION_PLANS_API_COLLECTION_PATH, useValue: 'subscriptions' },
	],
})
export class AdminsSharedSubscriptionPlansRootModule { }
