import { shareReplay, distinctUntilChanged, lastValueFrom, first } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { isEqual } from '@bp/shared/utilities/core';
import { DTO } from '@bp/shared/models/metadata';

import { CrmUser, CrmUserAnalytics } from '@bp/frontend/domains/crm/users/models';
import { takePresent } from '@bp/frontend/rxjs';

import { resetState, stopObservingCrmUserRemoteChanges, trySaveCrmUserKeptInStore, updateCrmUserInStore, observeCrmUserRemoteChanges } from './current-crm-user.actions';
import { currentCrmUserFeature, IState } from './current-crm-user.feature';

/**
 * State management for the current user stored in firebase
 */
@Injectable({
	providedIn: 'root',
})
export class CurrentCrmUserFacade {

	private readonly __store$ = inject<Store<IState>>(Store);

	user$ = this.__store$.select(currentCrmUserFeature.selectUser);

	userPresent$ = this.user$.pipe(takePresent);

	user: CrmUser | null = null;

	analytics$ = this.__store$
		.select(currentCrmUserFeature.selectAnalytics)
		.pipe(
			distinctUntilChanged(isEqual),
			shareReplay({ bufferSize: 1, refCount: false }),
		);

	analytics: CrmUserAnalytics | null = null;

	constructor() {
		this.__keepUserPropertyUpdated();

		this.__keepAnalyticsPropertyUpdated();
	}

	factory = (dto?: DTO<CrmUser>): CrmUser => new CrmUser(dto);

	observeCrmUserRemoteChanges(userId: string): void {
		this.__store$.dispatch(observeCrmUserRemoteChanges({
			userId,
		}));
	}

	async updateUserInStore(partialUser: DTO<CrmUser>): Promise<void> {
		const updatedUser = this.factory({
			...this.user,
			...partialUser,
			analytics: {
				...this.user?.analytics,
				...partialUser.analytics,
			},
		});

		this.__store$.dispatch(updateCrmUserInStore({ user: updatedUser }));

		await lastValueFrom(this.user$.pipe(
			first(user => user === updatedUser),
		));
	}

	resetState(): void {
		this.__store$.dispatch(resetState());

		this.__store$.dispatch(stopObservingCrmUserRemoteChanges());
	}

	saveUserKeptInStore(): void {
		this.__store$.dispatch(trySaveCrmUserKeptInStore({
			user: this.user,
		}));
	}

	async updateAndSaveUserKeptInStore(partialUser: DTO<CrmUser>): Promise<void> {
		await this.updateUserInStore(partialUser);

		this.saveUserKeptInStore();
	}

	private __keepUserPropertyUpdated(): void {
		this.user$.subscribe(user => (this.user = user));
	}

	private __keepAnalyticsPropertyUpdated(): void {
		this.analytics$.subscribe(analytics => (this.analytics = analytics));
	}

}
