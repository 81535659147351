import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import { DTO, IEntitiesApiService } from '@bp/shared/models/metadata';
import { NEST_PATH_SEGMENT } from '@bp/shared/models/core';
import { Organization } from '@bp/shared/domains/organizations';

import { toHttpParams } from '@bp/frontend/utilities/common';
import { mapToArray } from '@bp/frontend/rxjs';

import { bypassRefreshToken } from '@bp/merchant-admin/frontend/domains/identity';
import {
	CONNECT_ORGANIZATION_PATH_SEGMENT, ACCESSIBLE_ORGANIZATIONS_PATH_SEGMENT
} from '@bp/merchant-admin/shared/domains/current-member';

import { AccessibleOrganizationsQueryParams } from '../models';

@Injectable({
	providedIn: 'root',
})
export class AccessibleOrganizationsApiService implements IEntitiesApiService<Organization, AccessibleOrganizationsQueryParams> {

	private readonly __httpClient = inject(HttpClient);

	readonly collectionPath = `${ NEST_PATH_SEGMENT }/${ ACCESSIBLE_ORGANIZATIONS_PATH_SEGMENT }`;

	readonly factory = (dto?: DTO<Organization>): Organization => new Organization(dto);

	getRecordsPage(query?: AccessibleOrganizationsQueryParams): Observable<RecordsPage<Organization>> {
		return this.__httpClient.get<DTO<Organization>[]>(
			this.collectionPath,
			{ params: toHttpParams(query) },
		).pipe(
			mapToArray(this.factory),
			map(result => result.sort(a => a.isSandbox ? 1 : -1)),
			map(
				result => new RecordsPage({
					nextPageCursor: null,
					firstPage: null,
					records: result,
				}),
			),
		);
	}

	connectToOrganization(organization: Organization): Observable<void> {
		return this.__httpClient.post<void>(
			`${ this.collectionPath }/${ CONNECT_ORGANIZATION_PATH_SEGMENT }/${ organization.id }`,
			null,
			{
				context: bypassRefreshToken(),
			},
		);
	}

	get(_id: string): Observable<Organization | null> {
		throw new Error('Not implemented');
	}

	save(_organization: Organization): Observable<Organization> {
		throw new Error('Not implemented');
	}

	delete(_organization: Organization): Observable<void> {
		throw new Error('Not implemented');
	}

}
