import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import { CurrentOrganizationEffects } from './state/current-organization.effects';
import { FEATURE_KEY, reducer } from './state/current-organization.reducer';
import { HttpPostRequestOrganizationRefreshInterceptor } from './services';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ CurrentOrganizationEffects ]),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpPostRequestOrganizationRefreshInterceptor,
			multi: true,
		},
	],
})
export class MerchantAdminFrontendDomainsCurrentOrganizationRootModule {
	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			MerchantAdminFrontendDomainsCurrentOrganizationRootModule,
			async () => import('./api-mock-plugin/current-organization-api-mock-plugin'),
		);
	}
}
