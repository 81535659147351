import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { trackById } from '@bp/shared/utilities/core';

import { FADE_IN, HUB_ITEM_FLY_IN_OUT } from '@bp/frontend/animations';

import { NotificationsHubFacade } from '../../state';

@Component({
	selector: 'bp-notifications-hub-body',
	templateUrl: './notifications-hub-body.component.html',
	styleUrls: [ './notifications-hub-body.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'bp-scrollbar',
	},
	animations: [
		FADE_IN,
		HUB_ITEM_FLY_IN_OUT,
	],
})
export class NotificationsHubBodyComponent {

	trackById = trackById;

	showNoNotificationsMsg$ = new BehaviorSubject(true);

	constructor(public notificationsHubFacade: NotificationsHubFacade) { }

}
