import { uniq } from 'lodash-es';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { Country } from '@bp/shared/models/countries';
import { isInstanceOf } from '@bp/shared/utilities/core';

import { PaymentRoute, RoutePspPaymentOptionCreditCard } from '../payment-route';

export type PaymentRoutesCreditCardPspsPerCountryMap = Map<Country, string[]>;

export type PaymentRoutesCurrenciesPerCountryMap = Map<Country, FiatCurrency[]>;

export class VirtualTerminalData {

	creditCardPspsPerCountryMap = this._extractFromPaymentRoutesCreditCardPspsPerCountry();

	currenciesPerCountryMap = this._extractFromPaymentRoutesCurrenciesPerCountry();

	constructor(private readonly _paymentRoutes: PaymentRoute[]) { }

	private _extractFromPaymentRoutesCreditCardPspsPerCountry(): PaymentRoutesCreditCardPspsPerCountryMap {
		return new Map(
			this._paymentRoutes.flatMap(paymentRoute => {
				const paymentRouteCreditCardPsps = paymentRoute.psps
					.filter(isInstanceOf(RoutePspPaymentOptionCreditCard))
					.map(paymentOption => (paymentOption.pspName));

				return paymentRoute.countries.map(country => [ country, paymentRouteCreditCardPsps ]);
			}),

		);
	}

	private _extractFromPaymentRoutesCurrenciesPerCountry(): PaymentRoutesCurrenciesPerCountryMap {
		return new Map(
			this._paymentRoutes.flatMap(paymentRoute => {
				const paymentRouteCurrencies = uniq(paymentRoute.psps
					.filter(isInstanceOf(RoutePspPaymentOptionCreditCard))
					.flatMap(psp => psp.currencies));

				return paymentRoute.countries.map(country => [ country, paymentRouteCurrencies ]);
			}),
		);
	}
}
