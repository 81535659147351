import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ANALYTICS_OBSERVERS } from '@bp/frontend/features/analytics/models';

import { currentCrmOrganizationFeature } from './current-crm-organization.feature';
import { CurrentCrmOrganizationEffects } from './current-crm-organization.effects';
import { CurrentCrmOrganizationAnalyticsObserverService } from './current-crm-organization-analytics-observer.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(currentCrmOrganizationFeature),
		EffectsModule.forFeature([ CurrentCrmOrganizationEffects ]),
	],
})
export class SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule {
	static forRoot(): ModuleWithProviders<SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule> {
		return {
			ngModule: SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule,
			providers: [
				{ provide: ANALYTICS_OBSERVERS, useClass: CurrentCrmOrganizationAnalyticsObserverService, multi: true },
			],
		};
	}
}
