import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BridgerAdminFeature, MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { filterPresent } from '@bp/frontend/rxjs';

import {
	IdentityFacade, sendResetAuthenticatorAppLinkSuccess, sendResetPasswordLinkSuccess
} from '@bp/admins-shared/domains/identity';
import { Identity } from '@bp/admins-shared/domains/identity/models';
import { IntroPageStep } from '@bp/admins-shared/pages/intro/models';

import { changeIntroPageStep } from './intro.actions';
import { IntroFacade } from './intro.facade';

@Injectable()
export class IntroEffects {

	navigateToIntroStep$ = createEffect(
		() => this._actions$.pipe(
			ofType(changeIntroPageStep),
			tap(({ step }) => void this._router.navigate([ '/intro', step.kebabCase ])),
		),
		{ dispatch: false },
	);

	changeIntroStepBasedOnIncompleteIdentityPermission = this._identityFacade.incompleteIdentity$
		.pipe(filterPresent)
		.subscribe(incompleteIdentity => {
			if ([
				BridgerAdminFeature.baCreateAccount,
				MerchantAdminFeature.createAccount,
			].some(permission => incompleteIdentity.hasPermission(permission)))
				this._introFacade.setStep(IntroPageStep.createAccount);

			if ([
				BridgerAdminFeature.baLoginAfterInvite,
				MerchantAdminFeature.loginAfterInvite,
			].some(permission => incompleteIdentity.hasPermission(permission)))
				this._introFacade.setStep(IntroPageStep.loginAfterInvite);

			if (incompleteIdentity.hasPermission(MerchantAdminFeature.setSecurityQuestionsAnswers))
				this._introFacade.setStep(IntroPageStep.setSecurityQuestionsAnswers);

			if (incompleteIdentity.hasPermission(MerchantAdminFeature.registerAuthenticator))
				this._introFacade.setStep(IntroPageStep.registerAuthenticatorApp);

			if (this._shouldGoToResetPasswordStep(incompleteIdentity))
				this._introFacade.setStep(IntroPageStep.resetPassword);

			if (incompleteIdentity.hasPermission(MerchantAdminFeature.resetAuthenticator))
				this._introFacade.setStep(IntroPageStep.resetAuthenticatorApp);

			if (incompleteIdentity.hasPermission(MerchantAdminFeature.otpVerify))
				this._introFacade.setStep(IntroPageStep.otpOnLogin);
		});

	onSendResetPasswordLinkSuccessGotoEmailSentPage$ = createEffect(
		() => this._actions$.pipe(
			ofType(sendResetPasswordLinkSuccess),
			tap(() => void this._introFacade.setStep(IntroPageStep.resetPasswordLinkSent)),
		),
		{ dispatch: false },
	);

	onSendResetAuthenticatorAppLinkSuccessGotoEmailSentPage$ = createEffect(
		() => this._actions$.pipe(
			ofType(sendResetAuthenticatorAppLinkSuccess),
			tap(() => void this._introFacade.setStep(IntroPageStep.resetAuthenticatorAppLinkSent)),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _actions$: Actions,
		private readonly _router: Router,
		private readonly _introFacade: IntroFacade,
		private readonly _identityFacade: IdentityFacade,
	) {}

	private _shouldGoToResetPasswordStep(incompleteIdentity: Identity): boolean {
		return [
			MerchantAdminFeature.resetPassword,
			MerchantAdminFeature.resetExpiredPassword,
		]
			.some(permission => incompleteIdentity.hasPermission(permission));
	}

}
