import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroFacade } from '../../state/intro.facade';

@Component({
	selector: 'bp-otp-on-login-page',
	templateUrl: './otp-on-login-page.component.html',
	styleUrls: [ './otp-on-login-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpOnLoginPageComponent {

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) { }

}
