import { MonoTypeOperatorFunction, Observable, auditTime, partition, merge } from 'rxjs';

import { GtagEvents } from '@bp/frontend/features/analytics/models';

export const FLUSH_TIMEOUT = 2000;

export function flushLastSourceValueOnTimeoutOrAnalyticsEventChange<T>(
	eventSelector: (this: void, sourceValue: T) => GtagEvents.List | undefined,
): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => {
		const [ eventSourceValues$, eventlessSourceValues$ ] = partition(
			source$,
			sourceValue => !!eventSelector(sourceValue),
		);

		return merge(
			eventSourceValues$,
			eventlessSourceValues$.pipe(auditTime(FLUSH_TIMEOUT)),
		);
	};
}
