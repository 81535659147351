import { switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NewsApiService } from '@bp/frontend/domains/newsroom';
import { apiResult } from '@bp/frontend/models/common';

import { AnnouncementsFacade } from '../list';

import { loadFailure, loadSuccess } from './announcement-api.actions';
import { load } from './announcement.actions';

@Injectable()
export class AnnouncementEffects {
	onLoad$ = createEffect(() => this._actions$.pipe(
		ofType(load),
		switchMap(({ id }) => this._newsApiService.get(id).pipe(apiResult(loadSuccess, loadFailure))),
	));

	onAnnouncementLoadMarkItAsSeen$ = createEffect(
		() => this._actions$.pipe(
			ofType(loadSuccess),
			tap(({ result }) => void this._announcementsFacade.markAnnouncementsAsSeen([ result ])),
		),
		{ dispatch: false },
	);

	constructor(
		private readonly _announcementsFacade: AnnouncementsFacade,
		private readonly _newsApiService: NewsApiService,
		private readonly _actions$: Actions,
	) {}
}
