import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SupportRequest } from '@bp/shared/domains/support-requests';

import { BpError } from '@bp/frontend/models/core';

@Component({
	selector: 'bp-active-request-info[supportRequest]',
	templateUrl: './active-request-info.component.html',
	styleUrls: [ './active-request-info.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'alert alert-success alert-with-border alert-shadowless mb-2',
	},
})
export class ActiveRequestInfoComponent {

	@Input() supportRequest!: SupportRequest;

	@Input() error!: BpError | null;

}
