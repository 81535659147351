import { mergeMap, defer, filter, map, distinctUntilChanged, switchMap, tap, takeUntil, auditTime } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { isEqual } from '@bp/shared/utilities/core';

import { CrmOrganization } from '@bp/frontend/domains/crm/organizations/models';
import { CrmOrganizationsApiService } from '@bp/frontend/domains/crm/organizations/services';
import { apiVoidResult } from '@bp/frontend/models/common';
import { flushLastSourceValueOnTimeoutOrAnalyticsEventChange, FLUSH_TIMEOUT } from '@bp/frontend/features/firebase/utils';
import { EnvironmentService } from '@bp/frontend/services/environment';

import {
	saveCrmOrganizationKeptInStore, saveCrmOrganizationAnalytics, observeCrmOrganizationRemoteChanges, stopObservingCrmOrganizationRemoteChanges, trySaveCrmOrganizationAnalytics, trySaveCrmOrganizationKeptInStore
} from './current-crm-organization.actions';
import {
	saveCrmOrganizationFailure, saveCrmOrganizationSuccess, saveCrmOrganizationAnalyticsFailure, saveCrmOrganizationAnalyticsSuccess
} from './current-crm-organization-api.actions';
import { CurrentCrmOrganizationFacade } from './current-crm-organization.facade';

@Injectable()
export class CurrentCrmOrganizationEffects {

	private readonly __actions$ = inject<Actions>(Actions);

	private readonly __environment = inject(EnvironmentService);

	private readonly __currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	private readonly __crmOrganizationsApiService = inject(CrmOrganizationsApiService);

	trySaveCrmOrganizationKeptInStore$ = createEffect(() => this.__actions$.pipe(
		ofType(trySaveCrmOrganizationKeptInStore),
		map(({ organization }) => organization),
		filter((organization): organization is CrmOrganization => !!organization?.id),
		distinctUntilChanged(isEqual),
		auditTime(FLUSH_TIMEOUT),
		map(organization => saveCrmOrganizationKeptInStore({ organization })),
	));

	trySaveCrmOrganizationAnalytics$ = createEffect(() => this.__actions$.pipe(
		ofType(trySaveCrmOrganizationAnalytics),
		map(() => this.__currentCrmOrganizationFacade.factory({
			...this.__currentCrmOrganizationFacade.organization,
			analytics: this.__currentCrmOrganizationFacade.analytics,
		})),
		filter(organization => !!organization.id),
		distinctUntilChanged(isEqual),
		flushLastSourceValueOnTimeoutOrAnalyticsEventChange(organization => organization.analytics?.lastEventName),
		map(organization => saveCrmOrganizationAnalytics({ organization })),
	));

	saveCrmOrganization$ = createEffect(() => this.__actions$.pipe(
		ofType(saveCrmOrganizationKeptInStore, saveCrmOrganizationAnalytics),
		filter(() => this.__environment.isDeployedStagingOrProduction),
		mergeMap(({ type, organization }) => defer(
			() => this.__crmOrganizationsApiService
				.write(organization),
		)
			.pipe(apiVoidResult(
				type === saveCrmOrganizationKeptInStore.type ? saveCrmOrganizationSuccess : saveCrmOrganizationAnalyticsSuccess,
				type === saveCrmOrganizationKeptInStore.type ? saveCrmOrganizationFailure : saveCrmOrganizationAnalyticsFailure,
			))),
	));

	observeRemoteChanges$ = createEffect(
		() => this.__actions$.pipe(
			ofType(observeCrmOrganizationRemoteChanges),
			switchMap(({ organizationId }) => this.__crmOrganizationsApiService
				.listenToRemoteChanges(organizationId)
				.pipe(takeUntil(this.__actions$.pipe(ofType(stopObservingCrmOrganizationRemoteChanges))))),
			tap(crmOrganization => crmOrganization && void this.__currentCrmOrganizationFacade.updateOrganizationInStore({
				...crmOrganization,
				analytics: {
					dispatchedEventsCountMap: crmOrganization.analytics?.dispatchedEventsCountMap,
				},
			})),
		),
		{ dispatch: false },
	);

}
