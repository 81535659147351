<div>
	An email has been sent to your email address with instructions on how to reset your authenticator app. If you don't
	receive it within a few minutes,try again or contact us for help.
</div>

<button
	class="bp-round-button mb-2"
	type="button"
	mat-flat-button
	color="primary"
	(click)="introFacade.gotoLoginPage()"
>
	Login
</button>
