
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';
import { IGoogleIdentity } from '@bp/frontend/features/oauth';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

import type { ILoginApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: [ './login-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent extends FormBaseComponent<ILoginApiRequest> {

	MockedBackendState = MockedBackendState;

	@Input() showOAuthProviders = true;

	@Output() readonly sendResetPasswordLink = new EventEmitter();

	@Output() readonly googleIdentityChange = new EventEmitter<IGoogleIdentity>();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<ILoginApiRequest>({
				userName: [ '', { validators: Validators.email() }],
				password: [ '' ],
			}),
		);
	}
}
