import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OrganizationBillingPortalDetails } from '@bp/shared/domains/organizations';
import { DTO } from '@bp/shared/models/metadata';

import { mapTo } from '@bp/frontend/rxjs';
import { SubscriptionPlanPurchase } from '@bp/frontend/domains/subscription-plans/checkout';

import {
	DowngradeSubscriptionPlanApiRequest,
	PurchaseSubscriptionPlanApiResponse,
	PurchaseSubscriptionPlanApiResponseDTO
} from '../models';

@Injectable({
	providedIn: 'root',
})
export class CurrentOrganizationSubscriptionApiService {
	private readonly _namespace = 'merchant';

	constructor(private readonly _http: HttpClient) {}

	purchaseSubscriptionPlan(request: SubscriptionPlanPurchase): Observable<PurchaseSubscriptionPlanApiResponse> {
		return this._http
			.post<PurchaseSubscriptionPlanApiResponseDTO>(`${ this._namespace }/change-subscription`, request)
			.pipe(mapTo(PurchaseSubscriptionPlanApiResponse));
	}

	downgradeSubscription(request: DowngradeSubscriptionPlanApiRequest): Observable<void> {
		return this._http.post<void>(`${ this._namespace }/change-subscription`, request);
	}

	loadBillingPortalDetails(): Observable<OrganizationBillingPortalDetails> {
		return this._http
			.get<DTO<OrganizationBillingPortalDetails>>(`${ this._namespace }/billing-portal`)
			.pipe(mapTo(OrganizationBillingPortalDetails));
	}
}
