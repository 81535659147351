import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { ICookiesUsageConsent } from '@bp/frontend/features/analytics/models';

@Injectable({ providedIn: 'root' })
export class CookiesUsageConsentService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __cookiesUsageConsent$ = new BehaviorSubject<ICookiesUsageConsent | null>(
		this.__environment.isProduction
			? null
			: { marketing: true, preferences: true, statistics: true },
	);

	consent$ = this.__cookiesUsageConsent$.asObservable();

	constructor() {
		if (this.__environment.isProduction)
			this.__setConsentAccordingToCMP();
	}

	showWidget(): void {
		window.illow?.showWidget?.();
	}

	private __setConsentAccordingToCMP(): void {
		this.__setConsentWhenWasAlreadyGiven();

		this.__setConsentWhenGiven();
	}

	private __setConsentWhenWasAlreadyGiven(): void {
		const existingConsent = window.illow?.getConsent?.();

		if (existingConsent) {
			this.__cookiesUsageConsent$.next(
				this.__mapIllowConsentToCookiesUsageConsent(existingConsent),
			);

			return;
		}

		// https://docs.illow.io/docs/website-sdk/sdk-events#illowwidget_ready
		window.addEventListener<any>(
			'illow:widget_ready',
			({ detail }: { detail: { hasPreviousConsent: boolean } }) => {
				if (detail.hasPreviousConsent) {
					this.__cookiesUsageConsent$.next(
						this.__mapIllowConsentToCookiesUsageConsent(window.illow!.getConsent!()),
					);
				}
			},
		);
	}

	private __setConsentWhenGiven(): void {
		// https://docs.illow.io/docs/website-sdk/sdk-events#consent_set
		window.addEventListener<any>(
			'illow:consent_set',
			({ detail }: { detail: IIllowCookiesUsageConsent }) => void this.__cookiesUsageConsent$.next(
				this.__mapIllowConsentToCookiesUsageConsent(detail),
			),
		);
	}

	private __mapIllowConsentToCookiesUsageConsent(consent: IIllowCookiesUsageConsent): ICookiesUsageConsent {
		return {
			marketing: consent.marketing.value,
			preferences: consent.preferences.value,
			statistics: consent.statistics.value,
		};
	}
}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		illow?: {

			/**
			 * https://docs.illow.io/docs/website-sdk/sdk-methods#getconsent
			 */
			getConsent?: () => IIllowCookiesUsageConsent;

			showWidget?: () => void;
		};
	}

	interface IIllowCookiesUsageConsent {
		isPreviousConsent: boolean;
		'consent-id': string;
		necessary: IIllowCookiePreference;
		marketing: IIllowCookiePreference;
		preferences: IIllowCookiePreference;
		statistics: IIllowCookiePreference;
	}

	interface IIllowCookiePreference {
		value: boolean;
		services: { id: string; value: boolean }[];
	}
}
