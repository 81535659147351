import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IntroFacade } from '../../../state';

@Component({
	selector: 'bp-reset-authenticator-app-link-sent-page',
	templateUrl: './reset-authenticator-app-link-sent-page.component.html',
	styleUrls: [ './reset-authenticator-app-link-sent-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetAuthenticatorAppLinkSentPageComponent {

	constructor(
		public introFacade: IntroFacade,
	) { }
}
