import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/frontend/models/core';

import { IntroPageStep } from '@bp/admins-shared/pages/intro/models';

import { changeIntroPageStep } from './intro.actions';

export const FEATURE_KEY = '[page]:intro';

export interface IState {

	step: IntroPageStep;

	error: BpError | null;

	pending: boolean;

}
export const initialState: IState = {
	step: IntroPageStep.login,
	error: null,
	pending: false,
};

const introReducer = createReducer(

	initialState,

	on(
		changeIntroPageStep,
		(state, { step }): IState => ({
			...state,
			step,
		}),
	),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return introReducer(state, action);
}
