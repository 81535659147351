import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CREATE_ACCOUNT_PATH_SEGMENT, NEST_PATH_SEGMENT } from '@bp/shared/models/core';
import { ACCEPT_INVITE_PATH_SEGMENT } from '@bp/shared/domains/invitations';

import {
	CreateAccountApiRequest, Identity, IIdentitySessionApiResponse
} from '@bp/admins-shared/domains/identity/models';

import { AUTH_PATH_SEGMENT } from '../constants';

@Injectable({ providedIn: 'root' })
export class InviteApiService {

	private readonly _namespace = `${ NEST_PATH_SEGMENT }/${ AUTH_PATH_SEGMENT }`;

	constructor(private readonly _http: HttpClient) {}

	factory = ({ accessToken: { token, otpExpiresAt }, refreshToken }: IIdentitySessionApiResponse): Identity => new Identity(token, refreshToken, otpExpiresAt);

	acceptInvite(): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/${ ACCEPT_INVITE_PATH_SEGMENT }`, null)
			.pipe(map(this.factory));
	}

	createAccount(request: CreateAccountApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/${ CREATE_ACCOUNT_PATH_SEGMENT }`, request)
			.pipe(map(this.factory));
	}

}
