<bp-modal>
	<ng-template>
		<img
			*ngIf="_announcementFacade.loading$ | async; else loadedNewsTpl"
			src="/assets/bp-rocket.gif"
			alt="Loading."
			class="block m-auto"
		/>

		<ng-template #loadedNewsTpl>
			<div *ngIf="_announcementFacade.entity$ | async as announcement; else notFoundTpl">
				<h1>
					{{ announcement.name }}
				</h1>

				<div @fadeInStaggeringChildren>
					<div *ngIf="announcement.coverImgUrl" class="cover-img-container mb-2">
						<bp-img [url]="announcement.coverImgUrl" />
					</div>

					<hr class="mb-1" />

					<div class="aux-bar mb-3 flex-align-items-center">
						<div class="mat-h5 mb-0 font-weight-slim uppercase">
							{{ announcement.kind.displayName }} &#64;
							{{ announcement.publicationDate | momentFormat: 'LL' | async }}
						</div>

						<!-- we delay rendering to skip the fadeInStaggeringChildren animation  due to clashing of it with mat button ripple  -->
						<bp-share-btn
							*bpDelayedRender="10"
							class="ml-auto"
							[title]="announcement.title"
							[text]="announcement.summary ?? undefined"
							[url]="announcement.url"
						/>
					</div>

					<div class="content" [innerHTML]="announcement.content | safe: 'html'"></div>
				</div>
			</div>

			<ng-template #notFoundTpl>
				<h2>An error occurred while loading the announcement</h2>
			</ng-template>
		</ng-template>
	</ng-template>
</bp-modal>
