import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload } from '@bp/frontend/models/common';
import { CrmUser } from '@bp/frontend/domains/crm/users/models';

export const ACTIONS_SCOPE = '[Domain][Current Crm User]';

const scope = `[API]${ ACTIONS_SCOPE }`;

type SaveCrmUserRequest = { request: CrmUser };

export const saveCrmUserKeptInStoreSuccess = createAction(
	`${ scope } Save Crm User Kept In Store Success`,
	props<SaveCrmUserRequest>(),
);

export const saveCrmUserKeptInStoreFailure = createAction(
	`${ scope } Save Crm User Kept In Store Failure`,
	props<ErrorActionPayload & SaveCrmUserRequest>(),
);

export const saveCrmUserAnalyticsSuccess = createAction(
	`${ scope } Save Crm User Analytics Success`,
	props<SaveCrmUserRequest>(),
);

export const saveCrmUserAnalyticsFailure = createAction(
	`${ scope } Save Crm User Analytics Failure`,
	props<ErrorActionPayload & SaveCrmUserRequest>(),
);
