<bp-sidenav>
	<ng-container *bpHasAccess="MerchantAdminFeature.dashboard">
		<bp-nav-item path="/dashboard" icon="house">
			Dashboard
			<bp-paywall-badge *bpBehindPaywall />
		</bp-nav-item>

		<hr />
	</ng-container>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.transactions" path="/transactions" icon="transactions">
		Transactions

		<bp-paywall-badge *bpBehindPaywall />
	</bp-nav-item>

	<ng-container *bpHasAccess="MerchantAdminFeature.payouts">
		<bp-nav-item
			*ngIf="(currentOrganizationFacade.entity$ | async)?.allowPayouts"
			path="/payouts"
			icon="transactions"
		>
			Payouts

			<bp-paywall-badge *bpBehindPaywall />
		</bp-nav-item>
	</ng-container>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.paymentRoutes" path="/payment-routes" icon="paymentRouter">
		Router

		<bp-paywall-badge *bpBehindPaywall />
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.psps" path="/merchant-psps" icon="paymentProviders">
		PSPs

		<bp-paywall-badge *bpBehindPaywall />
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.checkouts" path="/checkouts" icon="checkouts">
		Checkouts
	</bp-nav-item>

	<bp-nav-item
		*bpHasAccess="MerchantAdminFeature.virtualTerminal"
		icon="terminal"
		[path]="[{ outlets: { '[right-drawer]': ['virtual-terminal'] } }]"
	>
		Virtual terminal
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.reports" icon="reports" path="/reports">
		Reports
		<bp-paywall-badge *bpBehindPaywall />
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.creditCardTokens" path="/payment-card-tokens" icon="card-with-lock">
		Bridger safe

		<bp-paywall-badge *bpBehindPaywall />
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.members" icon="manage_accounts" path="/members">
		Members
	</bp-nav-item>

	<bp-nav-item *bpHasAccess="MerchantAdminFeature.checkouts" icon="service_accounts" path="/service-accounts">
		Service accounts
	</bp-nav-item>
</bp-sidenav>
