import { mergeMap, defer, filter, map, distinctUntilChanged, switchMap, takeUntil, tap, auditTime } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { isEqual } from '@bp/shared/utilities/core';

import { CrmUser } from '@bp/frontend/domains/crm/users/models';
import { CrmUsersApiService } from '@bp/frontend/domains/crm/users/services';
import { apiVoidResultWithRequest } from '@bp/frontend/models/common';
import { flushLastSourceValueOnTimeoutOrAnalyticsEventChange, FLUSH_TIMEOUT } from '@bp/frontend/features/firebase/utils';
import { EnvironmentService } from '@bp/frontend/services/environment';

import {
	saveCrmUserAnalytics, trySaveCrmUserKeptInStore, trySaveCrmUserAnalytics, observeCrmUserRemoteChanges, stopObservingCrmUserRemoteChanges, saveCrmUserKeptInStore
} from './current-crm-user.actions';
import { saveCrmUserKeptInStoreFailure, saveCrmUserKeptInStoreSuccess, saveCrmUserAnalyticsSuccess, saveCrmUserAnalyticsFailure } from './current-crm-user-api.actions';
import { CurrentCrmUserFacade } from './current-crm-user.facade';

@Injectable()
export class CurrentCrmUserEffects {

	private readonly __actions$ = inject<Actions>(Actions);

	private readonly __environment = inject(EnvironmentService);

	private readonly __currentCrmUserFacade = inject(CurrentCrmUserFacade);

	private readonly __crmUsersApiService = inject(CrmUsersApiService);

	trySaveUserKeptInStore$ = createEffect(() => this.__actions$.pipe(
		ofType(trySaveCrmUserKeptInStore),
		map(({ user }) => user),
		filter((user): user is CrmUser => !!user?.id),
		distinctUntilChanged(isEqual),
		auditTime(FLUSH_TIMEOUT),
		map(user => saveCrmUserKeptInStore({ user })),
	));

	trySaveUserAnalytics$ = createEffect(() => this.__actions$.pipe(
		ofType(trySaveCrmUserAnalytics),
		map(() => this.__currentCrmUserFacade.factory({
			...this.__currentCrmUserFacade.user,
			analytics: this.__currentCrmUserFacade.analytics,
		})),
		filter(user => !!user.id),
		distinctUntilChanged(isEqual),
		flushLastSourceValueOnTimeoutOrAnalyticsEventChange(user => user.analytics?.lastEventName),
		map(user => saveCrmUserAnalytics({ user })),
	));

	saveUserKeptInStore$ = createEffect(() => this.__actions$.pipe(
		ofType(saveCrmUserKeptInStore, saveCrmUserAnalytics),
		filter(() => this.__environment.isDeployedStagingOrProduction),
		mergeMap(({ type, user }) => defer(() => this.__crmUsersApiService
			.write(user))
			.pipe(apiVoidResultWithRequest({
				request: user,
				successAction: type === saveCrmUserKeptInStore.type ? saveCrmUserKeptInStoreSuccess : saveCrmUserAnalyticsSuccess,
				failureAction: type === saveCrmUserKeptInStore.type ? saveCrmUserKeptInStoreFailure : saveCrmUserAnalyticsFailure,
			}))),
	));

	observeRemoteChanges$ = createEffect(
		() => this.__actions$.pipe(
			ofType(observeCrmUserRemoteChanges),
			switchMap(({ userId }) => this.__crmUsersApiService
				.listenToRemoteChanges(userId)
				.pipe(takeUntil(this.__actions$.pipe(ofType(stopObservingCrmUserRemoteChanges))))),
			tap(crmUser => crmUser && void this.__currentCrmUserFacade.updateUserInStore({
				...crmUser,
				analytics: {
					dispatchedEventsCountMap: crmUser.analytics?.dispatchedEventsCountMap,
				},
			})),
		),
		{ dispatch: false },
	);

}
