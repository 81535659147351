import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroFacade } from '../../state';

@Component({
	selector: 'bp-set-security-questions-answers-page',
	templateUrl: './set-security-questions-answers-page.component.html',
	styleUrls: [ './set-security-questions-answers-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetSecurityQuestionsAnswersPageComponent {

	constructor(
		public readonly introFacade: IntroFacade,
		public readonly identityFacade: IdentityFacade,
	) {
		this.identityFacade.loadAllSecurityQuestions();
	}

}
