<bp-error-shell class="reddish">
	<div class="ng-content-title">Oops, something has gone wrong.</div>

	<div class="ng-content-description">
		Please check your connection and try again later or contact support if the problem persists
	</div>

	<div class="ng-content-error-image">
		<bp-img url="/assets/error/broken-card.png" />
	</div>
</bp-error-shell>
