import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@bp/shared/models/metadata';
import { NEST_PATH_SEGMENT } from '@bp/shared/models/core';
import {
	NEST_SUPPORT_REQUESTS_PATH_SEGMENT, NEST_GET_ACTIVE_SUPPORT_REQUEST_PATH_SEGMENT, SupportRequest
} from '@bp/shared/domains/support-requests';

import {
	SupportRequestCreateModel, SupportRequestPatchModel
} from '@bp/merchant-admin/shared/domains/support-requests';

@Injectable({
	providedIn: 'root',
})
export class RequestSupportApiService {

	private readonly __http = inject(HttpClient);

	readonly collectionPath = `${ NEST_PATH_SEGMENT }/${ NEST_SUPPORT_REQUESTS_PATH_SEGMENT }`;

	readonly factory = (dto: DTO<SupportRequest>): SupportRequest => new SupportRequest(dto);

	requestSupport(supportRequestCreateModel: SupportRequestCreateModel): Observable<void> {
		return this.__http.post<void>(this.collectionPath, supportRequestCreateModel);
	}

	updateSupportRequest(
		supportRequestId: string,
		supportRequestPatchModelDto: DTO<SupportRequestPatchModel>,
	): Observable<SupportRequest | null> {
		return this.__http
			.patch<SupportRequest | null>(
				`${ this.collectionPath }/${ supportRequestId }`,
				supportRequestPatchModelDto,
			)
			.pipe(map(result => result ? this.factory(result) : null));
	}

	getActiveSupportRequest(): Observable<SupportRequest | null> {
		return this.__http
			.get<SupportRequest | null>(
				`${ this.collectionPath }/${ NEST_GET_ACTIVE_SUPPORT_REQUEST_PATH_SEGMENT }`,
			)
			.pipe(map(result => result ? this.factory(result) : null));
	}

}
