import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { trackById } from '@bp/shared/utilities/core';

import { FADE_IN, HUB_ITEM_FLY_IN_OUT, SLIDE } from '@bp/frontend/animations';

import { AnnouncementsFacade } from '../../../../state';

@Component({
	selector: 'bp-announcements-hub-body',
	templateUrl: './announcements-hub-body.component.html',
	styleUrls: [ './announcements-hub-body.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'bp-scrollbar',
	},
	animations: [ SLIDE, FADE_IN, HUB_ITEM_FLY_IN_OUT ],
})
export class AnnouncementsHubBodyComponent {
	trackById = trackById;

	showNoAnnouncements$ = new BehaviorSubject(true);

	constructor(public announcementsFacade: AnnouncementsFacade) {}
}
