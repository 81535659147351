import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FADE_IN_STAGGERING_CHILDREN } from '@bp/frontend/animations';
import { ModalHostComponent } from '@bp/frontend/features/modal';

import { AnnouncementFacade } from '../../state/entity/announcement.facade';

@Component({
	selector: 'bp-announcement-modal',
	templateUrl: './announcement-modal.component.html',
	styleUrls: [ './announcement-modal.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_STAGGERING_CHILDREN ],
})
export class AnnouncementModalComponent extends ModalHostComponent {
	protected _announcementFacade = inject(AnnouncementFacade);

	private readonly __activatedRoute = inject(ActivatedRoute);

	override panelClass = [ 'announcement-modal-overlay-pane', 'modal-overlay-pane-lg' ];

	private readonly __announcementId = <string> this.__activatedRoute.snapshot.params['id'];

	constructor() {
		super();

		this._announcementFacade.load(this.__announcementId);
	}
}
