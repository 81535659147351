import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { trackMember } from './current-member.actions';
import { selectMemberHash } from './current-member.selectors';

@Injectable({
	providedIn: 'root',
})
export class CurrentMemberFacade {

	private readonly __store$ = inject(Store);

	memberHash$ = this.__store$.select(selectMemberHash);

	track(): void {
		this.__store$.dispatch(trackMember());
	}

}
