import { MockUserEmail, MOCK_USER_EMAILS } from '@bp/shared/domains/jwt-session';
import { Validators } from '@bp/shared/features/validation/models';
import { Countries, Country, countryMapper, State, stateMapper } from '@bp/shared/models/countries';
import {
	MapFromDTO, DTO, Control, FieldControlType, Required, Mapper, titleCase, Label, Default, Validator
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

import { FirebaseDocument } from '@bp/frontend/features/firebase/models';

import { CrmUserAnalytics } from './crm-user-analytics';
import { PspIntroductionRequests } from './psp-introduction-requests.type';
import { pspIntroductionRequestInfoMapper } from './psp-introduction-request-info.mapper';

export type CrmUserKeys = NonFunctionPropertyNames<CrmUser>;

export type CrmUserDTO = DTO<CrmUser>;

export const NOT_PROVIDED = 'Not provided';

export class CrmUser extends FirebaseDocument {

	static {
		this._initClassMetadata();
	}

	@Label('Enter your work email')
	@Required()
	@Control(FieldControlType.email)
	email?: string;

	@Validator(Validators.compose([
		Validators.xssSafe,
		Validators.noUrl,
	])!)
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'given-name',
		},
	)
	firstName?: string;

	@Validator(Validators.compose([
		Validators.xssSafe,
		Validators.noUrl,
	])!)
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'family-name',
		},
	)
	lastName?: string;

	@Validator(Validators.compose([
		Validators.xssSafe,
		Validators.noUrl,
	])!)
	@Control(
		FieldControlType.phone,
		{
			nativeAutocomplete: 'tel',
			typeControlOptions: {
				initialDialCodeCountry: Countries.get('US'),
			},
		},
	)
	phone?: string;

	@MapFromDTO()
	avatarUrl?: string;

	@Mapper(countryMapper)
	country?: Country;

	@Mapper(stateMapper)
	state?: State;

	@Mapper(titleCase)
	city?: string;

	@MapFromDTO()
	organizationId?: string;

	@Mapper(CrmUserAnalytics)
	analytics?: CrmUserAnalytics;

	@MapFromDTO()
	isOwner?: boolean;

	@Default(null)
	@Mapper(pspIntroductionRequestInfoMapper)
	pspIntroductionRequestsProcessed!: PspIntroductionRequests | null;

	@Default(null)
	@Mapper(pspIntroductionRequestInfoMapper)
	pspIntroductionRequestsInProcess!: PspIntroductionRequests | null;

	@MapFromDTO()
	readonly roles?: string[];

	@Validator(Validators.compose([
		Validators.xssSafe,
		Validators.noUrl,
	])!)
	readonly fullName?: string;

	readonly name: string | null;

	constructor(dto?: CrmUserDTO) {
		super(dto);

		if (this.fullName) {
			const { firstName, lastName } = this.__extractFirstAndLastNames(this.fullName);

			this.firstName = firstName;

			this.lastName = lastName;
		}

		this.name = this.firstName && this.lastName
			? `${ this.firstName } ${ this.lastName }`
			: null;

		this.updatedBy = this.email;
	}

	private __extractFirstAndLastNames(fullName: string): { firstName: string; lastName: string } {
		const [ firstName, ...lastNames ] = fullName
			.replace(/\s+/gu, ' ')
			.split(' ');

		return {
			firstName,
			lastName: lastNames.join(' '),
		};
	}

	isTest(): boolean {
		return !!this.isOwner && this.email?.search(/test|bridgerpay/gu) !== -1;
	}

	isMockTest(): boolean {
		return MOCK_USER_EMAILS.includes(<MockUserEmail> this.email!);
	}

}
