<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert class="mb-n5 mt-4" type="danger" [errors]="errors" />

	<div class="mt-5 mb-5">
		<bp-otp-provider-message feature="login" />

		<bp-otp-code-input class="w-100" formControlName="code" required />
	</div>

	<button
		class="bp-round-button mt-auto"
		type="submit"
		mat-flat-button
		color="primary"
		[disabled]="canNotSave$ | async"
	>
		Next
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="tryAnotherWay.emit()">Try another way</button>
	</div>
</form>
