import * as firestore from '@firebase/firestore';
import moment from 'moment';
import { Observable, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import {
	assertIsMetadataQueryParamsBased, DTO, IEntitiesFirebaseApiService
} from '@bp/shared/models/metadata';

import { FirebaseService } from '@bp/frontend/features/firebase';
import {
	AnnouncementsHubStatePerUser, ANNOUNCEMENTS_HUB_STATE_PER_USER_COLLECTION_PATH, NEWSROOM_SUMMARIES_COLLECTION_PATH, NewsSummariesQueryParams, NewsSummary, NewsSummaryKeys
} from '@bp/frontend/domains/newsroom/models';

@Injectable({
	providedIn: 'root',
})
export class NewsSummariesApiService implements IEntitiesFirebaseApiService<NewsSummary, NewsSummariesQueryParams> {

	readonly collectionPath = NEWSROOM_SUMMARIES_COLLECTION_PATH;

	readonly announcementsHubStatePerUserCollectionPath = ANNOUNCEMENTS_HUB_STATE_PER_USER_COLLECTION_PATH;

	constructor(private readonly _firebaseService: FirebaseService) { }

	readonly factory = (dto: DTO<NewsSummary>): NewsSummary => new NewsSummary(dto);

	readonly announcementsHubStatePerUserFactory = (dto: DTO<AnnouncementsHubStatePerUser>): AnnouncementsHubStatePerUser => new AnnouncementsHubStatePerUser(dto);

	readonly newsSummariesFirestoreQueryComposer = <T extends NewsSummary>({
		isPublished,
		kinds,
		showOnMerchantAdminWhatsNewHub,
		skipPublishingToPromoWebsite,
	}: NewsSummariesQueryParams): (firestoreQuery: firestore.Query<DTO<T>>) => firestore.Query<DTO<T>> => this._firebaseService
		.firestoreQueryComposer<T>(
			firestoreQuery => {
				if (showOnMerchantAdminWhatsNewHub !== undefined) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(<NewsSummaryKeys> 'showOnMerchantAdminWhatsNewHub', '==', showOnMerchantAdminWhatsNewHub),
					);
				}

				if (isPublished !== undefined) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(<NewsSummaryKeys> 'isPublished', '==', isPublished),
					);
				}

				if (kinds && kinds.length > 0) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(<NewsSummaryKeys> 'kind', 'in', kinds.map(v => v.name)),
					);
				}

				if (skipPublishingToPromoWebsite !== undefined) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(<NewsSummaryKeys> 'skipPublishingToPromoWebsite', '==', skipPublishingToPromoWebsite),
					);
				}

				return firestoreQuery;
			},
		);

	listenToQueriedRecordsPageChanges(query?: NewsSummariesQueryParams): Observable<RecordsPage<NewsSummary>> {
		assertIsMetadataQueryParamsBased(query);

		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
			this.newsSummariesFirestoreQueryComposer(query),
		);
	}

	listenToAnnouncementsHubStatePerUserChanges(userId: string): Observable<AnnouncementsHubStatePerUser | null> {
		return this._firebaseService.listenToDocumentChanges(
			`${ this.announcementsHubStatePerUserCollectionPath }/${ userId }`,
			this.announcementsHubStatePerUserFactory,
		);
	}

	saveAnnouncementsHubStaterPerUser(userId: string, statePerUser: AnnouncementsHubStatePerUser): Observable<void> {
		return this._firebaseService.set(
			`${ this.announcementsHubStatePerUserCollectionPath }/${ userId }`,
			statePerUser,
		);
	}

	get(id: string): Observable<NewsSummary | null> {
		return this._firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	save(_entity: NewsSummary): Observable<NewsSummary> {
		throw new Error('Cannot be saved, use the news api service');
	}

	delete(_entity: NewsSummary): Observable<void> {
		throw new Error('Cannot be deleted, use the news api service');
	}

	async createAnnouncementsHubStateForCurrentUserIfDoesntExist(user: { id: string | null; email: string }): Promise<void> {
		const documentPath = `${ this.announcementsHubStatePerUserCollectionPath }/${ user.id! }`;
		const hubState = await lastValueFrom(
			this._firebaseService.getDocument(documentPath, this.announcementsHubStatePerUserFactory),
		);

		if (hubState)
			return;

		await lastValueFrom(
			this._firebaseService
				.set(documentPath, new AnnouncementsHubStatePerUser({
					id: user.id!,
					name: user.email,
					createdAt: moment(),
					showNotificationDot: false,
				})),
		);
	}

}
