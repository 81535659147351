import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import { IPageQueryParams, RecordsPage } from '@bp/shared/models/common';
import { FirebaseEntity, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';

import { apiResult } from '@bp/frontend/models/common';
import { debounceTimeAfterFirst } from '@bp/frontend/rxjs';

import { EntitiesListBaseEffects } from './entities-list-base.effects';
import { EntitiesListFacade } from './entities-list.facade';
import { EntitiesListState } from './compose-entities-list-reducer';

export abstract class FirebaseEntitiesListEffects<
	TEntity extends FirebaseEntity,
	TState extends EntitiesListState<TEntity>,
	TLoadQueryParams extends IPageQueryParams,
	TEntitiesFacade extends EntitiesListFacade<TEntity, TState, TLoadQueryParams>
>
	extends EntitiesListBaseEffects<TEntity, TState, TLoadQueryParams, TEntitiesFacade, IEntitiesFirebaseApiService<TEntity, TLoadQueryParams>> {

	loadOnLoadQueryParamsChange$ = createEffect(() => this.apiQueryParamsWithPage$.pipe(
		map(query => this.actions.load({ query })),
	));

	listenToQueriedRecordsPageChanges$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.load),
		debounceTimeAfterFirst(this.debounceDueTime),
		switchMap(({ query }) => this._listenToQueriedRecordsPageChanges$(query)
			.pipe(
				apiResult(this.actions.api.loadSuccess, this.actions.api.loadFailure),
				takeUntil(this.routeComponentDeactivation$),
			)),
	));

	protected _listenToQueriedRecordsPageChanges$(query?: TLoadQueryParams): Observable<RecordsPage<TEntity>> {
		return this._apiService.listenToQueriedRecordsPageChanges(query);
	}

}
