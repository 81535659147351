
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DTO } from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';

import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { FormMetadataEntityBaseComponent } from '@bp/frontend/components/metadata';
import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/frontend/components/core';

import { CreateAccountApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-create-account-form',
	templateUrl: './create-account-form.component.html',
	styleUrls: [ './create-account-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,
			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round',
				compact: true,
			},
		},
	],
})
export class CreateAccountFormComponent extends FormMetadataEntityBaseComponent<CreateAccountApiRequest> {

	@Input() email?: string;

	@Output() readonly login = new EventEmitter();

	override metadata = CreateAccountApiRequest.getClassMetadata();

	override onSubmitShowInvalidInputsToast = false;

	controlsScheme = [
		this.metadata.properties.firstName,
		this.metadata.properties.lastName,
	];

	constructor() {
		super();

		this._setupForm({
			firstName: null,
			lastName: null,
			password: [
				Validators.required,
				Validators.password(),
			],
			confirmPassword: [
				Validators.required,
				Validators.password(),
				Validators.confirmPassword(),
			],
		});

		this.__onPasswordChangeRevalidateConfirmPassword();
	}

	override factory = (dto?: DTO<CreateAccountApiRequest>): CreateAccountApiRequest => new CreateAccountApiRequest(dto);

	private __onPasswordChangeRevalidateConfirmPassword(): void {
		this.controls!.password.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.controls!.confirmPassword.updateValueAndValidity());
	}
}
