import { createAction, props } from '@ngrx/store';

import { CrmUser, CrmUserAnalytics } from '@bp/frontend/domains/crm/users/models';
import { createVerboseAction } from '@bp/frontend/ngrx';

import { ACTIONS_SCOPE } from './current-crm-user-api.actions';

export const observeCrmUserRemoteChanges = createAction(
	`${ ACTIONS_SCOPE } Observe Crm User Remote Changes`,
	props<{ userId: string }>(),
);

export const stopObservingCrmUserRemoteChanges = createAction(
	`${ ACTIONS_SCOPE } Stop Observing Crm User Remote Changes`,
);

export const trySaveCrmUserKeptInStore = createVerboseAction(
	`${ ACTIONS_SCOPE } Try Save Crm User Kept In Store`,
	props<{ user: CrmUser | null }>(),
);

export const saveCrmUserKeptInStore = createAction(
	`${ ACTIONS_SCOPE } Save Crm User Kept In Store`,
	props<{ user: CrmUser }>(),
);

export const updateCrmUserInStore = createAction(
	`${ ACTIONS_SCOPE } Update Crm User In Store`,
	props<{ user: CrmUser }>(),
);

export const trySaveCrmUserAnalytics = createVerboseAction(
	`${ ACTIONS_SCOPE } Try Save Crm User Analytics`,
	props<{ analytics: CrmUserAnalytics }>(),
);

export const saveCrmUserAnalytics = createAction(
	`${ ACTIONS_SCOPE } Save Crm User Analytics`,
	props<{ user: CrmUser }>(),
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);
