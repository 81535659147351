import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';

import type { ISendResetPasswordLinkApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-send-reset-password-link-form',
	templateUrl: './send-reset-password-link-form.component.html',
	styleUrls: [ './send-reset-password-link-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendResetPasswordLinkFormComponent extends FormBaseComponent<ISendResetPasswordLinkApiRequest> {

	@Output() readonly login = new EventEmitter();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(ensureFormGroupConfig<ISendResetPasswordLinkApiRequest>({
			userName: [ '', { validators: Validators.email() }],
		}));

	}
}
