import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';
import { SharedDomainsSubscriptionPlansPaymentChallengeModule } from '@bp/frontend/domains/subscription-plans/payment-challenge';

import { CurrentOrganizationSubscriptionEffects } from './state/current-organization-subscription.effects';
import { currentOrganizationSubscriptionFeature } from './state/current-organization-subscription.feature';

@NgModule({
	imports: [
		SharedDomainsSubscriptionPlansPaymentChallengeModule,

		StoreModule.forFeature(currentOrganizationSubscriptionFeature),
		EffectsModule.forFeature([ CurrentOrganizationSubscriptionEffects ]),
	],
})
export class MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionRootModule {
	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionRootModule,
			async () => import('./api-mock-plugin/current-organization-subscription-api-mock-plugin'),
		);
	}
}
