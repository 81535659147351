import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SupportRequestDurationChange } from '@bp/shared/domains/support-requests';

@Component({
	selector: 'bp-change-support-duration-form',
	templateUrl: './change-support-duration-form.component.html',
	styleUrls: [ './change-support-duration-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeSupportDurationFormComponent {

	@Input() pending!: boolean | null;

	@Input() availableSupportRequestDurationChanges!: Set<SupportRequestDurationChange>;

	@Output() readonly submitted = new EventEmitter<SupportRequestDurationChange>();

	protected readonly _supportRequestDurationChanges = SupportRequestDurationChange.getList();

}
