<bp-modal>
	<ng-template>
		<mat-dialog-content *ngIf="_currentCrmOrganizationFacade.organization$ | async as crmOrganization">
			<div class="flex-center">
				<bp-img url="assets/domains/subscription/subscription-fee-is-overdue-image.svg" />
			</div>

			<h2 class="flex-center my-4">
				<strong>Oops! Your subscription fee is overdue</strong>
			</h2>

			<div class="text-center">
				<p>
					Please settle your payment
					<span *ngIf="crmOrganization.suspensionDateInFuture">
						within
						<strong>
							the next
							{{ (crmOrganization?.suspensionDateInFuture | momentToNow: 'withoutSuffix' | async) ?? '' }}
						</strong>
					</span>
					to keep your Merchant Admin portal active. To complete the payment or review your invoicing and
					payment options, please email
					<a href="mailto:subscriptions@bridgerpay.com" class="link">subscriptions&#64;bridgerpay.com</a>.
				</p>

				<p>If you have already paid, please ignore this message.</p>
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="mt-3">
			<button
				mat-flat-button
				color="primary"
				class="ml-2 bp-round-button"
				type="button"
				[mat-dialog-close]="true"
			>
				Got it
			</button>
		</mat-dialog-actions>
	</ng-template>
</bp-modal>
