import { firstValueFrom } from 'rxjs';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { Platform } from '@bp/shared/typings';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { environment } from '@bp/frontend/environments';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedPipesModule } from '@bp/frontend/pipes';
import {
	LOCAL_SERVER_META_REDUCERS_PROVIDERS,
	REMOTE_SERVER_META_REDUCERS_PROVIDERS,
	sharedReducer
} from '@bp/frontend/state';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { FirebaseAppConfig, FIREBASE_APP_CONFIG } from '@bp/frontend/features/firebase';
import { SharedDomainsCrmUsersCurrentCrmUserStateModule } from '@bp/frontend/domains/crm/users/+current-crm-user-state';
import { SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { SharedServicesModule } from '@bp/frontend/services/core';
import { PLATFORM } from '@bp/frontend/services/environment';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { GlobalApiMockModule } from '@bp/frontend/api-mocking-global';

import { AdminsSharedDomainsIdentityRootModule } from '@bp/admins-shared/domains/identity';
import { AdminsSharedFeaturesLayoutRootModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubRootModule } from '@bp/admins-shared/features/notifications-hub';
import { AdminsSharedPagesErrorsModule } from '@bp/admins-shared/pages/errors';
import { AdminsSharedPagesIntroRootModule } from '@bp/admins-shared/pages/intro';
import { AdminsSharedPagesIdentityModule } from '@bp/admins-shared/pages/identity';
import { AdminsSharedBridgerPspsRootModule } from '@bp/admins-shared/domains/bridger-psps';
import { CONTROL_DEFAULT_OPTIONS_PROVIDERS } from '@bp/admins-shared/core/models';
import { AdminsSharedSubscriptionPlansRootModule } from '@bp/admins-shared/domains/subscription-plans';

import { MerchantAdminFrontendDomainsMerchantPspsRootModule } from '@bp/merchant-admin/frontend/domains/merchant-psps';
import { MerchantAdminFrontendFeaturesAnnouncementsRootModule } from '@bp/merchant-admin/frontend/features/announcements';
import { MerchantAdminFrontendDomainsCurrentOrganizationRootModule } from '@bp/merchant-admin/frontend/domains/current-organization';
import { MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionRootModule } from '@bp/merchant-admin/frontend/domains/current-organization-subscription';
import { MerchantAdminFrontendDomainsCheckoutsRootModule } from '@bp/merchant-admin/frontend/domains/checkouts';
import { CurrentMemberRootModule } from '@bp/merchant-admin/frontend/domains/current-member';
import { MerchantAdminFrontendDomainsIdentityModule } from '@bp/merchant-admin/frontend/domains/identity';

import { AppRoutingModule } from './app-routing.module';
import { AppStartupService } from './app-startup.service';

TelemetryService.log('App module execution begun');

@NgModule({
	imports: [
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.isDeployed,
			registrationStrategy: 'registerWithDelay:1000',
		}),
		// Redux related
		StoreModule.forRoot(sharedReducer, {
			runtimeChecks: {
				strictActionImmutability: false,
				// StrictActionSerializability: false,
				strictStateImmutability: false,

				/*
                 * StrictStateSerializability: false
                 * We dont use the built-in immutability check because
                 * It freezes the whole moment structure
                 * So we utilize custom immutabilityCheckMetaReducer
                 */
			},
		}),
		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal,
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),
		EffectsModule.forRoot([]),
		SharedPipesModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		SharedFeaturesAnalyticsModule.forRoot(),
		SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule.forRoot(),
		SharedDomainsCrmUsersCurrentCrmUserStateModule.forRoot(),
		AdminsSharedSubscriptionPlansRootModule,
		AdminsSharedDomainsIdentityRootModule,
		AdminsSharedBridgerPspsRootModule,
		AdminsSharedFeaturesLayoutRootModule,
		AdminsSharedFeaturesNotificationsHubRootModule,
		AdminsSharedPagesIntroRootModule,
		AdminsSharedPagesIdentityModule,
		MerchantAdminFrontendFeaturesAnnouncementsRootModule,
		MerchantAdminFrontendDomainsCurrentOrganizationRootModule,
		MerchantAdminFrontendDomainsCurrentOrganizationSubscriptionRootModule,
		MerchantAdminFrontendDomainsMerchantPspsRootModule,
		MerchantAdminFrontendDomainsCheckoutsRootModule,
		MerchantAdminFrontendDomainsIdentityModule.forRoot(),
		CurrentMemberRootModule,
		AppRoutingModule,
		GlobalApiMockModule,
		AdminsSharedPagesErrorsModule,
	],
	providers: [
		environment.isDeployed ? REMOTE_SERVER_META_REDUCERS_PROVIDERS : LOCAL_SERVER_META_REDUCERS_PROVIDERS,
		CONTROL_DEFAULT_OPTIONS_PROVIDERS,
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: <FirebaseAppConfig>{
				appId: '1:977741303368:web:b8e53980b491e281',
				enablePersistence: true,
			},
		},
		{
			provide: PLATFORM,
			useValue: <Platform>'merchant-admin',
		},
		provideHttpClient(
			withInterceptorsFromDi(),
		),
	],
})
export class AppModule {

	constructor(
		private readonly __appStartupService: AppStartupService,
		private readonly __zone: NgZone,
	) {
		void this.__whenAppIsStableInitStartupLogic();

	}

	private async __whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this.__zone.onStable);

		this.__appStartupService.init();
	}

}
