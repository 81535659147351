import { Enumeration } from '@bp/shared/models/core/enum';

export class FirebaseDocumentWriter extends Enumeration {

	static readonly frontend = new FirebaseDocumentWriter();

	static readonly googleTagManager = new FirebaseDocumentWriter();

	static readonly firebase = new FirebaseDocumentWriter();

	static readonly unbounce = new FirebaseDocumentWriter();

	static readonly crm = new FirebaseDocumentWriter();

	get isFrontend(): boolean {
		return this === FirebaseDocumentWriter.frontend;
	}

	get isBackend(): boolean {
		return this === FirebaseDocumentWriter.firebase;
	}

}
