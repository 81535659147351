<div class="mx-auto">
	<div class="flex-center">
		<bp-img url="assets/domains/subscription/subscription-has-expired.svg" />
	</div>

	<h1 class="flex-center mat-display-3 my-5">
		<strong>Your subscription has expired</strong>
	</h1>

	<div class="text-center font-weight-normal mat-display-1">
		Your Merchant Admin portal has been deactivated due to an unpaid subscription. Please settle your payment and
		send an email to
		<a href="mailto:subscriptions@bridgerpay.com" class="link">subscriptions&#64;bridgerpay.com</a>for your Merchant
		Admin portal to be reactivated.
	</div>
</div>
