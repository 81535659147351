import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import type { DTO } from '@bp/shared/models/metadata';

import { AppStorageService } from '@bp/frontend/services/storage';
import { MomentTimezoneService } from '@bp/frontend/features/moment/services';
import { filterPresent } from '@bp/frontend/rxjs';

import { IdentityPreferences } from '@bp/admins-shared/domains/identity/models';

import { IdentityPreferencesFacade } from './identity-preferences.facade';
import { FEATURE_STATE_KEY, IDENTITY_PREFERENCES_STATE_KEY } from './identity-preferences.reducer';
import { localStorageIdentityPreferencesChanged, updateIdentityPreferences } from './identity-preferences.actions';

const IDENTITY_PREFERENCES_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ IDENTITY_PREFERENCES_STATE_KEY }`;

@Injectable()
export class IdentityPreferencesEffects {

	private readonly __actions$ = inject(Actions);

	private readonly __appStorageService = inject(AppStorageService);

	private readonly __identityPreferencesFacade = inject(IdentityPreferencesFacade);

	private readonly __momentTimezoneService = inject(MomentTimezoneService);

	setMomentTimezoneOnUserPreferenceChange = this.__identityPreferencesFacade.preferences$
		.pipe(
			filterPresent,
			map(({ timezone }) => timezone),
			distinctUntilChanged(),
		)
		.subscribe(timezone => void this.__momentTimezoneService.setTimezone(timezone));

	storeInLocalStorageOnIdentityPreferencesChange$ = createEffect(
		() => this.__actions$.pipe(
			ofType(updateIdentityPreferences),
			tap(({ identityPreferences }) => void this.__appStorageService.setIfDifferentFromStored(
				identityPreferences,
				IDENTITY_PREFERENCES_PATH_IN_STATE,
			)),
		), { dispatch: false },
	);

	/**
	 * When on one tab the user preferences changes on
	 * all the other tabs the user preferences will get updated accordingly
	 */
	reflectLocalStorageIdentityPreferencesChange$ = createEffect(() => this.__appStorageService.change$
		.pipe(

			filter(event => event.key === this.__appStorageService.deriveKey(IDENTITY_PREFERENCES_PATH_IN_STATE)),
			map(event => <DTO<IdentityPreferences> | null>(event.newValue && JSON.parse(event.newValue)) ?? undefined),
			map(identityPreferencesFromStorageDTO => new IdentityPreferences(identityPreferencesFromStorageDTO)),
			filter(identityPreferencesFromStorage => identityPreferencesFromStorage.timestamp !== this.__identityPreferencesFacade.preferences?.timestamp),
			map(identityPreferencesFromStorage => localStorageIdentityPreferencesChanged({
				identityPreferences: identityPreferencesFromStorage,
			})),
		));

}
