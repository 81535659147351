import { timer } from 'rxjs';
import { map, skip, startWith, switchMap } from 'rxjs/operators';

import { Component, ChangeDetectionStrategy } from '@angular/core';

import { SLIDE_UP } from '@bp/frontend/animations';

import { IdentityPreferencesFacade } from '@bp/admins-shared/domains/identity';

import { IdentityPreferencesPageTab } from '../../models';

@Component({
	templateUrl: './identity-preferences-page.component.html',
	styleUrls: [ './identity-preferences-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'right-drawer-content',
	},
	animations: [ SLIDE_UP ],
})
export class IdentityPreferencesPageComponent {

	IdentityPreferencesPageTab = IdentityPreferencesPageTab;

	// to show the update happened
	simulatedUserPreferenceUpdating$ = this._identityPreferencesFacade.preferences$.pipe(
		skip(1),
		switchMap(() => timer(350).pipe(
			map(() => false),
			startWith(true),
		)),
	);

	constructor(private readonly _identityPreferencesFacade: IdentityPreferencesFacade) {}

}
