import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminsSharedBridgerPspsModule } from './admins-shared-bridger-psps.module';
import { BridgerPspsEffects } from './state/bridger-psps-shared.effects';
import { FEATURE_KEY, reducer } from './state/bridger-psps-shared.reducer';

@NgModule({
	imports: [
		AdminsSharedBridgerPspsModule,

		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ BridgerPspsEffects ]),
	],
})
export class AdminsSharedBridgerPspsRootModule { }
