import { Organization } from '@bp/shared/domains/organizations';

import { composeEntitiesInMemoryPagedListSelectors } from '@bp/admins-shared/features/entity';

import { currentMemberDomainFeature } from '../current-member.domain-feature';

import { IState } from './accessible-organizations.feature';

export const ORGANIZATIONS_LIST_SELECTORS = composeEntitiesInMemoryPagedListSelectors<Organization, IState>(
	currentMemberDomainFeature.selectAccessibleOrganizations,
);
