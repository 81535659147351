<ng-container *ngIf="_accessibleOrganizationsFacade.all$ | async as organizations">
	<ng-container *ngIf="organizations.length > 1; else plainNameTpl">
		<bp-autocomplete
			appearance="standard"
			required
			hideClearButton
			hideErrorText
			hasChevron
			autoGrow
			[value]="_currentOrganization$ | async"
			[items]="organizations"
			(valueChange)="$event && _accessibleOrganizationsFacade.confirmLoginToOrganization($event)"
		/>
	</ng-container>

	<ng-template #plainNameTpl>
		<span class="organization-name m-0 pl-4 pr-4">
			{{ (_currentOrganizationFacade.entity$ | async)?.displayName }}
		</span>
	</ng-template>
</ng-container>
