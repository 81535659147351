import { inject, Injectable } from '@angular/core';

import { UtmService } from '@bp/frontend/features/analytics/utms';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { IntercomService } from '@bp/frontend/services/intercom';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { LocalStorageService } from '@bp/frontend/services/storage';
import { ICookiesUsageConsent } from '@bp/frontend/features/analytics/models';

import { GoogleTagService } from './google-tag.service';
import { PardotService } from './pardot.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

	private readonly __googleTagService = inject(GoogleTagService);

	private readonly __utmService = inject(UtmService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __intercomService = inject(IntercomService);

	private readonly __pardotService = inject(PardotService);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __localStorageService = inject(LocalStorageService);

	private readonly __enabled = !!this.__environment.googleTagAnalyticsId && !!this.__environment.googleTagTransportUrl;

	private __inited = false;

	init(consent: ICookiesUsageConsent): void {
		if (!this.__enabled || this.__inited)
			return;

		this.__initGoogleTagService(consent);

		this.__setGoogleTagGlobalVariables();

		this.__setGTagBackendVersionVariable();

		this.__setGTagUserSessionRecordingURLVariable();

		this.__setIntercomUtmTags();

		this.__pardotService.setOptIn(consent.statistics);

		this.__inited = true;
	}

	updateConsent(consent: ICookiesUsageConsent): void {
		this.__googleTagService.updateConsent(consent);

		this.__pardotService.setOptIn(consent.statistics);
	}

	private __initGoogleTagService(consent: ICookiesUsageConsent): void {
		this.__googleTagService.init({
			consent,
			environment: this.__environment.environment,
			transportUrl: this.__environment.googleTagTransportUrl!,
			analyticsId: this.__environment.googleTagAnalyticsId!,
			platform: this.__environment.platform,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			debug_mode: this._tryGetDebugModeFromURLorSessionStorage(),
		});
	}

	private __setGoogleTagGlobalVariables(): void {
		const utmTags = this.__utmService.tags;

		this.__googleTagService.setGlobalVariables({
			utmTags,
			frontendVersion: this.__environment.appVersion.release,
			platform: this.__environment.platform,
			/* eslint-disable @typescript-eslint/naming-convention */
			campaign_id: utmTags.id,
			campaign_name: utmTags.campaign,
			campaign_source: utmTags.source,
			campaign_medium: utmTags.medium,
			campaign_term: utmTags.term,
			campaign_content: utmTags.content,
			gclid: utmTags.gclid,
			/* eslint-enable @typescript-eslint/naming-convention */
		});
	}

	private __setGTagBackendVersionVariable(): void {
		this.__environment.backendDeploymentVersions$
			.subscribe(backendDeploymentVersions => {
				this.__telemetry.setTags(backendDeploymentVersions);

				void this.__googleTagService.setGlobalVariables({
					backendVersion: backendDeploymentVersions['dotnetBackendVersion'] ?? undefined,
				});
			});
	}

	private __setGTagUserSessionRecordingURLVariable(): void {
		this.__telemetry.userSessionRecordingUrl$
			?.subscribe(userSessionRecordingUrl => void this.__googleTagService.setGlobalVariables({
				userSessionRecordingUrl,
			}));
	}

	private __setIntercomUtmTags(): void {
		void this.__intercomService.update({
			platform: this.__environment.platform,
			environment: this.__environment.name,
			adsetName: this.__utmService.tags.adsetName,
			campaignid: this.__utmService.tags.campaignid,
			adname: this.__utmService.tags.adname,
			placement: this.__utmService.tags.placement,
			adid: this.__utmService.tags.adid,
			gclid: this.__utmService.tags.gclid,
			fbclid: this.__utmService.tags.fbclid,
			liFatId: this.__utmService.tags.liFatId,
			variant: this.__utmService.tags.variant,
		});
	}

	private _tryGetDebugModeFromURLorSessionStorage(): boolean | undefined {
		const debugModeKey = 'analyticsDebugMode';
		const queryDebugMode = new URL(location.href).searchParams.get(debugModeKey) === 'true';

		if (queryDebugMode)
			this.__localStorageService.setDying(debugModeKey, true, 60 * 60 * 1 /* 1 hour */);

		return queryDebugMode || this.__localStorageService.getAlive(debugModeKey);
	}
}
