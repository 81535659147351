import { createFeature, createReducer } from '@ngrx/store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IState {

}

export const initialState: IState = {

};

export const subscriptionPlanPaymentChallengeFeature = createFeature({
	name: '[feature]:subscription-plan-payment-challenge',
	reducer: createReducer(
		initialState,
	),
});
