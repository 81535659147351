<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<div>
		<h3>Scan this QR code with your authenticator app</h3>

		<div class="mat-caption">You can download Google or Microsoft Authenticator on your smartphone</div>

		<div class="qr-code-container mt-3">
			<canvas
				*ngIf="authenticatorKey; else qrCodePlaceholderTpl"
				#qrCodeCanvas
				class="block qr-code"
				data-private
				@fadeIn
			></canvas>

			<ng-template #qrCodePlaceholderTpl>
				<bp-svg-icon name="qr-code-placeholder" />
			</ng-template>
		</div>
	</div>

	<div class="mt-4 mb-4">
		<h3>Enter the 6-digit code here</h3>

		<div class="mat-caption">
			Insert below the code from your authenticator app. Once connected, we'll remember your phone so you can use
			it each time you log in.
		</div>

		<bp-otp-code-input class="w-100 mt-4 mb-n3" formControlName="code" required />
	</div>

	<button
		class="bp-round-button mt-auto"
		type="submit"
		mat-flat-button
		color="primary"
		[bpGoogleTagOnClick]="submitButtonGtagEventName"
		[disabled]="pending$ | async"
	>
		{{ submitButtonText }}
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="login.emit()">Login</button>
	</div>
</form>
