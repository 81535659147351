import { CommonModule } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedFeaturesShareModule } from '@bp/frontend/features/share';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';
import { SharedComponentsMetadataModule } from '@bp/frontend/components/metadata';
import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import {
	RequestSupportOpenButtonComponent, RequestSupportDialogComponent, RequestSupportFormComponent,
	ActiveRequestInfoComponent
} from './components';
import { requestSupportFeature } from './state/request-support/request-support.feature';
import { RequestSupportEffects } from './state/request-support/request-support.effects';
import {
	ChangeSupportDurationFormComponent
} from './components/request-support-dialog/components/change-support-duration-form';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatIconModule,
		MatLegacyDialogModule,
		MatProgressBarModule,

		SharedComponentsControlsModule,
		SharedComponentsMetadataModule,
		SharedComponentsCoreModule,
		SharedDirectivesCoreModule,
		SharedFeaturesModalModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesMomentPipesModule,
		SharedFeaturesShareModule,
		SharedPipesModule,

		StoreModule.forFeature(requestSupportFeature),
		EffectsModule.forFeature(RequestSupportEffects),
	],
	exports: [ RequestSupportOpenButtonComponent ],
	declarations: [
		ActiveRequestInfoComponent,
		ChangeSupportDurationFormComponent,
		RequestSupportFormComponent,
		RequestSupportOpenButtonComponent,
		RequestSupportDialogComponent,
	],
})
export class MerchantAdminFrontendFeaturesRequestSupportModule {

	private readonly _apiMockPluginsManagerService = inject(ApiMockPluginsManagerService);

	constructor() {
		void this._apiMockPluginsManagerService.register(
			MerchantAdminFrontendFeaturesRequestSupportModule,
			async () => import('./api-mock-plugin/support-requests-api-mock-plugin'),
		);
	}

}
