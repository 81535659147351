import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-forbidden-page',
	templateUrl: './forbidden-page.component.html',
	styleUrls: [ './forbidden-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'flex-center' },
})
export class ForbiddenPageComponent {

}
