<form *ngIf="form" cdkTrapFocus cdkTrapFocusAutoCapture [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert type="danger" [errors]="errors" />

	<!-- to kick in the browsers save password feature with upon login -->
	<input type="text" name="username" hidden [value]="email" />

	<div *ngIf="expiredPassword" class="mb-5 mt-n2 text-center">Your password has expired. Please enter a new one</div>

	<div class="inputs-wrapper" [class.mb-2]="expiredPassword">
		<bp-password-input required label="Password" nativeAutocomplete="new-password" formControlName="password" />

		<bp-password-input
			required
			label="Confirm Password"
			nativeAutocomplete="new-password"
			formControlName="confirmPassword"
		/>
	</div>

	<button type="submit" class="bp-round-button mt-auto" mat-flat-button color="primary" [disabled]="pending$ | async">
		Reset Password
	</button>

	<div class="aux-buttons">
		<button type="button" class="link" (click)="login.emit()">Login</button>
	</div>
</form>
