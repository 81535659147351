import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionConfirmDialogComponent, ActionConfirmDialogData } from '@bp/frontend/components/dialogs';

@Component({
	selector: 'bp-login-to-another-organization-confirm-dialog',
	templateUrl: './login-to-another-organization-confirm-dialog.component.html',
	styleUrls: [ './login-to-another-organization-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginToAnotherOrganizationConfirmDialogComponent
	extends ActionConfirmDialogComponent<ActionConfirmDialogData> {
}
