/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { MODAL_OUTLET } from '@bp/shared/models/core';

import { TelemetryService } from '@bp/frontend/services/telemetry';

import { rightDrawerRouteDataOptions } from '@bp/admins-shared/features/layout';

import {
	identityHasAccessGuardConfig, IdentityHasAccessGuard, IdentityLoggedInGuard, NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN
} from '@bp/frontend-domains-identity';
import { AnnouncementModalComponent } from '@bp/merchant-admin/frontend/features/announcements';
import {
	CurrentSubscriptionManagementModalComponent,
	CurrentSubscriptionManagementPageComponent
} from '@bp/merchant-admin/frontend/domains/current-organization-subscription';

import {
	SubscriptionSuspendedPageComponent, NavigationToRootRedirectionGuard, HomePageComponent, SubscriptionFeeIsOverdueModalPageComponent
} from './core';

export const SUBSCRIPTION_SUSPENDED_ROUTE_PATH = 'subscription-suspended';

export const SUBSCRIPTION_FEE_IS_OVERDUE_ROUTE_PATH = 'subscription-fee-is-overdue';

export const SUBSCRIPTION_MANAGEMENT_ROUTE_PATH = 'subscription-management';

export const PURCHASE_SUBSCRIPTION_PLAN_ROUTE_PATH = 'purchase-subscription-plan';

// App routing module is for lazy routes
const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: HomePageComponent,
		canActivate: [ IdentityLoggedInGuard, NavigationToRootRedirectionGuard ],
	},
	{
		path: PURCHASE_SUBSCRIPTION_PLAN_ROUTE_PATH,
		canActivate: [ IdentityLoggedInGuard ],
		component: CurrentSubscriptionManagementPageComponent,
		data: { title: 'Purchase Your Plan', mode: 'purchase' },
	},
	{
		path: SUBSCRIPTION_MANAGEMENT_ROUTE_PATH,
		canActivate: [ IdentityLoggedInGuard ],
		component: CurrentSubscriptionManagementModalComponent,
		data: { title: 'Manage Your Plan' },
		outlet: MODAL_OUTLET,
	},
	{
		path: 'announcement/:id',
		canActivate: [ IdentityLoggedInGuard ],
		component: AnnouncementModalComponent,
		outlet: MODAL_OUTLET,
	},
	{
		path: SUBSCRIPTION_FEE_IS_OVERDUE_ROUTE_PATH,
		canActivate: [ IdentityLoggedInGuard ],
		component: SubscriptionFeeIsOverdueModalPageComponent,
		data: { title: 'Subscription fee is overdue' },
		outlet: MODAL_OUTLET,
	},
	{
		path: SUBSCRIPTION_SUSPENDED_ROUTE_PATH,
		canActivate: [ IdentityLoggedInGuard ],
		component: SubscriptionSuspendedPageComponent,
		data: { title: 'Organization is suspended' },
	},
	{
		path: 'enterprise-subscription-plan-checkout',
		data: {
			...identityHasAccessGuardConfig({
				permission: MerchantAdminFeature.enterpriseSubscriptionCheckout,
				permissionBasedRedirectionOnNoAccess: true,
				dontRequireRunGuardsAlways: true,
			}),
		},
		canActivate: [ IdentityHasAccessGuard ],
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/enterprise-subscription-plan-checkout').then(
			m => m.EnterpriseSubscriptionPlanCheckoutModule,
		),
	},
	{
		path: 'dashboard',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.dashboard),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/dashboard').then(m => m.DashboardSectionModule),
	},
	{
		path: 'transactions',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.transactions),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/transactions').then(m => m.TransactionsSectionModule),
	},
	{
		path: 'payouts',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.payouts),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/payouts').then(m => m.PayoutsSectionModule),
	},
	{
		path: 'payment-routes',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.paymentRoutes),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/payment-routes').then(m => m.PaymentRoutesSectionModule),
	},
	{
		path: 'checkouts',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.checkouts),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/checkouts').then(m => m.CheckoutsSectionModule),
	},
	{
		path: 'reports',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.reports),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/reports').then(m => m.ReportsSectionModule),
	},
	{
		path: 'virtual-terminal',
		data: {
			...identityHasAccessGuardConfig(MerchantAdminFeature.virtualTerminal),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		outlet: '[right-drawer]',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/virtual-terminal').then(m => m.VirtualTerminalModule),
	},
	{
		path: 'merchant-psps',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.psps),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/merchant-psps').then(m => m.MerchantPspsSectionModule),
	},
	{
		path: 'merchant-psps-drawer-outlet',
		data: {
			preload: false,
			...rightDrawerRouteDataOptions({
				drawersGroups: [
					{
						groupName: 'payment-route/psp-payment-option',
					},
				],
			}),
		},
		outlet: '[right-drawer][3]',
		canActivate: [ IdentityLoggedInGuard ],
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/merchant-psps').then(m => m.MerchantPspsSectionModule),
	},
	{
		path: 'payment-card-tokens',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.creditCardTokens),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/payment-card-tokens').then(
			m => m.PaymentCardTokensSectionModule,
		),
	},
	{
		path: 'members',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.members),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/members').then(m => m.MembersSectionModule),
	},
	{
		path: 'service-accounts',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(MerchantAdminFeature.checkouts),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/merchant-admin/frontend/sections/service-accounts').then(m => m.ServiceAccountsSectionModule),
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			enableTracing: false,
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			scrollOffset: [ 0, 150 ],
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
	providers: [
		{
			provide: NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN,
			multi: true,
			useValue: [ `/${ SUBSCRIPTION_SUSPENDED_ROUTE_PATH }`, `/${ PURCHASE_SUBSCRIPTION_PLAN_ROUTE_PATH }` ],
		},
	],
})
export class AppRoutingModule {}
