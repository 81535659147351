import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Organization } from '@bp/shared/domains/organizations';

import { EntitiesInMemoryPagedListFacade } from '@bp/admins-shared/features/entity';

import { AccessibleOrganizationsQueryParams } from '../../models';

import { ORGANIZATIONS_LIST_ACTIONS } from './accessible-organizations.actions';
import { ORGANIZATIONS_LIST_SELECTORS } from './accessible-organizations.selectors';
import { IState } from './accessible-organizations.feature';

@Injectable({
	providedIn: 'root',
})
export class BaseAccessibleOrganizationsFacade extends EntitiesInMemoryPagedListFacade<Organization, IState, AccessibleOrganizationsQueryParams> {

	readonly actions = ORGANIZATIONS_LIST_ACTIONS;

	readonly selectors = ORGANIZATIONS_LIST_SELECTORS;

	queryParamsFactory(params: Params): AccessibleOrganizationsQueryParams {
		return new AccessibleOrganizationsQueryParams(params);
	}

}
