import { camelCase, kebabCase } from 'lodash-es';

import { Injectable, inject } from '@angular/core';

import { IUtmTags } from '@bp/frontend/features/analytics/utms-models';
import { UrlHelper } from '@bp/frontend/utilities/common';
import { LocalStorageService } from '@bp/frontend/services/storage';

type UtmSearchParamEntry = [ utmTagName: string, utmValue: string ];

const REFERER_KEY = 'referer';

@Injectable({
	providedIn: 'root',
})
export class UtmService {

	private static readonly __timeToLive = 60 * 60 * 24 * 45; // 45 days

	private readonly __initialPageURL = new URL(globalThis.location.toString());

	private readonly __localStorageService = inject(LocalStorageService);

	private readonly __searchParamsEntries: UtmSearchParamEntry[] = [];

	readonly tags: IUtmTags = {
		id: this.__getUtmTagFromStorageOrURL('utm_id'),
		source: this.__getUtmTagFromStorageOrURL('utm_source'),
		medium: this.__getUtmTagFromStorageOrURL('utm_medium'),
		campaign: this.__getUtmTagFromStorageOrURL('utm_campaign'),
		term: this.__getUtmTagFromStorageOrURL('utm_term'),
		content: this.__getUtmTagFromStorageOrURL('utm_content'),
		adsetName: this.__getUtmTagFromStorageOrURL('utm_adset_name'),
		campaignid: this.__getUtmTagFromStorageOrURL('utm_campaignid'),
		adname: this.__getUtmTagFromStorageOrURL('utm_adname'),
		placement: this.__getUtmTagFromStorageOrURL('utm_placement'),
		adid: this.__getUtmTagFromStorageOrURL('utm_adid'),
		liFatId: this.__getUtmTagFromStorageOrURL('li_fat_id'),
		gclid: this.__getUtmTagFromStorageOrURL('gclid'),
		fbclid: this.__getUtmTagFromStorageOrURL('fbclid'),
		variant: this.__getUtmTagFromStorageOrURL('variant'),
		pardotCampaignId: this.__getUtmTagFromStorageOrURL('piCId'),
		refererPageUrl: UrlHelper.stripQueryParamsFromURL(
			this.__getUtmTagFromStorageOrURL(REFERER_KEY),
		),
	};

	init(): void {
		// intentionally blank
	}

	getUtmSearchParamsEntries(): UtmSearchParamEntry[] {
		return this.__searchParamsEntries;
	}

	private __getUtmTagFromStorageOrURL(utmTagName: string): string | undefined {
		let utmTagValueFromURL = this.__findInURLInDifferentCases(utmTagName);

		if (!utmTagValueFromURL && utmTagName === REFERER_KEY)
			utmTagValueFromURL = document.referrer;

		if (utmTagValueFromURL)
			this.__localStorageService.setDying(utmTagName, utmTagValueFromURL, UtmService.__timeToLive);

		const utmValue = this.__localStorageService.getAlive<string>(utmTagName);

		if (utmValue)
			this.__searchParamsEntries.push([ utmTagName, utmValue ]);

		return utmValue;
	}

	private __findInURLInDifferentCases(utmTagName: string): string | undefined {
		for (const casedUtmTagName of [ utmTagName, camelCase(utmTagName), kebabCase(utmTagName) ]) {
			const utmTagValueFromURL = this.__initialPageURL.searchParams.get(casedUtmTagName);

			if (utmTagValueFromURL)
				return utmTagValueFromURL;
		}

		return undefined;
	}
}
