import { createReducer, createFeature, on } from '@ngrx/store';

import { SupportRequest } from '@bp/shared/domains/support-requests';

import { BpError } from '@bp/frontend/models/core';

import {
	close, requestSupport, completeSupportRequest, changeSupportRequestDuration, getActiveSupportRequest
} from './request-support.actions';
import {
	getActiveSupportRequestSuccess, getActiveSupportRequestFailure,
	requestSupportSuccess, completeSupportRequestSuccess, updateSupportRequestFailure,
	changeSupportRequestDurationSuccess, requestSupportFailure
} from './request-support-api.actions';

export interface IState {
	entity: SupportRequest | null;
	pending: boolean;
	error: BpError | null;
}

export const initialState: IState = {
	entity: null,
	pending: false,
	error: null,
};

export const requestSupportFeature = createFeature({
	name: '[feature]:request-support',
	reducer: createReducer(
		initialState,

		on(
			close,
			(state): IState => ({
				...state,
				pending: false,
				error: null,
			}),
		),

		on(
			changeSupportRequestDurationSuccess,
			getActiveSupportRequestSuccess,
			(state, { result }): IState => ({
				...state,
				entity: result,
			}),
		),

		on(
			getActiveSupportRequest,
			changeSupportRequestDuration,
			requestSupport,
			completeSupportRequest,
			(state): IState => ({
				...state,
				pending: true,
			}),
		),

		on(
			completeSupportRequestSuccess,
			(state): IState => ({
				...state,
				entity: null,
			}),
		),

		on(
			getActiveSupportRequestSuccess,
			getActiveSupportRequestFailure,
			changeSupportRequestDurationSuccess,
			requestSupportSuccess,
			completeSupportRequestSuccess,
			updateSupportRequestFailure,
			requestSupportFailure,
			(state): IState => ({
				...state,
				pending: false,
			}),
		),

		on(
			requestSupportFailure,
			(state, { error }): IState => ({
				...state,
				error,
			}),
		),
	),
});
