import { lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';

import { ProgressiveWebAppService, SharedStartupService } from '@bp/frontend/services/core';

import { NotificationsHubService } from '@bp/admins-shared/features/notifications-hub';

@Injectable({
	providedIn: 'root',
})
export class AdminSharedAppStartupService {

	constructor(
		private readonly _sharedStartup: SharedStartupService,
		private readonly _pwaService: ProgressiveWebAppService,
		private readonly _notificationsHubService: NotificationsHubService,
	) { }

	init() {
		this._sharedStartup.init();

		void this._pwaService.listenForNewVersion();

		void this._whenNewVersionAvailableCreateNotificationToReloadApp();
	}

	private async _whenNewVersionAvailableCreateNotificationToReloadApp(): Promise<void> {
		await lastValueFrom(this._pwaService.newVersionIsAvailable$);

		this._notificationsHubService.next({
			text: 'A new app version is available!',
			duplicateAsToast: true,
			button: {
				text: 'Reload the page to apply changes',
				onClick: () => void this._pwaService.reloadApp(),
			},
		});
	}

}
