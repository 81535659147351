import { isEmpty } from 'lodash-es';
import type { Moment } from 'moment';

import type { MetaDefinition } from '@angular/platform-browser';

import { MapFromDTO, Mapper, momentMapper } from '@bp/shared/models/metadata';

import { Metatags } from './metatags';

const OG_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export class ArticleMetatags extends Metatags {

	@Mapper(momentMapper)
	publishedTime?: Moment | null;

	@Mapper(momentMapper)
	modifiedTime?: Moment | null;

	/**
	 * Should be an array of links
	 */
	@MapFromDTO()
	author?: string[];

	/**
	 * A high-level section name. E.g. Technology
	 */
	@MapFromDTO()
	section?: string;

	@MapFromDTO()
	tags?: string[];

	readonly metatagsSchema = 'article';

	getMetaDefinitions(): MetaDefinition[] {
		const definitions: MetaDefinition[] = [];

		if (this.publishedTime) {
			definitions.push({
				property: `${ this.metatagsSchema }:published_time`,
				content: this.publishedTime.format(OG_DATE_FORMAT),
			});
		}

		if (this.modifiedTime) {
			definitions.push({
				property: `${ this.metatagsSchema }:modified_time`,
				content: this.modifiedTime.format(OG_DATE_FORMAT),
			});
		}

		if (this.section) {
			definitions.push({
				property: `${ this.metatagsSchema }:section`,
				content: this.section,
			});
		}

		if (!isEmpty(this.tags))
			this.tags!.forEach(tag => definitions.push({ property: `${ this.metatagsSchema }:tag`, content: tag }));

		return definitions;
	}

}
