import { exhaustMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { createEffect, ofType } from '@ngrx/effects';

import { Organization } from '@bp/shared/domains/organizations';

import { ActionConfirmDialogData } from '@bp/frontend/components/dialogs';
import { apiVoidResult } from '@bp/frontend/models/common';

import {
	buildConfirmedEntityActionWorkflowEffect, EntitiesInMemoryPagedListEffects
} from '@bp/admins-shared/features/entity';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { AccessibleOrganizationsQueryParams } from '../../models';
import { AccessibleOrganizationsApiService } from '../../services';
import { LoginToAnotherOrganizationConfirmDialogComponent } from '../../components';

import { BaseAccessibleOrganizationsFacade } from './base-accessible-organizations.facade';
import { IState } from './accessible-organizations.feature';
import { confirmConnectToOrganization, dismissConfirmConnectToOrganization, connectToOrganization } from './accessible-organizations.actions';
import { connectToOrganizationFailure, connectToOrganizationSuccess } from './accessible-organizations-api.actions';

@Injectable()
export class AccessibleOrganizationsEffects extends EntitiesInMemoryPagedListEffects<
	Organization,
	IState,
	AccessibleOrganizationsQueryParams,
	BaseAccessibleOrganizationsFacade
> {
	protected readonly _dialog = inject(MatDialog);

	readonly routeComponentType = null;

	confirmLoginToOrganizationWorkflowEffect$ = buildConfirmedEntityActionWorkflowEffect({
		actions$: this._actions$,
		dialog: this._dialog,
		actionConfirmDialogComponent: LoginToAnotherOrganizationConfirmDialogComponent,
		buildActionConfirmDialogData: ({ entity }): ActionConfirmDialogData => ({
			name: entity.name!,
			confirmButtonText: 'Login',
			color: 'warn',
		}),
		confirmAction: confirmConnectToOrganization,
		dismissActionConfirmation: dismissConfirmConnectToOrganization,
		action: connectToOrganization,
	});

	onConnectToOrganization$ = createEffect(() => this._actions$.pipe(
		ofType(connectToOrganization),
		exhaustMap(({ entity }) => this.__organizationsApiService.connectToOrganization(entity)
			.pipe(apiVoidResult(connectToOrganizationSuccess, connectToOrganizationFailure))),
	));

	onConnectToOrganizationSuccess$ = createEffect(() => this._actions$.pipe(
		ofType(connectToOrganizationSuccess),
		tap(() => void this.__identityFacade.logout()),
	), { dispatch: false });

	onConnectToOrganizationFailure$ = createEffect(() => this._actions$.pipe(
		ofType(connectToOrganizationFailure),
		tap(({ error, type }) => {
			this.__toasterService.error(error.message, type, { disableTimeOut: true });
		}),
	), { dispatch: false });

	constructor(
		private readonly __organizationsApiService: AccessibleOrganizationsApiService,
		private readonly __identityFacade: IdentityFacade,
		private readonly __toasterService: ToastrService,
		organizationsFacade: BaseAccessibleOrganizationsFacade,
	) {
		super(__organizationsApiService, organizationsFacade);
	}

	protected _loadQueryParamsFactory = (
		dto?: Partial<AccessibleOrganizationsQueryParams>,
	): AccessibleOrganizationsQueryParams => new AccessibleOrganizationsQueryParams(dto);

	protected readonly _filterRecordsInMemoryOnQueryParamsChange = (
		records: Organization[],
		_queryParams: AccessibleOrganizationsQueryParams,
	): Organization[] => records;

}
