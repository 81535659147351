<h1 class="text-center" mat-dialog-title>Login to {{ _actionConfirmDialogData.name }}?</h1>

<mat-dialog-content>
	<p>Are you sure you want to login to {{ _actionConfirmDialogData.name }} organization?</p>

	<p>You will be logged out from your current organization and you will have to login again.</p>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button type="button" class="mr-auto" [mat-dialog-close]="false">Cancel</button>

	<button mat-stroked-button color="primary" type="button" [mat-dialog-close]="true">Login</button>
</mat-dialog-actions>
