import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './feature.reducer';
import { FEATURE_KEY } from './feature.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const getEntityState = createSelector(selectFeatureState, s => s.entity);

export const getListState = createSelector(selectFeatureState, s => s.list);
