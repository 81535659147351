import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

const EXPOSED_COMPONENTS: any[] | Type<any> = [

];

@NgModule({
	imports: [
		CommonModule,

		SharedDirectivesCoreModule,
		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
	],
	exports: [ EXPOSED_COMPONENTS ],
	declarations: EXPOSED_COMPONENTS,
})
export class AdminsSharedSubscriptionPlansModule {

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			AdminsSharedSubscriptionPlansModule,
			async () => import('./api-mock-plugin/subscription-plans-api-mock-plugin'),
		);
	}

}
