import { createAction, props } from '@ngrx/store';

import type { NewsSummary, AnnouncementsHubStatePerUser } from '@bp/frontend/domains/newsroom/models';
import type { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';

import { composeEntitiesListApiActions } from '@bp/admins-shared/features/entity';

export const ACTIONS_SCOPE = '[Feature][AnnouncementsHub]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const ANNOUNCEMENTS_API_ACTIONS = composeEntitiesListApiActions<NewsSummary>(scope);

export const announcementsHubStatePerUserApiUpdate = createAction(
	`${ scope } Announcements Hub State Per User Update`,
	props<ResultActionPayload<AnnouncementsHubStatePerUser>>(),
);

export const listeningToAnnouncementsHubStatePerUserApiUpdatesFailure = createAction(
	`${ scope } Listening To Announcements Hub State Per User Updates Failure`,
	props<ErrorActionPayload>(),
);

export const updateAnnouncementsHubStatePerUserSuccess = createAction(
	`${ scope } Update Announcements Hub State Per User Success`,
);

export const updateAnnouncementsHubStatePerUserFailure = createAction(
	`${ scope } Update Announcements Hub State Per User Failure`,
	props<ErrorActionPayload>(),
);
