import '@bp/frontend/startup';
import { bootstrapApplication } from '@angular/platform-browser';
import { importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import { TelemetryService } from '@bp/frontend/services/telemetry';

import { AppModule } from './app/app.module';
import { RootComponent } from './app/core';

/**
 * We bootstrap app in next event loop because we have microtasks `bpQueueMicrotask` we need to finish
 * executing before running main app
 */
setTimeout(
	() => void bootstrapApplication(RootComponent, {
		providers: [
			provideAnimations(),
			provideZoneChangeDetection({
				ignoreChangesOutsideZone: true,
				eventCoalescing: true,
				runCoalescing: true,
			}),
			importProvidersFrom(AppModule),
		],
	})
		.catch(TelemetryService.appErrorHandler),
);
