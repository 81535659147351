import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroFacade } from '../../../state';

@Component({
	selector: 'bp-reset-authenticator-app-page',
	templateUrl: './reset-authenticator-app-page.component.html',
	styleUrls: [ './reset-authenticator-app-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetAuthenticatorAppPageComponent {

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) {
		this.identityFacade.loadAuthenticatorAppKey();
	}

}
