import { createReducer, createFeature, on } from '@ngrx/store';

import { getMemberHashSuccess } from './current-member-api.actions';

export interface IState {
	memberHash: string | null;
}

export const initialState: IState = {
	memberHash: null,
};

export const currentMemberFeature = createFeature({
	name: 'currentMember',
	reducer: createReducer(
		initialState,

		on(
			getMemberHashSuccess,
			(state, { result }): IState => ({
				...state,
				memberHash: result,
			}),
		),
	),
});
