<button
	*ngFor="let change of _supportRequestDurationChanges"
	type="button"
	mat-stroked-button
	color="primary"
	class="bp-round-button bp-button-sm"
	[disabled]="pending || !availableSupportRequestDurationChanges.has(change)"
	(click)="submitted.emit(change)"
>
	{{ change.displayName }}
</button>
