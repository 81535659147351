import { Injectable } from '@angular/core';

import { Checkout } from '@bp/frontend/domains/checkout/models';

import { EntitiesInMemoryPagedListEffects } from '@bp/admins-shared/features/entity';

import { CheckoutsQueryParams } from '../../models';
import { CheckoutsApiService } from '../../services';

import { CheckoutsFacade } from './checkouts.facade';
import { IState } from './checkouts.feature';

@Injectable()
export class CheckoutsEffects extends EntitiesInMemoryPagedListEffects<
	Checkout,
	IState,
	CheckoutsQueryParams,
	CheckoutsFacade
> {
	readonly routeComponentType = null;

	constructor(checkoutsFacade: CheckoutsFacade, checkoutsApiService: CheckoutsApiService) {
		super(checkoutsApiService, checkoutsFacade);
	}

	protected _loadQueryParamsFactory = (dto?: Partial<CheckoutsQueryParams>): CheckoutsQueryParams => new CheckoutsQueryParams(dto);

	protected readonly _filterRecordsInMemoryOnQueryParamsChange = (
		records: Checkout[],
		_queryParams: CheckoutsQueryParams,
	): Checkout[] => records;
}
